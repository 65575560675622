import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch } from "react-router-dom";

import { Web3ReactProvider } from '@web3-react/core'
import { Web3Provider } from "@ethersproject/providers";
import { Provider } from "react-redux";
import store from "state";

import "assets/scss/material-kit-react.scss?v=1.10.0";

// pages for this product
import LandingPage from "views/LandingPage";
import MainApp from "views/AppPage/MainApp.js";
import AboutPage from "views/AboutPage";
import IndividualCapsule from "views/IndividualCapsulePage";
import MetaversePage from "views/MetaversePage";
import RedeemPage from "views/RedeemPage";
import LicensePage from "views/LicensePage";
// import CollectionPage from "views/CollectionPage";

var hist = createBrowserHistory();

const getLibrary = function (provider) {
  return new Web3Provider(provider, "any")
}

// previously, a collection page existed here: <Route path="/collection/:nftAddress" component={CollectionPage} />

const Web3App = props => (
  <Web3ReactProvider getLibrary={getLibrary}>
    <Provider store={store}>
      <Router history={hist}>
        <Switch>
          <Route path="/capsule/:nftAddress/:nftId" component={IndividualCapsule} />
          <Route path="/app" component={MainApp} />
          <Route path="/license" component={LicensePage} />
          <Route path="/about" component={AboutPage} />
          <Route path="/metaverse" component={MetaversePage} />
          <Route path="/redeem" component={RedeemPage} />
          <Route path="/" component={LandingPage} />
        </Switch>
      </Router>
    </Provider>
  </Web3ReactProvider>
)

ReactDOM.render(
  <Web3App/>,
  document.getElementById("root")
);
