import { useCallback, useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { useActiveWeb3React } from "hooks/useActiveWeb3React"
import useDebounce from "hooks/useDebounce"
import useIsWindowVisible from "hooks/useIsWindowVisible"
import { updateBlockNumber } from "./actions"
import { useCapsuleNFTFactoryContract } from 'hooks/useContract.js'

import {
  MainChainId,
  getLibraryDirect,
  getGeneralCapsuleNFTContractDirect
} from 'utils'

export default function Updater() {
  let { library, chainId } = useActiveWeb3React()
  const dispatch = useDispatch()

  const windowVisible = useIsWindowVisible()

  const [state, setState] = useState({
    chainId,
    blockNumber: null,
    nftsMinted: 0
  })

  const useNode = {
    active: true,
    chainId: MainChainId
  }

  const capsuleNFTFactoryContract = useCapsuleNFTFactoryContract(false, useNode)

  const blockNumberCallback = useCallback(
    async (blockNumber) => {
      let totalNftsMinted

      const getTotal = async () => {
        // this method gets the total amount of NFTs minted by all Capsule NFTs
        // first, get all capsule NFT addresses from the CapsuleNFTFactory
        // then, iterate through all of them, get their total 'count', and add them all

        if (!library) {
          library = getLibraryDirect(useNode)
        }

        let total = 0

        const getCollectionsArray = async () => {
          return capsuleNFTFactoryContract?.getAllCapsuleCollections()
        }

        const collectionArray = await getCollectionsArray()

        if (collectionArray) {
          return Promise.all(collectionArray.map(async function (nftAddress, _) {
            const capsuleNFTContract = getGeneralCapsuleNFTContractDirect(
              nftAddress,
              chainId || useNode.chainId,
              library
            )

            const getNFTTotalMinted = async () => {
              return await capsuleNFTContract?.counter()
            }

            return await getNFTTotalMinted()
          }))
            .then(function(result) {
              if (result) {
                for (var i = 0; i < result.length; i++) {
                  if (result[i]) {
                    total += result[i].toNumber() || 0
                  }
                }
              }

              return total
            })
        }

        return total
      }

      const handleTotal = async () => {
        try {
          const total = await getTotal()
          return total ? total : -1
        } catch (e) {
          return -1
        }
      }

      totalNftsMinted = await handleTotal()

      setState((state) => {
        if (chainId === state.chainId) {
          if (typeof state.blockNumber !== "number")
            return { chainId, blockNumber, nftsMinted: totalNftsMinted }
          return {
            chainId,
            blockNumber: Math.max(blockNumber, state.blockNumber),
            nftsMinted: totalNftsMinted
          }
        }
        return state
      })
    },
    [library, chainId, setState]
  )

  // attach/detach listeners
  useEffect(() => {
    if (!library || !chainId || !windowVisible) return undefined

    setState({ chainId, blockNumber: null })

    library
      .getBlockNumber()
      .then(blockNumberCallback)
      .catch((error) =>
        console.error(
          `Failed to get block number for chainId: ${chainId}`,
          error
        )
      )

    library.on("block", blockNumberCallback)
    return () => {
      library.removeListener("block", blockNumberCallback)
    }
  }, [dispatch, chainId, library, blockNumberCallback, windowVisible])

  const debouncedState = useDebounce(state, 100)

  useEffect(() => {
    if (
      !debouncedState.chainId ||
      !debouncedState.blockNumber ||
      !debouncedState.nftsMinted ||
      !windowVisible
    )
      return
    dispatch(
      updateBlockNumber({
        chainId: debouncedState.chainId,
        blockNumber: debouncedState.blockNumber,
        nftsMinted: debouncedState.nftsMinted
      })
    )
  }, [
    windowVisible,
    dispatch,
    debouncedState.blockNumber,
    debouncedState.chainId,
    debouncedState.nftsMinted
  ])

  return null
}
