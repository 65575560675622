import React from "react";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import { makeStyles } from "@material-ui/core/styles";

import styles from "assets/jss/material-kit-react/views/componentsSections/tabsStyle.js";

// images
import capsuleCollectionGraphic from "assets/img/capsule/CAP_CapsuleCollection_Graphic.gif"
import capsuleNFTGraphic from "assets/img/capsule/CAP_CapsuleNFT_Graphic.png"
import capsuleMinting from "assets/img/capsule/CAP_Minting_Graphic.png"
import capsuleRedeeming from "assets/img/capsule/CAP_Redeeming_Graphic.png"
import capsuleSimple from "assets/img/capsule/CAP_SimpleCapsule_Graphic.png"

// video carousel
import VideoCarousel from "views/AboutPage/Sections/video-carousel";

import GetTotalNFTsMinted from "components/app/MetamaskCornerWidget/WatchForNFTMintEvents.js"

const useStyles = makeStyles(styles);

export default function HomeSection() {
  const classes = useStyles();

  const collectionImageWidth = window.innerWidth < 800 ? '220px' : '440px'
  const collectionImageHeight = window.innerWidth < 800 ? '220px' : '440px'

  return (
    <div className={classes.textCenter}>
      <div className={classes.aboutPage}>
        <h2 className={classes.textCenter}><strong>Store, trade, and redeem tokens using Capsule NFTs</strong></h2>
        <GridItem xs={12}
          style={{
            marginTop: "50px"
          }}
        >
          <GridContainer alignItems="center" justifyContent="center">
            <GridItem
              xs={12}
              md={6}
            >
              <img
                width={'220px'}
                height={'220px'}
                src={capsuleSimple}
                alt=""
                className={classes.imgRounded + " " + classes.imgFluid}
              />
            </GridItem>
            <GridItem
              xs={12}
              md={6}
            >
              <div className={classes.aboutCapsule}>
                <h3 className={classes.textCenter}><strong>Simple Capsule NFT</strong></h3>
                <p className="smallText">
                  A normal ERC-721 NFT minted using the Capsule Protocol.
                </p>
                <p className="smallText">
                  It can be sold and traded like any NFT.
                </p>
                <br/>
              </div>
            </GridItem>
          </GridContainer>
        </GridItem>
        <GridItem xs={12}
          style={{
          }}
        >
          <GridContainer alignItems="center" justifyContent="center">
            <GridItem
              xs={12}
              md={6}
            >
              <img
                width={'220px'}
                height={'220px'}
                src={capsuleNFTGraphic}
                alt=""
                className={classes.imgRounded + " " + classes.imgFluid}
              />
            </GridItem>
            <GridItem
              xs={12}
              md={6}
            >
              <div className={classes.aboutCapsule}>
                <h3 className={classes.textCenter}><strong>Token-Embedded Capsule NFT</strong></h3>
                <p className="smallText">
                  An ERC-721 NFT minted specifically with the Capsule Protocol.
                </p>
                <p className="smallText">
                  It stores any assortment of ERC-20 or ERC-721 tokens.
                </p>
                <p className="smallText">
                  It can be sold and traded like any other NFT.
                </p>
              </div>
            </GridItem>
          </GridContainer>
        </GridItem>
        <GridItem xs={12}
          style={{
            marginTop: "35px"
          }}
        >
          <GridContainer alignItems="center" justifyContent="center">
            <GridItem
              xs={12}
              md={6}
            >
              <img
                width={'220px'}
                height={'220px'}
                src={capsuleMinting}
                alt=""
                className={classes.imgRounded + " " + classes.imgFluid}
              />
            </GridItem>
            <GridItem
              xs={12}
              md={6}
            >
              <div
                className={classes.aboutCapsule}
                style={{
                  marginTop: "25px"
                }}
              >
                <h3 className={classes.textCenter}><strong>Minting a Capsule NFT</strong></h3>
                <p className="smallText">
                  The act of creating a new Capsule NFT.
                </p>
                <br/><br/><br/><br/>
              </div>
            </GridItem>
          </GridContainer>
        </GridItem>
        <GridItem xs={12}
          style={{
            marginTop: "10px"
          }}
        >
          <GridContainer alignItems="center" justifyContent="center">
            <GridItem
              xs={12}
              md={6}
            >
              <img
                width={'220px'}
                height={'220px'}
                src={capsuleRedeeming}
                alt=""
                className={classes.imgRounded + " " + classes.imgFluid}
              />
            </GridItem>
            <GridItem
              xs={12}
              md={6}
            >
              <div className={classes.aboutCapsule}>
                <h3 className={classes.textCenter}><strong>Redeeming a Capsule NFT</strong></h3>
                <p className="smallText">
                  The act of exchanging your Capsule NFT in order to obtain the tokens stored within it.
                </p>
                <p className="smallText">
                  Note that the Capsule NFT will be burned as a result of redemption.
                </p>
                <br/>
              </div>
            </GridItem>
          </GridContainer>
        </GridItem>

        <h2
          className={classes.textCenter}
          style={{
            marginTop: "150px"
          }}
        >
          <strong>Explore new opportunities with Capsule Collections</strong>
        </h2>

        <GridItem xs={12}
          style={{
            marginTop: "100px"
          }}
        >
          <GridContainer alignItems="center" justifyContent="center">
            <GridItem
              xs={12}
              md={6}
            >
              <img
                width={collectionImageWidth}
                height={collectionImageHeight}
                src={capsuleCollectionGraphic}
                alt=""
                className={classes.imgRounded + " " + classes.imgFluid}
              />
            </GridItem>
            <GridItem
              xs={12}
              md={6}
            >
              <div className={classes.aboutCapsule}>
                <h3 className={classes.textCenter}><strong>Public Collections</strong></h3>
                <p className="smallText">
                  A Capsule Collection from which anyone is able to mint NFTs.
                </p>
                <br/>
                <h3 className={classes.textCenter}><strong>Private Collections</strong></h3>
                <p className="smallText">
                  A Capsule Collection from which only the owner (in most cases, the deployer) is able to mint NFTs.
                </p>
                <br/>
                <h3 className={classes.textCenter}><strong>Lockable Collections</strong></h3>
                <p className="smallText">
                  Set a maximum limit of NFTs mintable from a Collection. Once a collection is locked, it can never be unlocked.
                </p>
                <br/>
                <h3 className={classes.textCenter}><strong>Specifiable URI Owner</strong></h3>
                <p className="smallText">
                  The URI Owner of a collection has the ability to change the data of any NFT created by his collection. This can also be set to no one.
                </p>
              </div>
            </GridItem>
          </GridContainer>
        </GridItem>

        <h2
          className={classes.textCenter}
          style={{
            marginTop: "150px",
            marginBottom: "100px"
          }}
        >
          <strong>Check out these screenshare tutorials they help</strong>
        </h2>

        <VideoCarousel/>

        <h3
          className={classes.textCenter}
          style={{
            marginTop: "150px"
          }}
        >
          <strong>
            Do you love reading? Nerd. Check out our docs <strong><a href="https://docs.capsulenft.com/" target="_blank" className={classes.textColor, classes.capsuleLink}>here.</a></strong>
          </strong>
        </h3>
        <h3
          className={classes.textCenter}
          style={{
            marginTop: "50px"
          }}
        >
          <strong>
            Ready to get started? <span onClick={() => window.scrollTo(0, 0)} className={classes.textColor}>Click here.</span>
          </strong>
        </h3>
        <div style={{
          marginTop: "35px"
        }}>
          <GetTotalNFTsMinted/>
        </div>
    </div>
  </div>
  )
}

/*

        <h2>
          What are Capsules?
        </h2>
        <p>
          <strong>Capsules are ERC-721 compliant NFTs redeemable at any time for a certain amount of tokens through the Capsule ecosystem.</strong>
        </p>
        <p>
          When Capsules are created, the creator sends in any amount of a token (ERC-20, for example!) alongside, which gives the NFT a baseline value.
          At any time, an owner of a Capsule can 'burn' it, destroying the Capsule (NFT) forever and re-obtaining the sent in coins.
          Capsules can be held or traded, like any NFT. Simple Capsules also exist, which function as basic ERC-721 NFTs.
        </p>
        <p>
          <strong>A thorough explanation of Capsule NFTs can be found </strong><a href="/about">here</a>.
        </p>
      </div>
      <div className={classes.aboutPage}>
        <h2>
          What are Capsule Collections?
        </h2>
        <p>
          <strong>Capsules Collections are Capsule supported, deployed ERC-721 contracts. Users are able to create their own named ERC-721 NFTs through Capsule's ecosystem.</strong>
        </p>
        <p>
          By calling a special method, any user is able to create his own Capsule based ERC-721 contract - allowing for access to ALL Capsule value keeping methods.
          This means a user owned Capsule Collection can mint Simple Capsules (basic ERC-721 NFTs), ERC-20 Capsules (Capsules holding any ERC-20 as value), and/or any other method added to the Capsule protocol.
        </p>
        <p>
          Collections exist on chain as a contract, and a user is able to specify the collection's NFT Name and NFT Symbol.
          Collections can also be named 'public' (anyone can mint NFTs in the collection) or 'private' (only the owner can mint NFTs),
          and can also be 'locked' (disallowing ALL further mints to the collection) at any time by the collection owner.
        </p>
        <p>
          <strong>A thorough explanation of Capsule Collections can be found </strong><a href="/about">here</a>.
        </p>
      </div>
      <div className={classes.aboutPage}>
        <h2>
          How can I get started?
        </h2>
        <p>
          To get started with your own NFT Collection, navigate to the 'Collections' tab and create your own Capsule Collection!
        </p>
        <p>
          To get started minting a Capsule, navigate to the 'Mint Capsules' tab, choose a public Collection, and create your own Capsule!
        </p>
        <p>
          <strong>A thorough explanation of creating a Capsule NFT/Capsule NFT Collection can be found </strong><a href="/about">here</a>.
        </p>
      </div>
*/
