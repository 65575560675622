import React from "react";

// @material-ui/icons
import HelpIcon from '@material-ui/icons/Help';
import CreateIcon from '@material-ui/icons/Create';
import RedeemIcon from '@material-ui/icons/Redeem';
import WhatshotIcon from '@material-ui/icons/Whatshot';
import CollectionsIcon from '@material-ui/icons/Collections';
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomTabs from "components/CustomTabs/CustomTabs.js";

// import tabs
import MintTab from 'components/app/AppMainWidget/MintTab.js'
import MyCapsulesTab from 'components/app/AppMainWidget/MyCapsulesTab.js'
import HomeSection from 'components/app/AppMainWidget/HomeSection.js'
import FeaturedCapsulesTab from 'components/app/AppMainWidget/FeaturedCapsulesTab.js'
import CollectionsTab from 'components/app/AppMainWidget/CollectionsTab.js'

export default function AppTabs() {
  //const { account, active, error, library } = useWeb3React()
  //console.log(library, useWeb3React())

  var browserZoomLevel = Math.round(window.devicePixelRatio * 100);

  const zoomLevelMargin = browserZoomLevel > 100 ? "100px" : "0px"

  return (
    <GridContainer
      justifyContent="center"
      style={{
        marginTop: zoomLevelMargin
      }}
    >
      <GridItem xs={12} sm={12} md={12}>
        <CustomTabs
          headerColor="primary"
          alignCenter={true}
          tabs={[
            {
              tabName: "Featured",
              tabIcon: WhatshotIcon,
              tabContent: (
                <FeaturedCapsulesTab/>
              ),
            },
            {
              tabName: "Mint Capsule NFT",
              tabIcon: CreateIcon,
              tabContent: (
                <MintTab/>
              ),
            },
            {
              tabName: "My Capsule NFTs",
              tabIcon: RedeemIcon,
              tabContent: (
                <MyCapsulesTab/>
              ),
            },
            {
              tabName: "Collections",
              tabIcon: CollectionsIcon,
              tabContent: (
                <CollectionsTab/>
              ),
            },
            {
              tabName: "Overview",
              tabIcon: HelpIcon,
              tabContent: (
                <HomeSection/>
              ),
            },
          ]}
        />
      </GridItem>
    </GridContainer>
  );
}
