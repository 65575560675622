import React from "react";

import { Helmet } from "react-helmet";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import AppHeader from "components/LandingPage/Header/Header.js";
import HeaderLinks from "components/LandingPage/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";

import GridContainer from "components/Grid/GridContainer.js";

import styles from "assets/jss/material-kit-react/views/components.js";

// Sections for this page
import ProductSection from "./Sections/ProductSection.js";
import TeamSection from "./Sections/TeamSection.js";
import RoadmapSection from "./Sections/RoadmapSection.js";

const useStyles = makeStyles(styles);

export default function MainApp(props) {
  //const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
  //setTimeout(function () {
  //  setCardAnimation("");
  //}, 700);
  const classes = useStyles();
  const { ...rest } = props;

  return (
    <div>
      <Helmet>
        <html lang="en" />
        <title>CapsuleNFT - Composable NFT Infrastructure</title>
        <meta name="description" content="Learn more about the use cases, community, and team members behind the Capsule Protocol." />
      </Helmet>
      <AppHeader
        absolute
        color="transparent"
        brand="CapsuleNFT"
        rightLinks={<HeaderLinks />}
        {...rest}
      />
      <div
        className={classes.pageHeader}
      >
        <div
          className={classes.container}
          style={{paddingTop: 5}}
        >
          <ProductSection />
          <TeamSection />
          <RoadmapSection />
        </div>
        <div className={classes.container}>
          <GridContainer
            direction="column"
            alignItems="center"
            justifyContent="center"
          >

          </GridContainer>
        </div>
        <Footer whiteFont />
      </div>
    </div>
  );
}


/*

<Parallax filter image={require("assets/img/landing-bg.jpg").default}>
  <div className={classes.container}>
    <GridContainer>
      <GridItem xs={12} sm={12} md={6}>
        <h1 className={classes.title}>Welcome to Capsule</h1>
        <h4>
          Description of Capsule
        </h4>
        <br />
        <Button
          color="danger"
          size="lg"
          href="/app"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="fas fa-play" />
          Access the App here!
        </Button>
      </GridItem>
      <GridItem xs={12} sm={12} md={6}>
        <h4>
          'animation here'
        </h4>
      </GridItem>
    </GridContainer>
  </div>
</Parallax>

*/
