import { useCallback, useState } from "react";

import Button from "components/CustomButtons/Button.js";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

// NFT Data card
import NFTDataCard from "components/NFTDataCard";

import { makeStyles } from "@material-ui/core/styles";

import styles from "assets/jss/material-kit-react/views/componentsSections/tabsStyle.js";
import SendIcon from "@material-ui/icons/Send";
import { CircularProgress, TextField } from "@material-ui/core";
import { useTransactionAdder } from "state/transactions/hooks";
import { useCapsuleNFTContract } from "hooks/useContract";
import { ethers } from "ethers";
import GridItem from "components/Grid/GridItem";
import { useIsTransactionPending } from "state/transactions/hooks";

const useStyles = makeStyles(styles);

const SendCapsule = (props) => {
  const { account, address, id } = props;

  const capsuleContract = useCapsuleNFTContract(address, true, {
    active: false,
  });
  const addTransaction = useTransactionAdder();

  const classes = useStyles();

  const [sendDialogOpen, setSendDialogOpen] = useState(false);
  const [sendTx, setSendTx] = useState();
  const [recipientAddress, setRecipientAddress] = useState("invalid");
  const [transferError, setTransferError] = useState("");

  const handleOpen = () => {
    setSendDialogOpen(true);
  };

  const handleClose = () => {
    setSendDialogOpen(false);
  };

  const handleAddressValidation = (e) => {
    let recipient;
    try {
      recipient = ethers.utils.getAddress(e.target.value.trim());
    } catch (error) {
      setRecipientAddress("invalid");
      return;
    }
    setRecipientAddress(recipient);
  };

  const handleSend = useCallback(async () => {
    setTransferError(""); // clear error
    try {
      const tx = await capsuleContract[
        "safeTransferFrom(address,address,uint256)"
      ](account, recipientAddress, id);
      addTransaction(tx, { summary: `Capsule Sucessfully Transferred` });
      setSendTx(tx);
      return tx;
    } catch (e) {
      console.log("Problem transferring the Capsule NFT", e);
      let translatedError;
      console.log(e.message);
      if (e.message?.includes("transfer to non ERC721Receiver ")) {
        translatedError =
          "Cannot transfer CapsuleNFT to an address that cannot receive ERC721 tokens.";
      }
      createErrorModule(translatedError, e);
      return e;
    }
  }, [recipientAddress, account, capsuleContract, id, addTransaction]);

  const createErrorModule = (customError, errorObject) => {
    setTransferError(customError || errorObject?.message);
  };

  if (useIsTransactionPending(sendTx?.hash)) {
    console.log("transaction", sendTx, "is pending");
    return (
      <span
        style={{
          textAlign: "center",
          fontSize: "18px",
        }}
      >
        <CircularProgress
          size={24}
          style={{
            marginRight: "10px",
          }}
        />
        <strong>Transferring Capsule to: {recipientAddress}</strong>
      </span>
    );
  } else {
    return (
      <>
        <Button
          startIcon={<SendIcon />}
          color="danger"
          size="lg"
          onClick={handleOpen}
          variant="contained"
        >
          Send
        </Button>
        <Dialog
          open={sendDialogOpen}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth="md"
        >
          <DialogTitle
            id="alert-dialog-title"
            style={{
              textAlign: "center",
              background: "black",
              color: "white",
              border: "2px solid white",
              borderBottomWidth: "0px",
            }}
          >
            {`Send Capsule`}
          </DialogTitle>
          <DialogContent
            style={{
              textAlign: "center",
              background: "black",
              border: "2px solid white",
              borderBottomWidth: "0px",
              borderTopWidth: "0px",
            }}
          >
            <NFTDataCard nftAddress={address} nftId={id} height={200} />
            <DialogContentText
              id="alert-dialog-description"
              style={{
                color: "white",
                marginTop: "25px",
              }}
            >
              <p
                style={{
                  fontSize: "18px",
                }}
              >
                Sending this Capsule NFT is an <i>irreversible and permanent</i>{" "}
                process.
              </p>
              <p
                style={{
                  fontSize: "18px",
                }}
              >
                Upon <strong>confirmation of the transaction</strong>, this
                CapsuleNFT will be <i>transferred</i>.
              </p>
            </DialogContentText>
            <TextField
              autoFocus
              classes={{
                root: classes.autocompleteTextfield,
              }}
              inputProps={{
                style: {
                  color: "white",
                },
              }}
              variant="outlined"
              label="Input Receiver Address: 0x1234..."
              fullWidth
              onChange={handleAddressValidation}
            />

            {transferError && (
              <GridItem
                xs={12}
                style={{
                  color: "#dd4b39",
                }}
              >
                <h3
                  style={{
                    textAlign: "center",
                  }}
                >
                  Transfer Error:
                </h3>
                <p
                  style={{
                    fontSize: "18px",
                    textAlign: "center",
                  }}
                >
                  <strong>{transferError}</strong>
                </p>
              </GridItem>
            )}

            <DialogContentText
              id="alert-dialog-description"
              style={{
                color: "white",
                marginTop: "25px",
              }}
            >
              <p>
                By transferring a Capsule NFT, you agree and consent to the{" "}
                <a className={classes.capsuleLink} href="terms" target="_blank">
                  Terms & Conditions
                </a>
                .
              </p>
            </DialogContentText>
          </DialogContent>
          <DialogActions
            style={{
              background: "black",
              alignItems: "center",
              justifyContent: "center",
              border: "2px solid white",
              borderTopWidth: "0px",
            }}
          >
            <Button onClick={handleClose} autoFocus color="google">
              Cancel Transfer
            </Button>
            <Button
              color="danger"
              onClick={handleSend}
              disabled={recipientAddress === "invalid"}
            >
              Transfer Capsule
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
};

export default SendCapsule;
