import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import AppHeader from "components/LandingPage/Header/Header.js";
import HeaderLinks from "components/LandingPage/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";

// main swap app in the middle
import Capsule from "components/IndividualCapsule"

import styles from "assets/jss/material-kit-react/views/loginPage.js";

const useStyles = makeStyles(styles);


export default function IndividualCapsule(props) {
  //const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
  //setTimeout(function () {
  //  setCardAnimation("");
  //}, 700);
  const classes = useStyles();
  const { ...rest } = props;
  const nftId = props.match.params.nftId
  const nftAddress = props.match.params.nftAddress

  return (
    <div>
      <AppHeader
        absolute
        color="transparent"
        brand="CapsuleNFT"
        rightLinks={<HeaderLinks />}
        {...rest}
      />
      <div
        className={classes.pageHeader}
        style={{
          background: "black",
          backgroundSize: "cover",
          backgroundPosition: "top center",
          paddingTop: "10vh"
        }}
      >
        <div className={classes.container}>
          <Capsule
            nftAddress={nftAddress}
            nftId={nftId}
          />
        </div>
        <Footer whiteFont />
      </div>
    </div>
  );
}
