import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

import CopyButton from 'components/CopyButton';

import {
  ZeroAddress
} from 'utils'

export default function SimpleCollectionCard(props) {
  const {
    isPrivate,
    info,
    owner,
    tokenURIOwner,
    nftAddress,
    counter,
    maxId
  } = props

  const maxInt = "115792089237316195423570985008687907853269984665640564039457584007913129639935"

  return (
    <Card
      style={{
        border: "2.5px solid #6f40ff",
        background: 'black',
        marginBottom: '10px',
        color: 'white'
      }}
    >
      <Typography gutterBottom variant="h5" component="h2"
        style={{
          textAlign: 'center',
          background: 'rgb(111, 64, 255)'
        }}
      >
        {`${info}`}
        <CopyButton
          copyText={nftAddress}
        />
      </Typography>
      <CardContent>
        <Typography gutterBottom variant="h5" component="h2"
          style={{textAlign: 'center'}}
        >
          {
            `${isPrivate ? 'Private' : 'Public'} Collection - ${maxId ? maxId.toString() === maxInt ? 'Unlocked' : `Locked at ${parseInt(maxId.toString()) + 1}` : '?'}`
          }
        </Typography>
        <Typography variant="body2" component="p"
          style={{textAlign: 'center'}}
        >
          {`Owner: ${owner}`}
          <CopyButton
            copyText={owner}
            width={"14px"}
            height={"14px"}
          />
        </Typography>
        <Typography variant="body2" component="p"
          style={{textAlign: 'center'}}
        >
          {`Metamaster: ${tokenURIOwner === ZeroAddress ? 'None' : tokenURIOwner}`}
          { tokenURIOwner === ZeroAddress ?
              null
            :
              <CopyButton
                copyText={tokenURIOwner}
                width={"14px"}
                height={"14px"}
              />
          }
        </Typography>
        <Typography variant="body2" component="p"
          style={{textAlign: 'center'}}
        >
          {`${counter} NFTs minted`}
        </Typography>
      </CardContent>
    </Card>
  );
}
