import {
  warningCardHeader,
  successCardHeader,
  dangerCardHeader,
  infoCardHeader,
  primaryCardHeader,
} from "assets/jss/material-kit-react.js";

const menuStyle = {
  menu: {
    color: "#fff",
    //background: "linear-gradient(60deg, #551384, #3f0667)",
    //boxShadow: "0 12px 20px -10px rgba(156, 39, 176, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(156, 39, 176, 0.2)",
    borderRadius: "50px",
    marginLeft: "15px",
    marginRight: "15px",
    border: "0",
    marginTop: "100px",
    marginBottom: "100px"
  },
  cardHeaderPlain: {
    marginLeft: "0px",
    marginRight: "0px",
  },
  textCenter: {
    textAlign: "center",
  },
  color: {
    color: "#fdf4ff" // #dfbfe7
  },
  boxColor: {
    root: {
      "& $notchedOutline": {
        borderColor: "red"
      },
      "&:hover $notchedOutline": {
        borderColor: "blue"
      },
      "&$focused $notchedOutline": {
        borderColor: "green"
      }
    },
    focused: {},
    notchedOutline: {}
  },
  warningCardHeader,
  successCardHeader,
  dangerCardHeader,
  infoCardHeader,
  primaryCardHeader,
};

export default menuStyle;
