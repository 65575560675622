import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-kit-react/views/componentsSections/tabsStyle.js";

// import image
import unknownTokenPic from "assets/img/capsule/capsule-token-icon.png" // "assets/img/capsule/unknown-token.png";
//import { capsuleTokenList as erc20TokenList } from 'components/app/FunctionComponents/CurrencyInputDropdown/erc20-token-list'
import { capsuleTokenList as erc721TokenList } from 'components/app/FunctionComponents/CurrencyInputDropdown/erc721-token-list'

const useStyles = makeStyles(styles);

const getERC20TokenLogoURL = (address) => {
  if (!address) {
    return unknownTokenPic
  }
  return `https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/${address}/logo.png`;
}

const getERC721CollectionLogo = (address) => {
  if (!address) {
    return unknownTokenPic
  }
  for (var i = 0; i < erc721TokenList; i++) {
    if (erc721TokenList[i].address === address) {
      return erc721TokenList[i].logoURI
    }
  }
  return unknownTokenPic
}

const CurrencyLogo = (props) => {
  const classes = useStyles();
  const { address, width, height, style, type } = props

  return (
    <img
      width={width}
      height={height}
      style={style}
      src={type === 'erc20' ? getERC20TokenLogoURL(address) : getERC721CollectionLogo(address)}
      alt=""
      className={classes.imgRounded + " " + classes.imgFluid}
    />
  );
};

export default CurrencyLogo;
