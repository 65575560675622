import React from "react"

import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";

import ERC20Dropdown from './ERC20Dropdown.js'

export default function ERC20CapsuleTab(props) {

  const { data, addRow, deleteRow, updateData } = props

  return (
    <>
      {
        data.map((val, idx) => {
          const id = `erc20-dropdown-${val.index}`

          return (
            <div
              key={id}
              style={{
                marginBottom: "15px"
              }}
            >
              <ERC20Dropdown
                identifier={val.index}
                updateData={updateData}
                deleteRow={deleteRow}
                val={val}
              />
            </div>
        )})
      }
      <GridContainer alignItems="center" justifyContent="center">
        <Button
          onClick={() => addRow()}
          type="button"
          color="danger"
          size="md"
          className="btn btn-primary text-center"
        >
          {(data.length < 1) ? 'Add Token' : 'Add Another Token'}
          &nbsp;
          <i className="fa fa-plus-circle" aria-hidden="true" />
        </Button>
      </GridContainer>
    </>
  )
}
