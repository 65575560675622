import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import { useState, useEffect } from 'react'
//import { useNFTMinterContract } from 'hooks/useContract.js'
import {
  MainChainId,
  OurPublicCapsuleNFTAddress,
  OurPrivateCapsuleNFTAddress
} from 'utils'

// core components
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Pagination from '@material-ui/lab/Pagination';

import styles from "assets/jss/material-kit-react/views/componentsSections/tabsStyle.js";

// nft data card
import NFTDataCard from "components/NFTDataCard"

const useStyles = makeStyles(styles);

export default function FeaturedCapsulesTab() {
  const classes = useStyles();

  const [featuredCapsulesList, setFeaturedCapsulesList] = useState([])

  // for pagination
  const startPage = 1
  //const starterCapsule = featuredCapsules[startPage]
  const totalPages = 10

  const [page, setPage] = useState(startPage)
  const handlePageChange = (event, value) => {
    setPage(value)
  }

  useEffect(() => {
    const publicCapsuleAddress = OurPublicCapsuleNFTAddress(MainChainId)
    const privateCapsuleAddress = OurPrivateCapsuleNFTAddress(MainChainId)

    // featured capsule list is stored as follows:
    // featuredCapsules[page] = { id, address }
    const featuredCapsules = [
      {
        id: 3,
        address: '0x27d4281Bdb5bd0AD5a0f35cBB61F02f946F9D1C2' // Triptych
      },
      {
        id: 1,
        address: publicCapsuleAddress
      },
      {
        id: 2,
        address: publicCapsuleAddress
      },
      {
        id: 3,
        address: publicCapsuleAddress
      },
      {
        id: 4,
        address: publicCapsuleAddress
      },
      {
        id: 5,
        address: publicCapsuleAddress
      },
      {
        id: 6,
        address: publicCapsuleAddress
      },
      {
        id: 7,
        address: publicCapsuleAddress
      },
      {
        id: 8,
        address: publicCapsuleAddress
      },
      {
        id: 9,
        address: publicCapsuleAddress
      }
    ]

    setFeaturedCapsulesList(featuredCapsules)
  }, [])

  const getVisibilityStyle = (hiddenCondition) => {
    if (hiddenCondition) {
      return {
        visibility: 'hidden',
        height: 0,
        overflow: 'scroll'
      }
    }
    return {
      visibility: 'visible',
      height: 'inherit',
    }
  }

  /*
  <div>
    <h4 className={classes.textCenter}>
      Featured Capsule ({featuredCapsules[page]}):
    </h4>
    <NFTDataCard
      nftId={featuredCapsules[page]}
      height={height}
    />
  </div>
  */

  // nft picture in card height
  const height = 500

  return (
    <span>
      <CardBody>
        <GridContainer>
          <GridItem xs={12}>
            {featuredCapsulesList.map((prop, key) => {
              const nftAddress = featuredCapsulesList[key].address
              const nftId = featuredCapsulesList[key].id

              return (<div
                key={key}
                style={getVisibilityStyle((key + 1) !== page)}
              >
                <h3 className={classes.textCenter}>
                  <strong>Featured Capsule NFTs:</strong>
                </h3>
                <NFTDataCard
                  nftAddress={nftAddress}
                  nftId={nftId}
                  height={height}
                />
              </div>)
            })}
            </GridItem>
          <GridItem xs={12}>
            <div className={classes.pagination}>
              <Pagination
                className={classes.pagination}
                count={totalPages}
                page={page}
                onChange={handlePageChange}
                size="large"
                showFirstButton
                showLastButton
                siblingCount={totalPages/2}
              />
            </div>
          </GridItem>
        </GridContainer>
      </CardBody>
      <CardFooter className={classes.cardFooter}>
      </CardFooter>
    </span>
  );
}

/*
// deprecated latest capsule code

//const nftMinterContract = useNFTMinterContract(false, useNode)
// total NFTs minted obtained from injected provider
//const nftMintedNumber = useNftsMinted()

// featured capsule id:
//const featuredCapsuleId = 0


// const [latestNFTId, setLatestNFTId] = useState("?");
// const [latestCapsule, setLatestCapsule] = useState(null);

// this may make an extra call to infura bc nftMintedNumber
// may already be available when injected is available
useEffect(async () => {
  const getTotal = async () => {
    return nftMinterContract?.totalNFTCount()
  }

  const handleTotal = async () => {
    try {
      const total = await getTotal()
      return total ? total.toNumber() : 0
    } catch (e) {
      console.error(e)
      return 0
    }
  }
  // to allow for re-adding the data card
  // set to null and reset
  setLatestCapsule(null)

  const totalMinted = await handleTotal()
  setLatestNFTId(totalMinted)
  setLatestCapsule(<NFTDataCard
    nftId={(totalMinted - 1)}
    height={height}
  />)
}, [nftMintedNumber])

<GridContainer>
  <GridItem xs={6}>
    <h4 className={classes.textCenter}>
      Latest Capsule ({latestNFTId - 1}):
    </h4>
    {latestCapsule}
  </GridItem>
  <GridItem xs={6}>
    <h4 className={classes.textCenter}>
      Featured Capsule ({featuredCapsuleId}):
    </h4>
    <NFTDataCard
      nftId={featuredCapsuleId}
      height={height}
    />
  </GridItem>
</GridContainer>
*/
