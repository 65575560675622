import { useActiveWeb3React } from 'hooks/useActiveWeb3React.js'

// mat ui
import GridContainer from "components/Grid/GridContainer.js";

// approve tokens, if approval < 0
import ApproveERC721Token from 'components/app/FunctionComponents/ApproveERC721Token.js'

const GetERC721TokenApproval = function (props) {
  const {
    tokenAddress,
    erc721MintId
  } = props
  const { account } = useActiveWeb3React()

  return (
    <>
      {
        !account && 'Sign in, and select a token'
      }
      {
        account &&
          <>
            <GridContainer alignItems="center" justifyContent="center">
              <ApproveERC721Token
                tokenAddress={tokenAddress}
                id={erc721MintId}
                amount={'one'}
              />
            </GridContainer>
            <GridContainer alignItems="center" justifyContent="center">
              <ApproveERC721Token
                tokenAddress={tokenAddress}
                id={erc721MintId}
                amount={'all'}
              />
            </GridContainer>
          </>
      }
    </>
  )
}

export default GetERC721TokenApproval
