import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import styles from "assets/jss/material-kit-react/views/landingPageSections/productStyle.js";

const useStyles = makeStyles(styles);

export default function License() {
  const classes = useStyles();

  return (
     <div
      className={classes.section}
      style={{padding: '160px 0px 0px 0px'}}
    >
      <GridContainer justifyContent="center">
        <GridItem xs={12} sm={12} md={8}>
          <h2 className={classes.title}>Smart Contract Code License</h2>
          <h3 className={classes.description}>
          Business Source License 1.1
          </h3>
          <p className={classes.description}>
          License text copyright (c) 2017 MariaDB Corporation Ab, All Rights Reserved.
          "Business Source License" is a trademark of MariaDB Corporation Ab.
          </p>
          <p className={classes.description}>
          -----------------------------------------------------------------------------
          </p>
          <p className={classes.description}>
          Parameters
          </p>
          <p className={classes.description}>
          Licensor:             CapsuleNFT
          </p>
          <p className={classes.description}>
          Licensed Work:        Capsule Protocol - The Licensed Work is (c) 2022 CapsuleNFT
          </p>
          <p className={classes.description}>
          Additional Use Grant: Any uses listed and defined at www.capsulenft.com/license
          </p>
          <p className={classes.description}>
          Change Date:          The earlier of 2023-07-01 or a date specified at www.capsulenft.com/license
          </p>
          <p className={classes.description}>
          Change License:       GNU General Public License v3.0
          </p>
          <p className={classes.description}>
          -----------------------------------------------------------------------------
          </p>
          <p className={classes.description}>
          Terms
          </p>
          <p className={classes.description}>
          The Licensor hereby grants you the right to copy, modify, create derivative
          works, redistribute, and make non-production use of the Licensed Work. The
          Licensor may make an Additional Use Grant, above, permitting limited
          production use.
          </p>
          <p className={classes.description}>
          Effective on the Change Date, or the fourth anniversary of the first publicly
          available distribution of a specific version of the Licensed Work under this
          License, whichever comes first, the Licensor hereby grants you rights under
          the terms of the Change License, and the rights granted in the paragraph
          above terminate.
          </p>
          <p className={classes.description}>
          If your use of the Licensed Work does not comply with the requirements
          currently in effect as described in this License, you must purchase a
          commercial license from the Licensor, its affiliated entities, or authorized
          resellers, or you must refrain from using the Licensed Work.
          </p>
          <p className={classes.description}>
          All copies of the original and modified Licensed Work, and derivative works
          of the Licensed Work, are subject to this License. This License applies
          separately for each version of the Licensed Work and the Change Date may vary
          for each version of the Licensed Work released by Licensor.
          </p>
          <p className={classes.description}>
          You must conspicuously display this License on each original or modified copy
          of the Licensed Work. If you receive the Licensed Work in original or
          modified form from a third party, the terms and conditions set forth in this
          License apply to your use of that work.
          </p>
          <p className={classes.description}>
          Any use of the Licensed Work in violation of this License will automatically
          terminate your rights under this License for the current and all other
          versions of the Licensed Work.
          </p>
          <p className={classes.description}>
          This License does not grant you any right in any trademark or logo of
          Licensor or its affiliates (provided that you may use a trademark or logo of
          Licensor as expressly required by this License).
          </p>
          <p className={classes.description}>
          TO THE EXTENT PERMITTED BY APPLICABLE LAW, THE LICENSED WORK IS PROVIDED ON
          AN "AS IS" BASIS. LICENSOR HEREBY DISCLAIMS ALL WARRANTIES AND CONDITIONS,
          EXPRESS OR IMPLIED, INCLUDING (WITHOUT LIMITATION) WARRANTIES OF
          MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT, AND
          TITLE.
          </p>
          <p className={classes.description}>
          MariaDB hereby grants you permission to use this License’s text to license
          your works, and to refer to it using the trademark "Business Source License",
          as long as you comply with the Covenants of Licensor below.
          </p>
          <p className={classes.description}>
          -----------------------------------------------------------------------------
          </p>
          <p className={classes.description}>
          Covenants of Licensor
          </p>
          <p className={classes.description}>
          In consideration of the right to use this License’s text and the "Business
          Source License" name and trademark, Licensor covenants to MariaDB, and to all
          other recipients of the licensed work to be provided by Licensor:
          </p>
          <p className={classes.description}>
          1. To specify as the Change License the GPL Version 2.0 or any later version,
           or a license that is compatible with GPL Version 2.0 or a later version,
           where "compatible" means that software provided under the Change License can
           be included in a program with software provided under GPL Version 2.0 or a
           later version. Licensor may specify additional Change Licenses without
           limitation.
          </p>
          <p className={classes.description}>
          2. To either: (a) specify an additional grant of rights to use that does not
           impose any additional restriction on the right granted in this License, as
           the Additional Use Grant; or (b) insert the text "None".
          </p>
          <p className={classes.description}>
          3. To specify a Change Date.
          </p>
          <p className={classes.description}>
          4. Not to modify this License in any other way.
          </p>
          <p className={classes.description}>
          -----------------------------------------------------------------------------
          </p>
          <p className={classes.description}>
          Notice
          </p>
          <p className={classes.description}>
          The Business Source License (this document, or the "License") is not an Open
          Source license. However, the Licensed Work will eventually be made available
          under an Open Source License, as stated in this License.
          </p>
        </GridItem>
      </GridContainer>
      <GridContainer justifyContent="center">
        <GridItem xs={12} sm={12} md={8}>
          <h2 className={classes.title}>CapsuleNFT NFT License</h2>
          <h3 className={classes.description}>
          1.	Definitions.
          </h3>
          <p className={classes.description}>
          “Acquired NFT” means an NFT that you Own.
          </p>
          <p className={classes.description}>
          “Art” means any artwork, designs, photos, drawings or other content that may be associated with an Acquired NFT.
          </p>
          <p className={classes.description}>
          “NFT” means any blockchain-tracked, non-fungible token, such as those conforming to the ERC-721 standard.
          </p>
          <p className={classes.description}>
          “Own” means, with respect to an NFT, an NFT that has been created, purchased or otherwise rightfully acquired from a legitimate source, where proof of such purchase is recorded on the relevant blockchain.
          </p>
          <p className={classes.description}>
          “Extensions” means third party designs that: (i) are intended for use as extensions or overlays to the Art, (ii) do not modify the underlying Art, and (iii) can be removed at any time without affecting the underlying Art.
          </p>
          <p className={classes.description}>
          “Maker” means the person or entity that created, selected or otherwise determined the Art associated with the Acquired NFT.
          </p>
          <p className={classes.description}>
          “Third Party IP” means any third party patent rights (including, without limitation, patent applications and disclosures), copyrights, trade secrets, trademarks, know-how or any other intellectual property rights recognized in any country or jurisdiction in the world.
          </p>
          <h3 className={classes.description}>
          2.	Ownership.
          </h3>
          <p className={classes.description}>
          You acknowledge and agree that Maker (or, as applicable, its licensors) owns all legal right, title and interest in and to the Art, and all intellectual property rights therein. The rights that you have in and to the Art are limited to those described in this License. Maker reserves all rights in and to the Art not expressly granted to you in this License.
          </p>
          <h3 className={classes.description}>
          3.	License.
          </h3>
          <p className={classes.description}>
          Subject to your continued compliance with the terms of this License, Maker grants you a worldwide, non-exclusive, non-transferable, royalty-free license to use, copy, and display the Art, along with any Extensions that you choose to create or use, and to create derivative works of the Art for your Acquired NFTs, for any purpose except that you must not, nor permit any third party to attempt to register any trademark or copyright, or otherwise acquire additional intellectual property rights, in or to the Art for your Acquired NFTs.
          </p>
          <p className={classes.description}>
          To the extent that Art associated with your Acquired NFTs contains Third Party IP, you understand and agree that, depending on the nature of the license granted from the owner of the Third Party IP, Maker may need to pass through additional restrictions on your ability to use the Art, that you will be responsible for complying with all such restrictions, and that failure to do so will be deemed a breach of this license.
          </p>
          <h3 className={classes.description}>
          4.	License Term.
          </h3>
          <p className={classes.description}>
          The license granted in Section 3 above applies only while you continue to Own the applicable Acquired NFT. If at any time you sell, trade, donate, give away, transfer, or otherwise dispose of your Acquired NFT for any reason, the license granted in Section 3 will immediately expire with respect to such NFT without the requirement of notice, and you will have no further rights in or to the Art for those NFTs.
          </p>
        </GridItem>
      </GridContainer>
    </div>
  );
}
