import { useCallback, useEffect, useState } from 'react'
import { useERC721Contract } from 'hooks/useContract.js'
import {
  MainChainId, NFTMinterAddress, isAddress
} from 'utils'
import { useActiveWeb3React } from 'hooks/useActiveWeb3React.js'
import { useTransactionAdder } from 'state/transactions/hooks'

// material ui
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import Button from "components/CustomButtons/Button.js";

const ApproveERC721Token = function (props) {
  const addTransaction = useTransactionAdder()
  const { tokenAddress, id, amount } = props
  const { account } = useActiveWeb3React()
  const tokenERC721Contract = useERC721Contract(tokenAddress, true, { active: false })

  const [errorDiv, setErrorDiv] = useState(null)

  // approve single NFT
  const approveSingleToken = async (id) => {
    try {
      return tokenERC721Contract?.approve(NFTMinterAddress(MainChainId), id)
    } catch (e) {
      if (e.code === 'INVALID_ARGUMENT') {
        setErrorDiv('Error: Improper input ID value')
      } else {
        setErrorDiv(`${e}`)
      }
      return e
    }
  }

  // approve ALL NFTs
  const approveAllTokens = async (id) => {
    try {
      return tokenERC721Contract?.setApprovalForAll(NFTMinterAddress(MainChainId), true)
    } catch (e) {
      setErrorDiv(`${e}`)
      return e
    }
  }

  // approve single NFT
  const handleSingleApproval = useCallback(async () => {
    try {
        const tx = await approveSingleToken(id)
        addTransaction(tx, {summary: `Approve Successful`}, 'approveSingleNFT')
        setErrorDiv(null)
        return tx
    } catch (e) {
        if (e.hasOwnProperty('message')) {
          if (e.message.includes('INVALID_ARGUMENT')) {
            setErrorDiv('Error: Improper input ID value')
          } else if (e.message.includes('ERC721: approv')) {
            setErrorDiv('Error: NFT approval failed. Are you sure you own this NFT?')
          } else if (e.message.includes('ERC721: owner query for nonexistent token')) {
            setErrorDiv('Error: This NFT does not exist')
          } else {
            setErrorDiv(`${e.message}`)
          }
        } else {
          setErrorDiv(`${e}`)
        }

        return e
    }
  }, [account, tokenAddress, id, amount])

  // approve ALL NFTs in a collection
  const handleAllApproval = useCallback(async () => {
    try {
        const tx = await approveAllTokens()
        addTransaction(tx, {summary: `Approve Successful`}, 'approveAllNFTs')
        setErrorDiv(null)
        return tx
    } catch (e) {
        console.error(e)
        setErrorDiv(`${e.message}`)
        return e
    }
  }, [account, tokenAddress, id, amount])

  useEffect(async () => {
    setErrorDiv(null)
  }, [account, tokenAddress, id, amount])

  return (
    <>
      {
        errorDiv ?
            <GridItem xs={12}
              style={{
                marginBottom: "50px"
              }}
            >
              <GridContainer justifyContent="center">
                <Button
                  color="google"
                  size="lg"
                >ERROR</Button>
              </GridContainer>
              <GridContainer justifyContent="center">
                <p style={{
                  fontSize: '24px',
                  textAlign: 'center'
                }}>
                  <strong>{errorDiv}</strong>
                </p>
              </GridContainer>
            </GridItem>
          :
            null
      }
      {
        isAddress(tokenAddress) ?
          <Button
            color="danger"
            size="lg"
            onClick={amount === 'one' ? handleSingleApproval : handleAllApproval}
          >
            Approve {`${amount === 'one' ? 'Single NFT' : 'ALL NFTs in collection'}`}
          </Button>
        :
          ''
      }
    </>
  )
}

export default ApproveERC721Token
