import { memo } from 'react'

import TextField from '@material-ui/core/TextField'

const DropdownTextField = function (props) {
  const { params, classes, type } = props

  return (
    <TextField
      fullWidth
      {...params}
      label={type === 'erc20' ? "Input Token Address" : "Input Collection Address"}
      variant="outlined"
      classes={{
        root: classes.autocompleteTextfield,
      }}
      inputProps={{
        style: {
          color: "white"
        },
        ...params.inputProps,
        autoComplete: 'new-password',
      }}
    />
  )
}

export default memo(DropdownTextField)
