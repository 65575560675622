import { useMemo } from 'react'
import { useActiveWeb3React } from './useActiveWeb3React.js'
import { ethers } from "ethers"
import {
  getContract,
  isAddress,
  ExternalNodeMainnetUrl,
  ExternalNodeRopstenUrl,
  ExternalNodeRinkebyUrl
} from 'utils'

// contract ABIs
import NFTMinterContract from "contracts/CapsuleMinter.json"
import CapsuleNFTContract from "contracts/Capsule.json"
import CapsuleNFTFactoryContract from "contracts/CapsuleFactory.json"
import ERC20 from "contracts/ERC20.json"
import ERC721 from "contracts/ERC721.json"
import ERC1155 from "contracts/ERC1155.json"

// returns null on errors
export function useContract(address, ABI, withSignerIfPossible, useNode) {
    let { library, account } = useActiveWeb3React()

    if (useNode.active) {
      const chainId = useNode.chainId
      let url = getNodeUrl(chainId)

      if (url) {
        library = new ethers.providers.JsonRpcProvider(url, chainId)
      }
    }

    return useMemo(() => {
        if (!address || !ABI || !library) return null
        try {
            return getContract(address, ABI, library,
              withSignerIfPossible && account ? account : undefined)
        } catch (error) {
            console.error('Failed to get contract', error)
            return null
        }
    }, [address, ABI, library, account, withSignerIfPossible, useNode])
}

export function useNFTMinterContract(withSignerIfPossible, useNode) {
    let { chainId } = useActiveWeb3React()

    if (useNode.active) {
      chainId = useNode.chainId
    }

    return useContract(chainId ?
      (NFTMinterContract.networks[chainId] ?
        NFTMinterContract.networks[chainId].address :
        undefined
      ) :
      undefined,
      chainId ?
        (NFTMinterContract.networks[chainId] ?
          NFTMinterContract.networks[chainId].abi :
          undefined
        ) :
        undefined,
      withSignerIfPossible,
      useNode)
}

export function useCapsuleNFTFactoryContract(withSignerIfPossible, useNode) {
    let { chainId } = useActiveWeb3React()

    if (useNode.active) {
      chainId = useNode.chainId
    }

    return useContract(chainId ?
      (CapsuleNFTFactoryContract.networks[chainId] ?
        CapsuleNFTFactoryContract.networks[chainId].address :
        undefined
      ) :
      undefined,
      chainId ?
        (CapsuleNFTFactoryContract.networks[chainId] ?
          CapsuleNFTFactoryContract.networks[chainId].abi :
          undefined
        ) :
        undefined,
      withSignerIfPossible,
      useNode)
}

export function useCapsuleNFTContract(tokenAddress, withSignerIfPossible, useNode) {
    let { chainId } = useActiveWeb3React()

    if (useNode.active) {
      chainId = useNode.chainId
    }

    return useContract(
      isAddress(tokenAddress) ?
        tokenAddress :
        null,
      chainId ?
        (CapsuleNFTContract.networks[chainId] ?
          CapsuleNFTContract.networks[chainId].abi :
          undefined
        ) :
        undefined,
      withSignerIfPossible,
      useNode)
}

export function useERC20Contract(tokenAddress, withSignerIfPossible, useNode) {
    let { chainId } = useActiveWeb3React()

    if (useNode.active) {
      chainId = useNode.chainId
    }

    return useContract(
      isAddress(tokenAddress) ?
        tokenAddress :
        null,
      chainId ?
        (ERC20.networks[chainId] ?
          ERC20.networks[chainId].abi :
          undefined
        ) :
        undefined,
      withSignerIfPossible,
      useNode)
}

export function useERC721Contract(tokenAddress, withSignerIfPossible, useNode) {
    let { chainId } = useActiveWeb3React()

    if (useNode.active) {
      chainId = useNode.chainId
    }

    return useContract(
      isAddress(tokenAddress) ?
        tokenAddress :
        null,
      chainId ?
        (ERC721.networks[chainId] ?
          ERC721.networks[chainId].abi :
          undefined
        ) :
        undefined,
      withSignerIfPossible,
      useNode)
}

export function useERC1155Contract(tokenAddress, withSignerIfPossible, useNode) {
  let { chainId } = useActiveWeb3React()

  if (useNode.active) {
    chainId = useNode.chainId
  }

  return useContract(
    isAddress(tokenAddress) ?
      tokenAddress :
      null,
    chainId ?
      (ERC1155.networks[chainId] ?
        ERC1155.networks[chainId].abi :
        undefined
      ) :
      undefined,
    withSignerIfPossible,
    useNode)
}

export function getNodeUrl(chainId) {
  let url = null

  switch(chainId) {
    case 1:
      url = ExternalNodeMainnetUrl
      break;
    case 3:
      url = ExternalNodeRopstenUrl
      break;
    case 4:
      url = ExternalNodeRinkebyUrl
      break;
    default:
      //url = ''
  }

  return url
}
