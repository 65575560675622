import React from "react"

import "react-responsive-carousel/lib/styles/carousel.min.css" // requires a loader
import { Carousel } from 'react-responsive-carousel'

import ReactPlayer from 'react-player' // video player

//import { makeStyles } from "@material-ui/core/styles"
//import styles from "assets/jss/material-kit-react/views/landingPageSections/workStyle.js"
//const useStyles = makeStyles(styles)

export default function VideoCarousel() {
  const customRenderItem = (item, props) => <item.type {...item.props} {...props} />

  const getVideoThumb = (videoId) => `https://img.youtube.com/vi/${videoId}/default.jpg`

  const getVideoId = (url) => url.substr('https://www.youtube.com/embed/'.length, url.length)

  const YoutubeSlide = ({ url, isSelected }: { url: string; isSelected?: boolean }) => (
    <div
      style={{
        height: "600px",
        paddingBottom: "20px"
      }}
    >
      <ReactPlayer
        controls
        height="100%"
        width="100%"
        url={url}
        playing={false}
      />
    </div>
  )

  const customRenderThumb = (children) =>
    children.map((item) => {
      const videoId = getVideoId(item.props.url)
      return (
        <img
          key={`yt${item.props.url}`}
          alt="Capsule Youtube Walkthrough"
          src={getVideoThumb(videoId)}
        />
      )
    })

  return (
    <Carousel
      renderItem={customRenderItem}
      renderThumbs={customRenderThumb}
      thumbWidth={150}
    >
      <YoutubeSlide key="youtube-1" url="https://www.youtube.com/embed/Mmf9Nklcg6A" />
      <YoutubeSlide key="youtube-2" url="https://www.youtube.com/embed/lyKG2W1XcnI" />
      <YoutubeSlide key="youtube-3" url="https://www.youtube.com/embed/53vgzID3sWo" />
    </Carousel>
  )
}
