import React from 'react';

import { useState, useEffect } from 'react'

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from "components/CustomButtons/Button.js";
import Typography from '@material-ui/core/Typography';
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import OpenSeaIcon from "assets/img/opensea-icon.png"
import CapsuleScanImage from "assets/img/capsule/capsulescan-graphic.png"

// import required contracts
import { AddressZero } from "@ethersproject/constants";
import {
  MainChainId,
  IPFSNodeURL,
  IPFSGatewayURL,
  IPFSEndpoint,
  IPFSEndpointId,
  IPFSEndpointSecret,
  OpenSeaCapsuleUrl,
  getLibraryDirect,
  getGeneralCapsuleNFTContractDirect,
  shortenAddress
} from 'utils'
import { useNFTMinterContract } from 'hooks/useContract.js'
import CapsuleTokenInfo from "components/NFTDataCard/CapsuleTokenInfo.js"

import CopyButton from 'components/CopyButton'

// import loading capsule gif
import loadingCapsule from "assets/img/capsule/animations/loading-capsule-animation-gif.gif"

// ipfs data
const ipfsClient = require('ipfs-http-client')
const all = require('it-all')
const uint8ArrayConcat = require('uint8arrays/concat')
const uint8ArrayToString = require('uint8arrays/to-string')

export default function NFTDataCard(props) {
  //const { chainId } = useActiveWeb3React()

  const { nftAddress, nftId, height, width, type } = props

  const nsfwIdArray = []

  // nft data to present on site
  let nftDataObject = {
    description: '',
    image: null,
    name: '',
    attributes: [],
    capsule: null
  }

  //const [isCapsule, setIsCapsule] = useState(false)
  const [nftData, setNftData] = useState(nftDataObject)
  const [capsuleName, setCapsuleName] = useState('')

  // connect straight to node, not to injected
  const useNode = {
    active: true,
    chainId: MainChainId
  }

  const nftMinterContract = useNFTMinterContract(false, useNode)

  useEffect(async () => {
    // direct library
    const library = getLibraryDirect(useNode)

    // nft collection contract object
    const nftContractObject = getGeneralCapsuleNFTContractDirect(
      nftAddress,
      useNode.chainId,
      library
    )

    // nftMinter direct checks
    const getTokenERC20Capsule = async (address, id) => {
      return nftMinterContract?.singleERC20Capsule(address, id)
    }

    const getMultiTokenERC20Capsule = async (address, id) => {
      return nftMinterContract?.multiERC20Capsule(address, id)
    }

    const getMultiERC1155Capsule = async (nftAddress, index) => {
      // multiERC1155Capsule (no single)
      return nftMinterContract?.multiERC1155Capsule(nftAddress, index)
    }

    // nftBase functions
    const getTokenURI = async (nftContract, id) => {
      return nftContract?.tokenURI(id)
    }

    const getIpfsHash = async (nftContractObject, id) => {
      try {
        const hash = await getTokenURI(nftContractObject, id)
        return hash ? hash : null
      } catch (e) {
        console.error(e)
        // can check against: e.reason)
        // reason="ERC721URIStorage: URI query for nonexistent token"
        return null
      }
    }

    const getCapsule = async (id) => {
      try {
        let struct = {}

        await Promise.allSettled([
          new Promise(async (resolve, reject) => {
            await getTokenERC20Capsule(nftAddress, id)
              .then(async function (result) {
                const stashedErc20Address = result?.tokenAddress
                const stashedErc20Amount = result?.tokenAmount.toString()
                if (stashedErc20Address !== AddressZero) {
                  struct = {
                    id,
                    token: stashedErc20Address,
                    amount: stashedErc20Amount
                  }
                  resolve(true)
                }
                reject(false)
              })
          }),
          new Promise(async (resolve, reject) => {
            await getMultiTokenERC20Capsule(nftAddress, id)
              .then(async function (result) {
                const stashedErc20Addresses = result?.tokenAddresses
                const stashedErc20Amounts = result?.tokenAmounts
                if (stashedErc20Addresses && stashedErc20Addresses?.length > 0) {
                  struct = {
                    id,
                    tokens: stashedErc20Addresses,
                    amounts: stashedErc20Amounts
                  }
                  resolve(true)
                }
                reject(false)
              })
          }).then(function (result) {
            for (let r = 0; r < result.length; r++) {
              if (result[r].status === 'fulfilled') {
                return
              }
            }
            // if we've gone through all capsule info and all failed to return data
            // it must be a simple capsule
          }).catch(function (error) {
            console.error(`could not get underlying value of nft at index ${id}`, error)
            return null
          })
        ])
        return struct
      } catch (e) {
        console.error(e)
        return null
      }
    }

    const loadNFT = async (id) => {
      let metadataObject = nftDataObject

      setNftData(metadataObject)

      const unknownMetadata = {
        description: 'We don\'t know much about this NFT. It either ' +
          'does not exist or was a Capsule NFT redeemed for value.',
        image: null,
        name: 'Unknown',
        type: 'img',
        attributes: ['Error'],
        capsule: null
      }

      const nsfwMetadata = {
        description: 'capsulenft.com does not support this content. ' +
          'Don\'t worry! Your Capsule NFT, Capsule NFT metadata, and potential redeeming functions ' +
          'still exist. For now, you can view it elsewhere, such as OpenSea.',
        image: null,
        name: 'Unsupported Capsule NFT',
        type: 'img',
        attributes: ['NSFW'],
        capsule: null
      }

      const getNFTName = async (contract) => {
        try {
          return contract?.name()
        } catch (e) {
          console.log(e, 'issue getting NFT Name')
          return '?'
        }
      }

      setCapsuleName(nftContractObject ? await getNFTName(nftContractObject) : null)

      // nsfw content
      if (nsfwIdArray.includes(id)) {
        setNftData(nsfwMetadata)
        return
      }

      const uri = await getIpfsHash(nftContractObject, id)

      const makeGatewayURL = (ipfsURI) => {
          return `https://ipfs.infura.io/ipfs/${stripIpfsUriPrefix(ipfsURI)}`
          //return IPFSGatewayURL + '/' + stripIpfsUriPrefix(ipfsURI)
      }

      // no uri/null
      if (!uri) {
        setNftData(unknownMetadata)
        return
      } else if (uri.includes('data:application/json;utf8,{"name":"')) {
        const onchainMetadata = JSON.parse(uri.substring(27)) // for cyberbrokers
        if (onchainMetadata.image) {
          onchainMetadata.image = makeGatewayURL(onchainMetadata.image)
        }
        onchainMetadata.type = 'img'
        setNftData(onchainMetadata)
        return
      }

      // else if (nsfwIdArray.includes(id))

      // connect to the ipfs node
      //let ipfsClient = create(IPFSNodeURL)
      function stripIpfsUriPrefix(cidOrURI) {
        if (cidOrURI.startsWith('ipfs://')) {
           return cidOrURI.slice('ipfs://'.length)
        }
        return cidOrURI
      }

      const getIPFS = async (cidOrURI) => {
          if (!cidOrURI) {
            return null
          }
          const cid = stripIpfsUriPrefix(cidOrURI)
          //console.log('cid', cid)

          const auth =
            'Basic ' + Buffer.from(IPFSEndpointId + ':' + IPFSEndpointSecret).toString('base64')

          //console.log(IPFSEndpoint)

          const client = ipfsClient.create({
            host: 'ipfs.infura.io',
            port: 5001,
            protocol: 'https',
            headers: {
              authorization: auth
            }
          })

          //console.log(client)

          return uint8ArrayConcat(await all(client.cat(cid)))
          //return uint8ArrayConcat(await all(ipfsClient.cat(cid)))
      }

      const getIPFSString = async (cidOrURI) => {
          const bytes = await getIPFS(cidOrURI)
          return bytes ? uint8ArrayToString(bytes) : null
      }

      const getIPFSJSON = async (cidOrURI) => {
          const str = await getIPFSString(cidOrURI)
          return str ? JSON.parse(str) : null
      }

      const getHTTPJSON = async (uri) => {
        const response = await fetch(uri)
        const data = await response.json()
        return data
      }

      // sets whether it is a capsule through state
      const capsuleInfo = await getCapsule(id)

      let metadata = null

      try {
        if (uri) {
          if (uri?.substring(0, 4).toLowerCase() === 'http') {
            metadata = await getHTTPJSON(uri)
          } else {
            metadata = await getIPFSJSON(uri)
          }
        }
      } catch (e) {
        console.log('Failed to get metadata', e)
        const unableToFetchMetadata = {
          description: 'capsulenft.com was unable to get the metadata for this NFT.',
          image: null,
          name: 'Cannot get NFT Metadata',
          type: 'img',
          attributes: ['No Metadata'],
          capsule: capsuleInfo
        }
        setNftData(unableToFetchMetadata)
        return
      }

      let metadataGatewayURL = ''

      if (metadata?.image) {
        if (metadata?.image.substring(0, 4).toLowerCase() === 'http') {
          metadataGatewayURL = metadata?.image
        } else {
          metadataGatewayURL = makeGatewayURL(metadata?.image)
        }
      }

      // assetDataBase64 await this.getIPFSBase64(metadata.image)

      if (metadata) {
        const type = metadataGatewayURL
          ?
            // check for file types - needs heavy cleaning up
            (metadataGatewayURL.toLowerCase().includes('.mp4')
              ||
            metadataGatewayURL.toLowerCase().includes('.mov')
              ||
            metadataGatewayURL.toLowerCase().includes('.avi'))
              ?
                "video"
              :
                (metadataGatewayURL.toLowerCase().includes('.mp3')
                  ||
                metadataGatewayURL.toLowerCase().includes('.wav')
                  ||
                metadataGatewayURL.toLowerCase().includes('.aac')
                  ||
                metadataGatewayURL.toLowerCase().includes('.flac'))
                  ?
                    "audio"
                  :
                    "img"
          :
            "img"

        metadataObject = {
          description: metadata.description,
          image: metadataGatewayURL,
          name: metadata.name,
          type,
          attributes: metadata.attributes,
          capsule: capsuleInfo
        }
      }

      setNftData(metadataObject)

      //const getNFTSymbol = async (contract) => {
      //  return contract?.symbol()
      //}

      //setCapsuleSymbol(await getNFTSymbol(nftContractObject))
    }

    loadNFT(nftId)
  }, [nftAddress, nftId])

  /*

  description: metadata.description,
  image: metadataGatewayURL,
  name: metadata.name,
  attributes: metadata.attributes,
  capsule: capsuleInfo

  {"description":"testtt",
  "image":"http://localhost:8080/ipfs/bafybeicnrvoawousxziicza6ysg4ww6k5mdaiboaz4igdictb2cmz2mkyq/received_508573383804012.jpeg",
  "name":"test",
  "attributes":[{"trait_type":"Capsule Token 1","value":"0x3507925Bd7edD7dEc054F391B42Ba84B2170e740, 10000000000000000000"}],
  "capsule":{"id":"2","token":"0x3507925Bd7edD7dEc054F391B42Ba84B2170e740",
  "amount":"2000000000000000000"}}

  */

  // needs editting for mainnet! only works for rinkeby...
  const openseaLink = `${OpenSeaCapsuleUrl(MainChainId, nftAddress)}/${nftId}`

  return (
    <Card
      style={{
        border: "2.5px solid #6f40ff",
        background: 'black',
        color: 'white'
      }}
    >
      { nftData.attributes.length > 0 ?
          <>
            <Typography gutterBottom variant="h5" component="h2"
              style={{
                textAlign: 'center',
                marginTop: '10px'
              }}
            >
              {`Collection: ${capsuleName || 'Unknown...'}${nftAddress ? ` - ${shortenAddress(nftAddress)}` : ''}`}
              <CopyButton
                copyText={nftAddress}
                width={"14px"}
                height={"14px"}
              />
            </Typography>
            <Typography gutterBottom
              style={{textAlign: 'center'}}
            >
              {
                nftData.capsule
                  ?
                    <>
                      <p style={{fontSize: '1.2rem'}}>This Capsule contains:</p>
                      {
                        nftData.capsule.tokens
                          ?
                              nftData.capsule.tokens.map(function(token, i) {
                                return (
                                  <div
                                    key={i}
                                  >
                                    <CapsuleTokenInfo
                                      tokenAddress={token}
                                      tokenId={nftData.capsule.ids[i] || null}
                                      amount={nftData.capsule.amounts[i] || 0}
                                    />
                                  </div>
                                )
                              })
                          :
                            <CapsuleTokenInfo
                              amount={nftData.capsule?.amount}
                              tokenId={null}
                              tokenAddress={nftData.capsule?.token}
                            />
                      }
                    </>
                  :
                    null
              }
            </Typography>
            {
              ((nftId === 0 || nftId) &&
              <Typography variant="h5" component="p"
                style={{textAlign: 'center'}}
              >
                {`${type ? `${type} - ` : ''}ID (${nftId})`}
              </Typography>)
            }
            <CardMedia
              component={nftData.type}
              alt="Capsule..."
              style={{
                maxHeight: (height || '100%'),
                width: (width || '100%'),
                objectFit: 'scale-down'
              }}
              height={height}
              width={width}
              src={nftData.image}
              title={nftData.name}
              controls
            />
            <CardContent
              style={{
                paddingBottom: "10px"
              }}
            >
              <Typography gutterBottom variant="h5" component="h2"
                style={{textAlign: 'center'}}
              >
                {nftData.name}
              </Typography>
              <Typography variant="body2" component="p"
                style={{textAlign: 'center'}}
              >
                {nftData.description}
              </Typography>
              <GridContainer
                style={{
                  marginTop: "10px",
                  textAlign: "center"
                }}
              >
                <GridItem xs={12} sm={12}>
                  <Button
                    color="danger"
                    size="md"
                    href={`/capsule/${nftAddress}/${nftId}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      padding: "1.5px 20px"
                    }}
                  >
                    <img alt='CapsuleScan' src={CapsuleScanImage} style={{height: "38px"}}/>
                  </Button>
                  <Button
                    color="danger"
                    size="md"
                    href={openseaLink || null}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      padding: "8.5px 20px"
                    }}
                  >
                    <img alt='OpenSea' src={OpenSeaIcon} style={{width: "24px"}}/>
                  </Button>
                </GridItem>
              </GridContainer>
            </CardContent>
          </>
        :
          <>
            <Typography gutterBottom
              style={{textAlign: 'center'}}
            >
              Loading...
            </Typography>
            <CardMedia
              component="img"
              alt="Loading Capsule..."
              style={{
                height: (height || '100%'),
                width: (width || '100%'),
                objectFit: 'scale-down'
              }}
              height={height}
              width={width}
              image={loadingCapsule}
              title={`Capsule ${nftId}`}
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="h2"
                style={{textAlign: 'center'}}
              >
                {'Loading...'}
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p">
                {'Loading...'}
              </Typography>
            </CardContent>
          </>
      }
    </Card>
  );
}

/*

<Button
  color="danger"
  size="md"
  href={`capsule/${nftAddress}/${nftId}`}
  target="_blank"
>
  View on CapsuleScan
</Button>
<Button
  color="danger"
  size="md"
  href={openseaLink || null}
  target="_blank"
>
  View on OpenSea
</Button>

*/
