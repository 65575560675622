import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import AppHeader from "components/LandingPage/Header/Header.js";
import HeaderLinks from "components/LandingPage/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";

import License from "./License.js";

import styles from "assets/jss/material-kit-react/views/components.js";

const useStyles = makeStyles(styles);

export default function LicensePage(props) {
  const classes = useStyles()
  const { ...rest } = props;

  return (
    <div>
      <AppHeader
        absolute
        color="transparent"
        brand="CapsuleNFT"
        rightLinks={<HeaderLinks />}
        {...rest}
      />
      <div
        className={classes.pageHeader}
      >
        <div
          className={classes.container}
          style={{paddingTop: 5}}
        >
          <License/>
        <Footer whiteFont />
        </div>
      </div>
    </div>
  )
}
