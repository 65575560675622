import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
//import { load, save } from 'redux-localstorage-simple'
import { updateVersion } from 'state/global/actions.js'

import application from './application/reducer.js'
import transactions from 'state/transactions/reducer.js'

const store = configureStore({
  reducer: {
    application,
    transactions
  },
  middleware: [
    ...getDefaultMiddleware({ thunk: false, immutableCheck: false })
  ]
})

store.dispatch(updateVersion())

export default store

export type AppState = store.getState
export type AppDispatch = store.dispatch
