import { useState } from 'react';

import {
  shortenAddress,
  getEtherscanLinkWithHash
} from 'utils'

import { useActiveWeb3React } from 'hooks/useActiveWeb3React.js'

import Button from "components/CustomButtons/Button.js";
import Menu from '@mui/material/Menu';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';

import { makeStyles } from "@material-ui/core/styles";

// redux user txs
import { useSelector } from 'react-redux'

import styles from "assets/jss/material-kit-react/components/headerLinksStyle.js";

const useStyles = makeStyles(styles)

const HistoryDropdown = function (props) {
  const classes = useStyles()

  const [anchorEl, setAnchorEl] = useState(null)

  const open = Boolean(anchorEl)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const { chainId } = useActiveWeb3React()

  // current test for loading transactions
  const transactionSelector = useSelector(state => state.transactions)
  const transactionState = chainId ? transactionSelector[chainId] ?? null : null

  const baseResponse = (<Alert severity='info'>
    No transactions found
  </Alert>)

  return (
    <div
      style={{
        display: 'inherit'
      }}
    >
      <Button
        id="history-button"
        color="transparent"
        style={{fontSize: '14px'}}
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        History
      </Button>
      <Menu
        className={classes.menu}
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'history-button',
          'style': {
            paddingTop: '0px',
            paddingBottom: '0px'
          }
        }}
      >
        {
          (transactionState
            ?
              Object.keys(transactionState).length > 0 ?
                Object.keys(transactionState).reverse().map(function(obj, i) {
                  const tx = transactionState[obj]
                  const hasReceipt = tx.hasOwnProperty('receipt')

                  return (hasReceipt ?
                      <Alert severity='success'>
                        <AlertTitle>Confirmed</AlertTitle>
                        <a href={getEtherscanLinkWithHash(tx.hash)} target="_blank" rel="noreferrer">{`${tx.type}: Transaction ${shortenAddress(tx.hash)}`}</a>
                      </Alert>
                    :
                      <Alert severity='info'>
                        <AlertTitle>Pending</AlertTitle>
                        <a href={getEtherscanLinkWithHash(tx.hash)} target="_blank" rel="noreferrer">{`${tx.type}: Transaction ${shortenAddress(tx.hash)}`}</a>
                      </Alert>
                  )
                })
              :
                baseResponse
            :
              baseResponse
          )
        }
      </Menu>
    </div>
  )
}

export default HistoryDropdown


/*

if (tx['summary'] === 'Capsule Sucessfully Burned') {
  if (tx.hasOwnProperty('receipt')) {
    return 'done'
  }
} else if (tx['summary'] === 'Capsule Successfully Minted') {
  if (tx.hasOwnProperty('receipt')) {
    return 'done'
  }
}

                transactions.slice(0).reverse().map(function(obj, index){
                  return (
                    <Card
                      style={{
                        border: "2.5px solid #6f40ff",
                        background: 'black',
                        marginBottom: '10px',
                        color: 'white'
                      }}
                    >
                      <Typography gutterBottom variant="h5" component="h2"
                        style={{
                          textAlign: 'center',
                          background: 'rgb(111, 64, 255)'
                        }}
                      >
                        {`${txText(obj)}`}
                      </Typography>
                      <CardContent>
                        <Typography gutterBottom variant="h5" component="h2"
                          style={{textAlign: 'center'}}
                        >
                          {
                            !obj.done
                              ?
                                (
                                  <span>
                                    <CircularProgress
                                      size={24}
                                    />
                                    {'  Pending transaction: '}
                                    <a
                                      href={getEtherscanLinkWithHash(obj.tx)}
                                      target="_blank"
                                    >
                                      View on Etherscan
                                    </a>
                                  </span>
                                )
                              :
                                (
                                  <span>
                                    <CheckCircleIcon/>
                                    {'  Finished transaction: '}
                                    <a
                                      href={getEtherscanLinkWithHash(obj.tx)}
                                      target="_blank"
                                    >
                                      View on Etherscan
                                    </a>
                                  </span>
                                )
                          }
                        </Typography>
                        <Typography variant="body2" component="p"
                          style={{textAlign: 'center'}}
                        >
                          { `Transaction Hash: ${obj.tx}` }
                          <CopyButton
                            copyText={obj.tx}
                            width={"14px"}
                            height={"14px"}
                          />
                        </Typography>
                        <Typography variant="body2" component="p"
                          style={{textAlign: 'center'}}
                        >
                        </Typography>
                        <Typography variant="body2" component="p"
                          style={{textAlign: 'center'}}
                        >
                        </Typography>
                      </CardContent>
                    </Card>
                  )
                })
              }
            </div>
          </Collapse>
      </div>
    </>

*/
