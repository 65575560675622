import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import styles from "assets/jss/material-kit-react/components/nftDataCardStyle.js"

import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';

import {
  shortenAddress
} from 'utils'
import CapsuleTokenInfo from "components/NFTDataCard/CapsuleTokenInfo.js"

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

// blank image
import blankImage from "assets/img/blank-image.png";

const useStyles = makeStyles(styles);

const thumbsContainer = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  marginTop: 16,
  marginLeft: 9
};

const thumb = {
  display: 'inline-flex',
  marginBottom: 8,
  marginRight: 8,
  maxWidth: (window.innerWidth - 30),
  height: 300,
  padding: 4,
  boxSizing: 'border-box'
};

const thumbInner = {
  display: 'flex',
  minWidth: 0,
  overflow: 'hidden'
};

export default function NFTDataCard(props) {
  const classes = useStyles();
  //const { chainId } = useActiveWeb3React()

  const {
    nftAddress,
    nftId,

    image,
    name,
    description,
    type,
    insideTokenData,

    height,
    width
  } = props

  const nsfwIdArray = []

  /*

  description: metadata.description,
  image: metadataGatewayURL,
  name: metadata.name,
  attributes: metadata.attributes,
  capsule: capsuleInfo

  {"description":"testtt",
  "image":"http://localhost:8080/ipfs/bafybeicnrvoawousxziicza6ysg4ww6k5mdaiboaz4igdictb2cmz2mkyq/received_508573383804012.jpeg",
  "name":"test",
  "attributes":[{"trait_type":"Capsule Token 1","value":"0x3507925Bd7edD7dEc054F391B42Ba84B2170e740, 10000000000000000000"}],
  "capsule":{"id":"2","token":"0x3507925Bd7edD7dEc054F391B42Ba84B2170e740",
  "amount":"2000000000000000000"}}

  */

  const emptyImage = <img alt='No Attachment' src={blankImage}/>

  return (
    <Card
      style={{
        border: "2.5px solid #6f40ff",
        background: 'black',
        color: 'white'
      }}
    >
      <CardActionArea
        className={classes.completelyRemoveHover}
      >
        {
          (nftAddress &&
            <Typography gutterBottom variant="h5" component="h2"
              style={{
                textAlign: 'center',
                marginTop: '10px'
              }}
            >
              {`Collection: - ${shortenAddress(nftAddress)}`}
            </Typography>
          )
        }
        <Typography gutterBottom
          style={{textAlign: 'center'}}
        >
          {
            (insideTokenData) ?
              (type === 'erc20-capsule' && insideTokenData.length > 0)
                ?
                  <>
                    {'This Capsule contains: '}
                    {
                      insideTokenData.map(function(token, i) {
                        return (
                          <p>
                            <CapsuleTokenInfo
                              hardCodedAmount={token.amount || 0}
                              tokenAddress={token.address}
                            />
                          </p>
                        )
                      })
                    }
                  </>
                :
                  (type === 'erc721-capsule' && insideTokenData.length > 0)
                    ?
                      (insideTokenData?.amount && insideTokenData?.address) ?
                        `This Capsule contains: ${
                          insideTokenData.amount
                        } from ${
                          insideTokenData.address
                        }`
                      :
                        ''
                    :
                      ''
              :
                ''
          }
        </Typography>
        {
          (nftId &&
            <Typography variant="h5" component="p"
              style={{textAlign: 'center'}}
            >
              {`ID (${nftId})`}
            </Typography>
          )
        }
        <CardMedia
          component={type}
          alt="Capsule..."
          style={{
            maxHeight: (height || '100%'),
            width: (width || '100%'),
            objectFit: 'scale-down'
          }}
          height={height}
          width={width}
          title={name}
          controls
        />
        <CardContent>
          <GridItem xs={12}>
            <GridContainer alignItems="center" justifyContent="center">
              <aside style={thumbsContainer}>
                <div style={thumb}>
                  <div style={thumbInner}>
                    { image.length > 0 ? image : emptyImage }
                  </div>
                </div>
              </aside>
            </GridContainer>
          </GridItem>
          <Typography gutterBottom variant="h5" component="h2"
            style={{textAlign: 'center'}}
          >
            {name}
          </Typography>
          <Typography variant="body2" component="p"
            style={{textAlign: 'center'}}
          >
            {description}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
