import {
  primaryColor,
  dangerColor,
  roseColor,
  grayColor,
  container
} from "assets/jss/material-kit-react.js";

const tabsStyle = {
  section: {
    background: "#EEEEEE",
    padding: "70px 0",
  },
  container,
  textCenter: {
    textAlign: "center",
  },
  pagination: {
    "& > *": {
      marginTop: "10px",
      justifyContent: "center",
      display: "flex"
    },
    "& .MuiPaginationItem-root": {
      color: "#fff"
    },
    "& .MuiPagination-outlined": {
      color: "#fff"
    },
    '& .Mui-selected': {
      backgroundColor: '#fff',
      color: "black"
    },
  },
  aboutPage: {
    //border: "2.5px solid rgb(65, 7, 106)",
    marginBottom: "50px",
    paddingLeft: "40px",
    paddingRight: "40px",
    "& p": {
      fontSize: "17px"
    }
  },
  capsuleLink: {
    color: "#6e3fff",
    "&:link, &:visited": {
      color: "#6e3fff",
    },
    "&:hover, &:active": {
      color: "white"
    }
  },
  purpleBorder: {
    //border: "5px solid #9128ac",
    borderRadius: "50px"
  },
  checkRoot: {
    padding: "12px",
    "&:hover": {
      backgroundColor: "unset",
    },
  },
  radioRoot: {
    padding: "12px",
    "&:hover": {
      backgroundColor: "unset",
    },
  },
  labelRoot: {
    marginLeft: "-14px",
  },
  checkboxAndRadio: {
    position: "relative",
    display: "block",
    marginTop: "10px",
    marginBottom: "10px",
  },
  checkboxAndRadioHorizontal: {
    position: "relative",
    display: "block",
    "&:first-child": {
      marginTop: "10px",
    },
    "&:not(:first-child)": {
      marginTop: "-14px",
    },
    marginTop: "0",
    marginBottom: "0",
  },
  checked: {
    color: 'white' + "!important",
  },
  checkedIcon: {
    width: "20px",
    height: "20px",
    border: "1px solid white",
    borderRadius: "3px",
  },
  uncheckedIcon: {
    width: "0px",
    height: "0px",
    padding: "9px",
    border: "1px solid white",
    borderRadius: "3px",
  },
  disabledCheckboxAndRadio: {
    opacity: "0.45",
  },
  label: {
    cursor: "pointer",
    paddingLeft: "0",
    color: "white",
    fontSize: "14px",
    lineHeight: "1.428571429",
    fontWeight: "400",
    display: "inline-flex",
    transition: "0.3s ease all",
    letterSpacing: "unset",
  },
  labelHorizontal: {
    color: "rgba(0, 0, 0, 0.26)",
    cursor: "pointer",
    display: "inline-flex",
    fontSize: "14px",
    lineHeight: "1.428571429",
    fontWeight: "400",
    paddingTop: "39px",
    marginRight: "0",
    "@media (min-width: 992px)": {
      float: "right",
    },
  },
  labelHorizontalRadioCheckbox: {
    paddingTop: "22px",
  },
  labelLeftHorizontal: {
    color: "rgba(0, 0, 0, 0.26)",
    cursor: "pointer",
    display: "inline-flex",
    fontSize: "14px",
    lineHeight: "1.428571429",
    fontWeight: "400",
    paddingTop: "22px",
    marginRight: "0",
  },
  labelError: {
    color: dangerColor,
  },
  radio: {
    color: primaryColor + "!important",
  },
  radioChecked: {
    width: "16px",
    height: "16px",
    border: "1px solid " + primaryColor,
    borderRadius: "50%",
  },
  radioUnchecked: {
    width: "0px",
    height: "0px",
    padding: "7px",
    border: "1px solid rgba(0, 0, 0, .54)",
    borderRadius: "50%",
  },
  inlineChecks: {
    marginTop: "8px",
  },
  iconCheckbox: {
    height: "116px",
    width: "116px",
    color: grayColor,
    "& > span:first-child": {
      borderWidth: "4px",
      borderStyle: "solid",
      borderColor: "#CCCCCC",
      textAlign: "center",
      verticalAlign: "middle",
      borderRadius: "50%",
      color: "inherit",
      margin: "0 auto 20px",
      transition: "all 0.2s",
    },
    "&:hover": {
      color: roseColor,
      "& > span:first-child": {
        borderColor: roseColor,
      },
    },
  },
  iconCheckboxChecked: {
    color: roseColor,
    "& > span:first-child": {
      borderColor: roseColor,
    },
  },
  iconCheckboxIcon: {
    fontSize: "40px",
    lineHeight: "111px",
  },
  switchBase: {
    color: "#6f40ff" + "!important",
  },
  switchIcon: {
    boxShadow: "0 1px 3px 1px rgba(0, 0, 0, 0.4)",
    color: "#FFFFFF !important",
    border: "1px solid rgba(0, 0, 0, .54)",
  },
  switchBar: {
    width: "30px",
    height: "15px",
    backgroundColor: "rgb(80, 80, 80)",
    borderRadius: "15px",
    opacity: "0.7!important",
  },
  switchChecked: {
    "& + $switchBar": {
      backgroundColor: "#6f40ff !important",
    },
    "& $switchIcon": {
      borderColor: "#9c27b0",
    },
  },
  switchRoot: {
    height: "48px",
  },
  autocompleteTextfield: {
    // label color
    "& .MuiFormLabel-root": {
      color: "white",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#6f40ff"
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "#6f40ff"
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#6f40ff"
    },
    color: "white",
  },
  pureTextfield: {
    // label color
    "& .MuiFormLabel-root": {
      color: "white",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#6f40ff"
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "#6f40ff"
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#6f40ff"
    },
    color: "white",
  },
  autocomplete: {
    "& .MuiAutocomplete-clearIndicator": {
      color: "white"
    }
  },
  tokenAllowance: {
    marginTop: "5px",
    paddingLeft: "10px",
    fontSize: "1rem",
    fontWeight: "500"
  },
  aboutCapsule: {
    textAlign: "left",
    "& > *": {
      textAlign: "left"
    },
    "& .smallText": {
      margin: "0px"
    },
    "& h3": {
      marginTop: "0px"
    },
  },
  textColor: {
    color: "#6f40ff"
  }
};

export default tabsStyle;
