import { useState } from 'react'

import Button from "components/CustomButtons/Button.js";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

// NFT Data card
import NFTDataCard from "components/NFTDataCard"

import { makeStyles } from "@material-ui/core/styles";

import styles from "assets/jss/material-kit-react/views/componentsSections/tabsStyle.js";

const useStyles = makeStyles(styles);

const BurnCapsule = function (props) {
  const { address, id, type, handleBurn } = props

  const classes = useStyles();

  const [burnDialogOpen, setBurnDialogOpen] = useState(false)

  const handleClickOpen = () => {
    setBurnDialogOpen(true)
  }

  const handleClose = () => {
    setBurnDialogOpen(false)
  }

  return (
    <>
      <Button
        color="warning"
        size="lg"
        onClick={handleClickOpen}
        variant="contained"
      >
        Redeem this Capsule NFT (ID {id})
      </Button>
      <Dialog
        open={burnDialogOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          style={{
            textAlign: "center",
            background: 'black',
            color: 'white',
            border: '2px solid white',
            borderBottomWidth: '0px'
          }}
        >
          {`Redeem Capsule`}
        </DialogTitle>
        <DialogContent
          style={{
            textAlign: "center",
            background: 'black',
            border: '2px solid white',
            borderBottomWidth: '0px',
            borderTopWidth: '0px'
          }}
        >
          <NFTDataCard
            nftAddress={address}
            nftId={id}
            height={200}
          />
          <DialogContentText
            id="alert-dialog-description"
            style={{
              color: 'white',
              marginTop: '25px'
            }}
          >
            <p
              style={{
                fontSize: '18px'
              }}
            >
              The redemption of this Capsule NFT is an <i>irreversible and permanent</i> process.
            </p>
            <p
              style={{
                fontSize: '18px'
              }}
            >
              Upon <strong>confirmation of a transaction</strong>, all underlying tokens within this Capsule NFT will be retrieved and this Capsule NFT will be <i>burned</i>.
            </p>
            <p
              style={{
                marginTop: '50px'
              }}
            >
              To further understand the process of redemption, please read <a className={classes.capsuleLink} href="help" target="_blank">Redemption of Capsules</a>.
            </p>
            <p>
              By redeeming a Capsule NFT, you agree and consent to the <a className={classes.capsuleLink} href="terms" target="_blank">Terms & Conditions</a>.
            </p>
          </DialogContentText>
        </DialogContent>
        <DialogActions
          style={{
            background: 'black',
            alignItems: 'center',
            justifyContent: 'center',
            border: '2px solid white',
            borderTopWidth: '0px'
          }}
        >
          <Button
            onClick={handleClose}
            autoFocus
            color="google"
          >Don't Redeem</Button>
          <Button
            color="danger"
            onClick={() => handleBurn({address, id, type})}
          >
            Redeem Capsule NFT
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default BurnCapsule
