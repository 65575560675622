import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import Brush from "@material-ui/icons/Brush";
import CodeIcon from '@material-ui/icons/Code';
import Star from "@material-ui/icons/Star";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";

import styles from "assets/jss/material-kit-react/views/landingPageSections/productStyle.js";

const useStyles = makeStyles(styles);

export default function ProductSection() {
  const classes = useStyles();

  /*
  <h5 className={classes.description}>
    By creating a method to assign underlying value to NFTs, the Capsule protocol opens the door to limitless possibilities.
  </h5>
  */

  return (
     <div
      className={classes.section}
      style={{padding: '160px 0px 0px 0px'}}
    >
      <GridContainer justifyContent="center">
        <GridItem xs={12} sm={12} md={8}>
          <h1
            className={classes.title}
            style={{
              fontSize: "2.25rem",
              lineHeight: "1.5em"
            }}
          >About</h1>
          <h5 className={classes.description}>
            The Capsule Protocol allows users to create token-storing NFTs, known as Capsule NFTs.
            These Capsule NFTs have all the properties of ordinary NFTs; they can be viewed, traded, bought and sold as other NFTs are.
            However, there is a key difference — Capsule NFTs can be redeemed for their underlying tokens.
          </h5>
        </GridItem>
      </GridContainer>
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              title="For the Artists"
              description="The Capsule Protocol allows artists an unprecedented autonomy over their own work, and empowers them to explore an exciting new medium."
              icon={Brush}
              iconColor="primary"
              vertical
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              title="For the Developers"
              description="The Capsule Protocol allows for extensive abstraction, enabling customizable ecosystems to be constructed upon the base layer."
              icon={CodeIcon}
              iconColor="primary"
              vertical
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              title="For the Innovators"
              description="We look forward to discovering the new, innovative applications this protocol is capable of facilitating alongside the larger crypto community."
              icon={Star}
              iconColor="primary"
              vertical
            />
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
