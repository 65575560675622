import capsuleTokenPic from "assets/img/capsule/capsule-token-icon.png";

import {
  MainChainId,
  OurPublicCapsuleNFTAddress
} from 'utils'

export const capsuleTokenList = function (chainId) {
  return [
    /*
    {
      "address": OurPublicCapsuleNFTAddress(MainChainId),
      "name": "Capsule",
      "symbol": "CNFT",
      "logoURI": capsuleTokenPic
    }
    */
  ]
}
