import { useState } from 'react';

import {
  getEtherscanLinkWithHash
} from 'utils'

import Switch from '@material-ui/core/Switch';
import Collapse from '@material-ui/core/Collapse';
import FormControlLabel from '@material-ui/core/FormControlLabel';

// loading/done animations
import CircularProgress from '@material-ui/core/CircularProgress'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'

// card
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

import CopyButton from 'components/CopyButton';

import { makeStyles } from "@material-ui/core/styles";

import styles from "assets/jss/material-kit-react/views/componentsSections/tabsStyle.js";

const useStyles = makeStyles(styles)

const PendingTransactions = function (props) {
  const classes = useStyles()

  const { transactions, txText } = props

  const [viewTransactions, setViewTransactions] = useState(true)

  const showTransactions = () => {
    setViewTransactions((view) => !view)
  }

  return (
    <>
      <FormControlLabel
        control={
          <Switch
            checked={viewTransactions}
            onChange={showTransactions}
            classes={{
              switchBase: classes.switchBase,
              checked: classes.switchChecked,
              thumb: classes.switchIcon,
              track: classes.switchBar,
            }}
          />
        }
        classes={{
          label: classes.label,
        }}
        label="Show Recent Transactions"
      />
      <div className={classes.container}>
        <Collapse in={viewTransactions}>
          <div
            style={{
              marginTop: "25px"
            }}
          >
            {transactions.length < 1 ?
                <div
                  style={{
                    textAlign: 'center'
                  }}
                >
                  No transactions pending
                </div>
              :
                transactions.slice(0).reverse().map(function(obj, index){
                  return (
                    <Card
                      style={{
                        border: "2.5px solid #6f40ff",
                        background: 'black',
                        marginBottom: '10px',
                        color: 'white'
                      }}
                    >
                      <Typography gutterBottom variant="h6" component="h4"
                        style={{
                          textAlign: 'center',
                          background: 'rgb(111, 64, 255)'
                        }}
                      >
                        {`${txText(obj)}`}
                      </Typography>
                      <CardContent>
                        <Typography gutterBottom variant="h7" component="h4"
                          style={{textAlign: 'center'}}
                        >
                          {
                            !obj.done
                              ?
                                (
                                  <span>
                                    <CircularProgress
                                      size={24}
                                    />
                                    {'  Pending transaction: '}
                                    <a
                                      href={getEtherscanLinkWithHash(obj.tx)}
                                      target="_blank"
                                      className={classes.capsuleLink} rel="noreferrer"
                                    >
                                      View on Etherscan
                                    </a>
                                  </span>
                                )
                              :
                                (
                                  <span>
                                    <CheckCircleIcon/>
                                    {'  Finished transaction: '}
                                    <a
                                      href={getEtherscanLinkWithHash(obj.tx)}
                                      target="_blank"
                                      className={classes.capsuleLink} rel="noreferrer"
                                    >
                                      View on Etherscan
                                    </a>
                                  </span>
                                )
                          }
                        </Typography>
                        <Typography variant="body2" component="p"
                          style={{textAlign: 'center'}}
                        >
                          { `Transaction Hash: ${obj.tx}` }
                          <CopyButton
                            copyText={obj.tx}
                            width={"14px"}
                            height={"14px"}
                          />
                        </Typography>
                        <Typography variant="body2" component="p"
                          style={{textAlign: 'center'}}
                        >
                        </Typography>
                        <Typography variant="body2" component="p"
                          style={{textAlign: 'center'}}
                        >
                        </Typography>
                      </CardContent>
                    </Card>
                  )
                })
              }
            </div>
          </Collapse>
      </div>
    </>
  )
}

export default PendingTransactions
