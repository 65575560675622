import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import Eyes from "assets/img/eyes.png"

// partnership/security images
import quantstamp from "assets/img/security/Quantstamp_White.png"
// import bloq from "assets/img/partners/Bloq_Logo.png"

import styles from "assets/jss/material-kit-react/views/landingPageSections/workStyle.js";

const useStyles = makeStyles(styles);

export default function RoadmapSection() {
  const classes = useStyles();

  //<img src={roadmap} alt="..."/>
  return (
    <div className={classes.section}
      style={{
        padding: "0px"
      }}
    >
      <GridContainer justifyContent="center"
        style={{
          marginBottom: "100px"
        }}
      >
        <GridItem cs={12} sm={12} md={12}>
          <h2 className={classes.title}>Partners</h2>
          <p
            className={classes.title}
            style={{
              fontSize: "1.125rem"
            }}
          >
            To be announced... <img alt='someday...' style={{marginBottom: "6px", width: "22px", height: "22px"}} src={Eyes} />
          </p>
        </GridItem>
      </GridContainer>
      <GridContainer justifyContent="center"
        style={{
          marginBottom: "100px"
        }}
      >
        <GridItem cs={12} sm={12} md={12}>
          <h2 className={classes.title}>Security</h2>
        </GridItem>
        <GridItem
          style={{textAlign: 'center'}}
          cs={12} sm={12} md={12}>
          <a
            href='https://certificate.quantstamp.com/full/capsule-nft'
            target='_blank' rel="noreferrer"
          >
            <img
              src={quantstamp}
              alt="Quantstamp Logo"
              height={'300px'}
            />
          </a>
        </GridItem>
      </GridContainer>
      <GridContainer justifyContent="center"
        style={{
          marginBottom: "100px"
        }}
      >
        <GridItem cs={12} sm={12} md={12}>
          <h2 className={classes.title}>Join the Capsule Community</h2>
          <p
            className={classes.title}
            style={{
              fontSize: "1.125rem"
            }}
          >
            Best place to find us is on our <a style={{color: "#6f40ff"}} target="_blank" href="https://discord.gg/CapsuleNFT" rel="noreferrer">Discord</a>
          </p>
          <p
            className={classes.title}
            style={{
              fontSize: "1.125rem"
            }}
          >
            Business inquiries should go to <a style={{color: "#6f40ff"}} target="_blank" href="mailto:business@capsulenft.com" rel="noreferrer">business@capsulenft.com</a>
          </p>
        </GridItem>
      </GridContainer>
    </div>
  );
}
