import React, { useEffect, useState } from "react"

// @material-ui/icons
import StarsIcon from '@material-ui/icons/Stars';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import GroupWorkIcon from '@material-ui/icons/GroupWork';
import OfflineBoltIcon from '@material-ui/icons/OfflineBolt';

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import NavPills from "components/NavPills/NavPills.js";

// collection getting imports
import {
  MainChainId,
} from 'utils'
import { useActiveWeb3React } from 'hooks/useActiveWeb3React.js'
import { useCapsuleNFTFactoryContract } from 'hooks/useContract.js'

// get select menu item
import FormattedNFTMenuItem from 'components/app/FunctionComponents/FormattedNFTMenuItem.js'

// each individual tab:
import ERC20CapsuleTab from 'components/app/AppMainWidget/MintComponents/MintERC20Capsule.js'
import ERC721CapsuleTab from 'components/app/AppMainWidget/MintComponents/MintERC721Capsule.js'
import ERC1155CapsuleTab from 'components/app/AppMainWidget/MintComponents/MintERC1155Capsule.js'
import BaseNFTTab from 'components/app/AppMainWidget/MintComponents/MintBaseNFT.js'

// redux user txs
import { useSelector } from 'react-redux'

export default function MintTab() {
  const { chainId } = useActiveWeb3React()

  // connect straight to node, not to injected
  const useNode = {
    active: true,
    chainId: MainChainId
  }

  const [selectedCapsuleCollection, setSelectedCapsuleCollection] = useState('none')
  const [capsuleCollectionArray, setCapsuleCollectionArray] = useState([])

  // current test for loading transactions
  const transactionSelector = useSelector(state => state.transactions)

  const [txEx, setTxEx] = useState(transactionSelector?.[chainId]); // set when tx executed
  useEffect(() => {
    setTxEx(transactionSelector?.[chainId])
  }, [transactionSelector])

  const handleChange = (event) => {
    setSelectedCapsuleCollection(event.target.value)
  }

  const capsuleNFTFactoryContract = useCapsuleNFTFactoryContract(false, useNode)

  useEffect(() => {
    const getAllCapsuleNFTAddresses = async () => {
      return capsuleNFTFactoryContract?.getAllCapsuleCollections()
    }

    const getFactoryCapsuleNFTs = async () => {
      try {
        const capsuleArray = await getAllCapsuleNFTAddresses()
        return capsuleArray
      } catch (e) {
        console.log('error getting cap collection addresses', e)
        return e
      }
    }

    const createCollectionSelect = async () => {
      const collectionArray = await getFactoryCapsuleNFTs()
      setCapsuleCollectionArray(collectionArray || [])
      // may want to auto-select to just the capsuleNFT public token, if unreachable
    }

    createCollectionSelect()
  }, [
    chainId,
    txEx ? JSON.stringify(Object.keys(txEx).map(function(obj, i) {
      const tx = txEx[obj]
      if (tx['summary'] === 'Capsule Collection Successfully Deployed') {
        if (tx.hasOwnProperty('receipt')) {
          return 'done'
        }
      }
      return null
    }).filter(element => {
      return element !== null;
    })) : ''
  ])

  return (
    <GridContainer>
      <GridItem xs={12} sm={12}>
        <FormattedNFTMenuItem
          collectionArray={capsuleCollectionArray}
          selectedCollection={selectedCapsuleCollection}
          handleChange={handleChange}
          mintTab={true}
        />
        <NavPills
          color="danger"
          alignCenter={true}
          tabs={[
            {
              tabButton: "Simple Capsule NFT",
              tabIcon: StarsIcon,
              tabContent: (
                <BaseNFTTab
                  collectionAddress={selectedCapsuleCollection}
                />
              ),
            },
            {
              tabButton: "ERC-20 Capsule NFT",
              tabIcon: RadioButtonCheckedIcon,
              tabContent: (
                <ERC20CapsuleTab
                  collectionAddress={selectedCapsuleCollection}
                />
              ),
            },
            {
              tabButton: "ERC-721 Capsule NFT",
              tabIcon: OfflineBoltIcon,
              tabContent: (
                <ERC721CapsuleTab
                  collectionAddress={selectedCapsuleCollection}
                />
              ),
            },
            {
              tabButton: "ERC-1155 Capsule NFT",
              tabIcon: GroupWorkIcon,
              tabContent: (
                <ERC1155CapsuleTab
                  collectionAddress={selectedCapsuleCollection}
                />
              ),
            },
          ]}
        />
      </GridItem>
    </GridContainer>
  );
}
