import React, { useState, useEffect } from "react"
// @material-ui/core components
import { useNFTMinterContract } from 'hooks/useContract.js'

// @material-ui/icons
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";

import { AddressZero } from "@ethersproject/constants";

import {
  MainChainId
} from 'utils'

// nft data card
import NFTDataCard from "components/NFTDataCard"

export default function Capsule(props) {

  const { nftAddress, nftId } = props

  /*
  {
    childAddress: null,
    childId: null
  }
  */

  const [singleERC721CapsuleChildren, setSingleERC721CapsuleChildren] = useState([])
  const [multiERC721CapsuleChildren, setMultiERC721CapsuleChildren] = useState([])

  const useNode = {
    active: true,
    chainId: MainChainId
  }

  const nftMinterContract = useNFTMinterContract(false, useNode)

  const getERC721CapsuleUnderlyingStruct = async (nftAddress, index) => {
    // singleERC721Capsule
    return nftMinterContract?.singleERC721Capsule(nftAddress, index)
  }

  const multiERC721Capsule = async (nftAddress, index) => {
    // multiERC721Capsule
    return nftMinterContract?.multiERC721Capsule(nftAddress, index)
  }

  const sortMultiERC721CapsuleInfo = async (nftAddress, index) => {
    const data = await multiERC721Capsule(nftAddress, nftId)
    if (!data) return null

    const filtered = []

    for (let i = 0; i < data[0].length; i++) {
      filtered.push({
        address: data[0][i],
        id: data[1][i]
      })
    }

    return filtered
  }

  const createUserNFTHoldings = async () => {

    const getChildCapsuleHoldings = async (address, id) => {
      return await getERC721CapsuleUnderlyingStruct(address, id)
        .then(function (result) {
          const stashedErc721Address = result.tokenAddress
          const stashedErc721Id = result.id
          if (stashedErc721Address !== AddressZero) {
            return {
              childAddress: stashedErc721Address,
              childId: stashedErc721Id
            } // return info to show it isn't done looping through child Capsules
          } else {
            return null // return true to show there are no more nested Capsules
          }
        })
        .then(function(result) {
          return result
        })
        .catch(function (error) {
          console.error(`Could not get underlying value of child Capsule`, error)
          return null
        })
    }

    let doneLooping = false
    let currentChild = {
      childAddress: nftAddress,
      childId: nftId
    }
    let allChildren = []

    // loop through all Capsules (continuously) until a child capsule doesn't have an ERC721 Capsule under it
    // (only for Single ERC-721 Capsule chains)
    while (!doneLooping) {
      currentChild = await getChildCapsuleHoldings(currentChild.childAddress, currentChild.childId)
      if (currentChild) {
        allChildren.push(currentChild)
      }
      doneLooping = currentChild === null
    }

    setSingleERC721CapsuleChildren(allChildren)

    if (allChildren.length < 1) {
      setMultiERC721CapsuleChildren(await sortMultiERC721CapsuleInfo(nftAddress, nftId))
    }
  }

  useEffect(async () => {
    createUserNFTHoldings()
  }, [])

  // height/width of the nft image
  const height = 600

  return (
    <GridContainer justifyContent="center">
      <GridItem xs={12} sm={12} md={12}>
        <GridContainer justifyContent="center">
          <GridItem xs={5} sm={5} md={3}>
            {
              (nftId - 1 >= 0) &&
              <Button
                color="danger"
                size="lg"
                href={`/capsule/${nftAddress}/${nftId - 1}`}
                rel="noopener noreferrer"
              >
                <KeyboardArrowLeftIcon/>{`ID ${nftId - 1}`}
              </Button>
          }
          </GridItem>
          <GridItem xs={2} sm={2} md={6}>
          </GridItem>
          <GridItem xs={5} sm={5} md={3}>
            <Button
              color="danger"
              size="lg"
              href={`/capsule/${nftAddress}/${parseInt(nftId) + 1}`}
              rel="noopener noreferrer"
              style={{float: "right"}}
            >
              {`ID ${parseInt(nftId) + 1}`}<KeyboardArrowRightIcon/>
            </Button>
          </GridItem>
        </GridContainer>
        <NFTDataCard
          nftAddress={nftAddress}
          nftId={parseInt(nftId)}
          height={height}
          type={singleERC721CapsuleChildren.length > 0
            ?
              "ERC-721 Capsule"
            :
              multiERC721CapsuleChildren.length > 0
              ?
                "Multi ERC-721 Capsule"
              :
                null
          }
        />
        {singleERC721CapsuleChildren.map(function(obj, index){
          return (obj.childAddress && obj.childId) && (
              <GridItem>
                <p style={{
                  textAlign: "center",
                  fontSize: "50px",
                  margin: "30px"
                }}>&#8595;</p>
                <NFTDataCard
                  nftAddress={obj.childAddress}
                  nftId={obj.childId}
                  height={height}
                />
              </GridItem>
          )
        })}
        {
          multiERC721CapsuleChildren.length > 1 &&
            <GridContainer>
              <GridItem>
                <p style={{
                  textAlign: "center",
                  fontSize: "50px",
                  margin: "30px"
                }}>&#8595;</p>
              </GridItem>
              {
                multiERC721CapsuleChildren.map(function(val, index){
                  return (
                    multiERC721CapsuleChildren.length === 0 ? null :
                    multiERC721CapsuleChildren.length === 1 ?
                      <GridItem xs={12} sm={12} md={12}>
                        <NFTDataCard
                          nftAddress={val?.address}
                          nftId={val?.id}
                          height={300}
                        />
                      </GridItem>
                    :
                      multiERC721CapsuleChildren.length === 2 ?
                        <GridItem xs={12} sm={12} md={6}>
                          <NFTDataCard
                            nftAddress={val?.address}
                            nftId={val?.id}
                            height={300}
                          />
                        </GridItem>
                      :
                        <GridItem xs={12} sm={6} md={4}>
                          <NFTDataCard
                            nftAddress={val?.address}
                            nftId={val?.id}
                            height={300}
                          />
                        </GridItem>
                    )
                })
              }
              <GridItem style={{
                textAlign: "center",
                marginTop: "15px"
              }} xs={12} sm={12} md={12}>
                <p>Note: CapsuleScan Multi ERC-721 Capsules only parse one layer downward</p>
              </GridItem>
            </GridContainer>
        }
      </GridItem>
    </GridContainer>
  );
}
