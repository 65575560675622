import { useEffect, useState, useCallback, useMemo } from 'react'
import { useNFTMinterContract } from 'hooks/useContract.js'
import { useActiveWeb3React } from 'hooks/useActiveWeb3React.js'
import { useTransactionAdder } from 'state/transactions/hooks'
import { AddressZero } from "@ethersproject/constants";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import {
  getGeneralCapsuleNFTContractDirect
} from 'utils';

import Pagination from '@material-ui/lab/Pagination'

// icons
import StarsIcon from '@material-ui/icons/Stars';
import GroupWorkIcon from '@material-ui/icons/GroupWork';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import OfflineBoltIcon from '@material-ui/icons/OfflineBolt';

// nft data card
import NavPills from "components/NavPills/NavPills.js";
import NFTDataCard from "components/NFTDataCard"

// burn user Capsule NFT at id
import BurnCapsule from 'components/app/FunctionComponents/BurnCapsule.js'
import CircularProgress from '@material-ui/core/CircularProgress'

// redux user txs
import { useSelector } from 'react-redux'

// styles
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-kit-react/views/componentsSections/tabsStyle.js";
import SendCapsule from "components/app/FunctionComponents/SendCapsule";
const useStyles = makeStyles(styles);

const GetUserOwnedNFTs = function (props) {
  const classes = useStyles()

  const { collectionAddress } = props

  const addTransaction = useTransactionAdder()
  const { active, account, chainId, library } = useActiveWeb3React()
  const nftMinterContract = useNFTMinterContract(true, { active: false })

  // current test for loading transactions
  const transactionSelector = useSelector(state => state.transactions)

  const [txEx, setTxEx] = useState(transactionSelector?.[chainId]); // set when tx executed
  useEffect(() => {
    setTxEx(transactionSelector?.[chainId])
  }, [transactionSelector])

  let userHoldingsObject = {
    baseNFTIds: [],
    erc20NFTHoldings: [],
    erc721NFTHoldings: [],
    erc1155NFTHoldings: []
  }

  const [userNFTTotalHoldings, setUserNFTTotalHoldings] = useState(userHoldingsObject)
  const [loading, setLoading] = useState(false)

  // pagination elements
  const [simpleCapsuleCurrentPage, setSimpleCapsuleCurrentPage] = useState(1)
  const [erc20CapsuleCurrentPage, setERC20CapsuleCurrentPage] = useState(1)
  const [erc721CapsuleCurrentPage, setERC721CapsuleCurrentPage] = useState(1)
  const [erc1155CapsuleCurrentPage, setERC1155CapsuleCurrentPage] = useState(1)

  // amount to show per page
  let PageSize = 4
  const simpleCapsuleShownNFTs = useMemo(() => {
    const firstPageIndex = (simpleCapsuleCurrentPage - 1) * PageSize
    const lastPageIndex = firstPageIndex + PageSize
    return userNFTTotalHoldings.baseNFTIds.slice(firstPageIndex, lastPageIndex)
  }, [simpleCapsuleCurrentPage, userNFTTotalHoldings])

  const simpleCapsulePaginationPageChange = (event, value) => {
    setSimpleCapsuleCurrentPage(value)
  }

  const erc20CapsuleShownNFTs = useMemo(() => {
    const firstPageIndex = (erc20CapsuleCurrentPage - 1) * PageSize
    const lastPageIndex = firstPageIndex + PageSize
    return userNFTTotalHoldings.erc20NFTHoldings.slice(firstPageIndex, lastPageIndex)
  }, [erc20CapsuleCurrentPage, userNFTTotalHoldings])

  const erc20PaginationPageChange = (event, value) => {
    setERC20CapsuleCurrentPage(value)
  }

  const erc721CapsuleShownNFTs = useMemo(() => {
    const firstPageIndex = (erc721CapsuleCurrentPage - 1) * PageSize
    const lastPageIndex = firstPageIndex + PageSize
    return userNFTTotalHoldings.erc721NFTHoldings.slice(firstPageIndex, lastPageIndex)
  }, [erc721CapsuleCurrentPage, userNFTTotalHoldings])

  const erc721PaginationPageChange = (event, value) => {
    setERC721CapsuleCurrentPage(value)
  }

  const erc1155CapsuleShownNFTs = useMemo(() => {
    const firstPageIndex = (erc1155CapsuleCurrentPage - 1) * PageSize
    const lastPageIndex = firstPageIndex + PageSize
    return userNFTTotalHoldings.erc1155NFTHoldings.slice(firstPageIndex, lastPageIndex)
  }, [erc1155CapsuleCurrentPage, userNFTTotalHoldings])

  const erc1155PaginationPageChange = (event, value) => {
    setERC1155CapsuleCurrentPage(value)
  }

  // nftMinter direct checks
  const getERC20CapsuleUnderlyingStruct = async (nftAddress, index) => {
    // singleERC20Capsule
    return nftMinterContract?.singleERC20Capsule(nftAddress, index)
  }

  const multiERC20Capsule = async (nftAddress, index) => {
    // multiERC20Capsule
    return nftMinterContract?.multiERC20Capsule(nftAddress, index)
  }

  const getERC721CapsuleUnderlyingStruct = async (nftAddress, index) => {
    // singleERC721Capsule
    return nftMinterContract?.singleERC721Capsule(nftAddress, index)
  }

  const multiERC721Capsule = async (nftAddress, index) => {
    // multiERC721Capsule
    return nftMinterContract?.multiERC721Capsule(nftAddress, index)
  }

  const multiERC1155Capsule = async (nftAddress, index) => {
    // multiERC1155Capsule (no single)
    return nftMinterContract?.multiERC1155Capsule(nftAddress, index)
  }

  // nftBase functions
  const getOwnerTotalNFTBalance = async (contractObject, address) => {
    return contractObject?.balanceOf(address)
  }

  const getOwnerNFTId = async (contractObject, address, index) => {
    return contractObject?.tokenOfOwnerByIndex(address, index)
  }

  const getAllUserOwnedNFTs = async (nftContractObject) => {
    try {
      const ownerTokenIds = []
      const totalNFTsOwned = await getOwnerTotalNFTBalance(nftContractObject, account)

      for (var i = 0; i < totalNFTsOwned; i++) {
        const id = await getOwnerNFTId(nftContractObject, account, i)
        ownerTokenIds.push(id)
      }

      return ownerTokenIds
    } catch (e) {
      console.error(e)
      return []
    }
  }

  // ERC20 burn Functions (required to pass to child to update parent 'burning Capsule' state)
  const [burningERC20Capsules, setBurningERC20Capsules] = useState([])

  const burnERC20Capsule = async (address, id, type) => {
    return type === 'multi' ?
        nftMinterContract?.burnMultiERC20Capsule(address, id, account, account)
      :
        nftMinterContract?.burnSingleERC20Capsule(address, id, account, account)
  }

  const handleERC20CapsuleBurn = useCallback(async ({address, id, type}) => {
    try {
        const tx = await burnERC20Capsule(address, id, type)
        addTransaction(tx, {summary: `Capsule Successfully Burned`})
        const currentlyBurning = burningERC20Capsules
        currentlyBurning.push({
          address,
          id
        })
        setBurningERC20Capsules([...currentlyBurning])
        return tx
    } catch (e) {
        return e
    }
  }, [account, nftMinterContract, burningERC20Capsules])

  // ERC721 burn Functions (required to pass to child to update parent 'burning Capsule' state)
  const [burningERC721Capsules, setBurningERC721Capsules] = useState([])

  const burnERC721Capsule = async (address, id, type) => {
    return type === 'multi' ?
        nftMinterContract?.burnMultiERC721Capsule(address, id, account, account)
      :
        nftMinterContract?.burnSingleERC721Capsule(address, id, account, account)
  }

  const handleERC721CapsuleBurn = useCallback(async ({address, id, type}) => {
    try {
        const tx = await burnERC721Capsule(address, id, type)
        addTransaction(tx, {summary: `Capsule Successfully Burned`})
        const currentlyBurning = burningERC721Capsules
        currentlyBurning.push({
          address,
          id
        })
        setBurningERC721Capsules([...currentlyBurning])
        return tx
    } catch (e) {
        return e
    }
  }, [account, nftMinterContract, burningERC721Capsules])

    // ERC1155 burn Functions (required to pass to child to update parent 'burning Capsule' state)
    const [burningERC1155Capsules, setBurningERC1155Capsules] = useState([])

    const burnERC1155Capsule = async (address, id, type) => {
      // type unnecessary as there are only multi 1155 Capsule
      return nftMinterContract?.burnMultiERC1155Capsule(address, id, account, account)
    }

    const handleERC1155CapsuleBurn = useCallback(async ({address, id, type}) => {
      try {
          const tx = await burnERC1155Capsule(address, id, type)
          addTransaction(tx, {summary: `Capsule Successfully Burned`})
          const currentlyBurning = burningERC1155Capsules
          currentlyBurning.push({
            address,
            id
          })
          setBurningERC1155Capsules([...currentlyBurning])
          return tx
      } catch (e) {
          return e
      }
    }, [account, nftMinterContract, burningERC1155Capsules])

  useEffect(() => {
    const getUserNFTs = async (nftContractAddress) => {
      const nftContractObject = getGeneralCapsuleNFTContractDirect(
        nftContractAddress,
        chainId,
        library
      )

      const result = await getAllUserOwnedNFTs(nftContractObject)
      return result
    }

    const createUserNFTHoldings = async (nftAddress) => {
      const userOwnedNFTArray = await getUserNFTs(nftAddress)

      // iterate through all NFTs a user owns at a certain Capsule Collection address
      for (let j = 0; j < userOwnedNFTArray.length; j++) {
        const id = userOwnedNFTArray[j].toString()
        // check if the Capsule has an address at its ERC20 slot
        await Promise.allSettled([
          new Promise(async (resolve, reject) => {
            await getERC20CapsuleUnderlyingStruct(nftAddress, id)
              .then(async function (result) {
                const stashedErc20Address = result?.tokenAddress
                const stashedErc20Amount = result?.tokenAmount.toString()
                if (stashedErc20Address !== AddressZero) {
                  userHoldingsObject.erc20NFTHoldings.push({
                    id,
                    address: nftAddress,
                    token: stashedErc20Address,
                    stashedAmount: stashedErc20Amount
                  })
                  resolve(true)
                }
                reject(false)
              })
          }),
          new Promise(async (resolve, reject) => {
            await getERC721CapsuleUnderlyingStruct(nftAddress, id)
              .then(async function (result) {
                const stashedErc721Address = result?.tokenAddress
                const stashedErc721Id = result?.id
                if (stashedErc721Address !== AddressZero) {
                  userHoldingsObject.erc721NFTHoldings.push({
                    id,
                    address: nftAddress,
                    token: stashedErc721Address,
                    stashedId: stashedErc721Id
                  })
                  resolve(true)
                }
                reject(false)
              })
          }),
          new Promise(async (resolve, reject) => {
            await multiERC20Capsule(nftAddress, id)
              .then(async function (result) {
                const stashedErc20Addresses = result?.tokenAddresses
                const stashedErc20Amounts = result?.tokenAmounts
                if (stashedErc20Addresses && stashedErc20Addresses?.length > 0) {
                  userHoldingsObject.erc20NFTHoldings.push({
                    id,
                    address: nftAddress,
                    tokens: stashedErc20Addresses,
                    stashedAmounts: stashedErc20Amounts
                  })
                  resolve(true)
                }
                reject(false)
              })
          }),
          new Promise(async (resolve, reject) => {
            await multiERC721Capsule(nftAddress, id)
              .then(async function (result) {
                const stashedErc721Addresses = result?.tokenAddresses
                const stashedErc721Ids = result?.ids
                if (stashedErc721Addresses && stashedErc721Addresses?.length > 0) {
                  userHoldingsObject.erc721NFTHoldings.push({
                    id,
                    address: nftAddress,
                    tokens: stashedErc721Addresses,
                    stashedIds: stashedErc721Ids
                  })
                  resolve(true)
                }
                reject(false)
              })
          }),
          new Promise(async (resolve, reject) => {
            await multiERC1155Capsule(nftAddress, id)
              .then(async function (result) {
                const stashedErc1155Addresses = result?.tokenAddresses
                const stashedErc1155Amounts = result?.tokenAmounts
                const stashedErc1155Ids = result?.ids
                if (stashedErc1155Addresses && stashedErc1155Addresses?.length > 0) {
                  userHoldingsObject.erc1155NFTHoldings.push({
                    id,
                    address: nftAddress,
                    tokens: stashedErc1155Addresses,
                    stashedAmounts: stashedErc1155Amounts,
                    stashedIds: stashedErc1155Ids
                  })
                  resolve(true)
                }
                reject(false)
              })
          })
      	]).then(function (result) {
          for (let r = 0; r < result.length; r++) {
            if (result[r].status === 'fulfilled') {
              return
            }
          }
          // if we've gone through all capsule info and all failed to return data
          // it must be a simple capsule
          userHoldingsObject.baseNFTIds.push({
            id
          })
        })
      }

      setLoading(false)
      setUserNFTTotalHoldings({...userHoldingsObject})
    }

    // reset holdings on any change
    setUserNFTTotalHoldings({
      baseNFTIds: [],
      erc20NFTHoldings: [],
      erc721NFTHoldings: [],
      erc1155NFTHoldings: []
    })

    // do not create user holdings if an improper nft address is passed
    if (collectionAddress) {
      createUserNFTHoldings(collectionAddress)
    }

  }, [
      account,
      collectionAddress,
      txEx ? JSON.stringify(Object.keys(txEx).map(function(obj, i) {
        const tx = txEx[obj]
        if (tx['summary'] === 'Capsule Successfully Burned') {
          if (tx.hasOwnProperty('receipt')) {
            return 'done'
          }
        } else if (tx['summary'] === 'Capsule Successfully Minted') {
          if (tx.hasOwnProperty('receipt')) {
            return 'done'
          }
        }
        return null
      }).filter(element => {
        return element !== null;
      })) : ''
    ]
  )

  useEffect(() => {
    setLoading(true)
  }, [collectionAddress])


  const height = 300

  // ids need to be updated on every single successful TX (or the buttons need to disappear or this needs to rerender?)
  return (
    <>
      {
        loading && (
          <h2 className={classes.textCenter}>Loading...</h2>
        )
      }
      <NavPills
        color="danger"
        alignCenter={true}
        tabs={[
          {
            tabButton: "All Capsule NFTs",
            tabIcon: GroupWorkIcon,
            tabContent: (
              <>
                <h3
                  className={classes.textCenter}
                  style={{marginTop: "75px"}}
                ><strong>Capsule NFTs Owned in this Collection</strong></h3>
                <h5 className={classes.textCenter}>
                  {
                    !active ?
                      'Sign in to view your Capsule NFTs here'
                    :
                      (userNFTTotalHoldings.baseNFTIds.length < 1 &&
                      userNFTTotalHoldings.erc20NFTHoldings.length < 1 &&
                      userNFTTotalHoldings.erc721NFTHoldings.length < 1 &&
                      userNFTTotalHoldings.erc1155NFTHoldings.length < 1) ?
                          'You don\'t have any Capsule NFTs from this collection'
                        :
                          (active && account) ?
                            null
                          :
                            'Connect to your account'
                  }
                </h5>
                { (userNFTTotalHoldings.baseNFTIds.length > 0) &&
                  <>
                    <h4
                      className={classes.textCenter}
                    ><strong>Simple Capsule NFTs</strong></h4>
                    <GridContainer>
                      {simpleCapsuleShownNFTs.map(function(obj, index){

                        return (
                          <GridItem
                            xs={12}
                            md={6}
                            style={{ marginBottom: "10px" }}
                          >
                              <NFTDataCard
                                nftAddress={collectionAddress}
                                nftId={obj.id}
                                height={height}
                                key={"all-simple" + collectionAddress + obj.id}
                              />
                              <GridContainer justifyContent="center">
                                <SendCapsule
                                  account={account}
                                  address={collectionAddress}
                                  id={obj.id}
                                />
                              </GridContainer>
                            </GridItem>
                        )
                      })}
                      <GridItem xs={12}
                        style={{
                          marginTop: "10px",
                          marginBottom: "25px"
                        }}
                      >
                        <Pagination
                          className={classes.pagination}
                          page={simpleCapsuleCurrentPage}
                          size="large"
                          count={Math.ceil(userNFTTotalHoldings.baseNFTIds.length / PageSize)}
                          onChange={simpleCapsulePaginationPageChange}
                        />
                      </GridItem>
                    </GridContainer>
                  </>
                }
                { (userNFTTotalHoldings.erc20NFTHoldings.length > 0) &&
                  <>
                    <h4
                      className={classes.textCenter}
                      style={{marginTop: "75px"}}
                    ><strong>ERC-20 Capsule NFTs</strong></h4>
                    <GridContainer>
                      {erc20CapsuleShownNFTs.map(function(obj, index){
                        return (
                            <GridItem
                              xs={12}
                              md={6}
                              style={{marginBottom: "10px"}}
                            >
                              <NFTDataCard
                                nftAddress={collectionAddress}
                                nftId={obj.id}
                                height={height}
                                key={"all-20-" + collectionAddress + obj.id}
                              />
                              <GridContainer justifyContent="center">
                                {(() => {
                                  const inProgress = burningERC20Capsules.some(function(burningObj, burningIndex) {
                                    if (obj.address === burningObj.address &&
                                      obj.id === burningObj.id) {
                                        return true
                                    }
                                  })

                                  return inProgress ?
                                      (<span
                                        style={{
                                          textAlign: "center",
                                          fontSize: "18px"
                                        }}
                                      >
                                        <CircularProgress
                                          size={24}
                                          style={{
                                            marginRight: "10px"
                                          }}
                                        />
                                        <strong>Burning Capsule...</strong>
                                      </span>)
                                    :
                                      (<BurnCapsule
                                        address={obj.address}
                                        id={obj.id}
                                        handleBurn={handleERC20CapsuleBurn}
                                        type={ obj?.tokens ? 'multi' : 'single' }
                                      />)
                                })()}
                                <SendCapsule
                                  account={account}
                                  address={collectionAddress}
                                  id={obj.id}
                                />
                              </GridContainer>
                            </GridItem>
                        )
                      })}
                      <GridItem xs={12}
                        style={{
                          marginTop: "10px",
                          marginBottom: "25px"
                        }}
                      >
                        <Pagination
                          className={classes.pagination}
                          page={erc20CapsuleCurrentPage}
                          size="large"
                          count={Math.ceil(userNFTTotalHoldings.erc20NFTHoldings.length / PageSize)}
                          onChange={erc20PaginationPageChange}
                        />
                      </GridItem>
                    </GridContainer>
                  </>
                }
                { (userNFTTotalHoldings.erc721NFTHoldings.length > 0) &&
                  <>
                    <h4
                      className={classes.textCenter}
                      style={{marginTop: "75px"}}
                    ><strong>ERC-721 Capsule NFTs</strong></h4>
                    <GridContainer>
                      {erc721CapsuleShownNFTs.map(function(obj, index){
                        return (
                            <GridItem
                              xs={12}
                              md={6}
                              style={{marginBottom: "10px"}}
                            >
                              <NFTDataCard
                                nftAddress={collectionAddress}
                                nftId={obj.id}
                                height={height}
                                key={"all-721-" + collectionAddress + obj.id}
                              />
                              <GridContainer justifyContent="center">
                                {(() => {
                                  const inProgress = burningERC721Capsules.some(function(burningObj, burningIndex) {
                                    if (obj.address === burningObj.address &&
                                      obj.id === burningObj.id) {
                                        return true
                                    }
                                  })

                                  return inProgress ?
                                      (<span
                                        style={{
                                          textAlign: "center",
                                          fontSize: "18px"
                                        }}
                                      >
                                        <CircularProgress
                                          size={24}
                                          style={{
                                            marginRight: "10px"
                                          }}
                                        />
                                        <strong>Burning Capsule...</strong>
                                      </span>)
                                    :
                                      (<BurnCapsule
                                        address={obj.address}
                                        id={obj.id}
                                        handleBurn={handleERC721CapsuleBurn}
                                        type={ obj?.tokens ? 'multi' : 'single' }
                                      />)
                                })()}
                                <SendCapsule
                                  account={account}
                                  address={collectionAddress}
                                  id={obj.id}
                                />
                              </GridContainer>
                            </GridItem>
                        )
                      })}
                      <GridItem xs={12}
                        style={{
                          marginTop: "10px",
                          marginBottom: "25px"
                        }}
                      >
                        <Pagination
                          className={classes.pagination}
                          page={erc721CapsuleCurrentPage}
                          size="large"
                          count={Math.ceil(userNFTTotalHoldings.erc721NFTHoldings.length / PageSize)}
                          onChange={erc721PaginationPageChange}
                        />
                      </GridItem>
                    </GridContainer>
                  </>
                }
                { (userNFTTotalHoldings.erc1155NFTHoldings.length > 0) &&
                  <>
                    <h4
                      className={classes.textCenter}
                      style={{marginTop: "75px"}}
                    ><strong>ERC-1155 Capsule NFTs</strong></h4>
                    <GridContainer>
                      {erc1155CapsuleShownNFTs.map(function(obj, index){
                        return (
                            <GridItem
                              xs={12}
                              md={6}
                              style={{marginBottom: "10px"}}
                            >
                              <NFTDataCard
                                nftAddress={collectionAddress}
                                nftId={obj.id}
                                height={height}
                                key={"all-1155-" + collectionAddress + obj.id}
                              />
                              <GridContainer justifyContent="center">
                                {(() => {
                                  const inProgress = burningERC1155Capsules.some(function(burningObj, burningIndex) {
                                    if (obj.address === burningObj.address &&
                                      obj.id === burningObj.id) {
                                        return true
                                    }
                                  })

                                  return inProgress ?
                                      (<span
                                        style={{
                                          textAlign: "center",
                                          fontSize: "18px"
                                        }}
                                      >
                                        <CircularProgress
                                          size={24}
                                          style={{
                                            marginRight: "10px"
                                          }}
                                        />
                                        <strong>Burning Capsule...</strong>
                                      </span>)
                                    :
                                      (<BurnCapsule
                                        address={obj.address}
                                        id={obj.id}
                                        handleBurn={handleERC1155CapsuleBurn}
                                        type={ 'multi' }
                                      />)
                                })()}
                                <SendCapsule
                                  account={account}
                                  address={collectionAddress}
                                  id={obj.id}
                                />
                              </GridContainer>
                            </GridItem>
                        )
                      })}
                      <GridItem xs={12}
                        style={{
                          marginTop: "10px",
                          marginBottom: "25px"
                        }}
                      >
                        <Pagination
                          className={classes.pagination}
                          page={erc1155CapsuleCurrentPage}
                          size="large"
                          count={Math.ceil(userNFTTotalHoldings.erc1155NFTHoldings.length / PageSize)}
                          onChange={erc1155PaginationPageChange}
                        />
                      </GridItem>
                    </GridContainer>
                  </>
                }
              </>
            )
          },
          {
            tabButton: "Simple Capsule NFTs",
            tabIcon: StarsIcon,
            tabContent: (
              <>
                <h3
                  className={classes.textCenter}
                  style={{marginTop: "55px"}}
                ><strong>Simple Capsule NFTs</strong></h3>
                <h5 className={classes.textCenter}>
                  {
                    !active ?
                      'Sign in to view your Capsule NFTs here'
                    :
                      (userNFTTotalHoldings.baseNFTIds.length < 1) ?
                          'You don\'t have any Simple Capsule NFTs from this collection'
                        :
                          (active && account) ?
                            null
                          :
                            'Connect to your account'
                  }
                </h5>
                <GridContainer>
                  {simpleCapsuleShownNFTs.map(function(obj, index){
                    return (
                        <GridItem
                          xs={12}
                          md={6}
                          style={{marginBottom: "10px"}}
                        >
                          <NFTDataCard
                            nftAddress={collectionAddress}
                            nftId={obj.id}
                            height={height}
                            key={collectionAddress + obj.id}
                          />
                          <GridContainer justifyContent="center">
                            <SendCapsule
                              account={account}
                              address={collectionAddress}
                              id={obj.id}
                            />
                          </GridContainer>
                        </GridItem>
                    )
                  })}
                  <GridItem xs={12}
                    style={{
                      marginTop: "10px",
                      marginBottom: "25px"
                    }}
                  >
                    <Pagination
                      className={classes.pagination}
                      page={simpleCapsuleCurrentPage}
                      size="large"
                      count={Math.ceil(userNFTTotalHoldings.baseNFTIds.length / PageSize)}
                      onChange={simpleCapsulePaginationPageChange}
                    />
                  </GridItem>
                </GridContainer>
              </>
            )
          },
          {
            tabButton: "ERC-20 Capsule NFTs",
            tabIcon: RadioButtonCheckedIcon,
            tabContent: (
              <div style={{
                marginTop: "-20px"
              }}>
                <h3
                  className={classes.textCenter}
                  style={{marginTop: "75px"}}
                ><strong>ERC-20 Capsule NFTs</strong></h3>
                <h5 className={classes.textCenter}>
                  {
                    !active ?
                      'Sign in to view your Capsule NFTs here'
                    :
                      (userNFTTotalHoldings.erc20NFTHoldings.length < 1) ?
                          'You don\'t have any ERC20 Capsule NFTs from this collection'
                        :
                          (active && account) ?
                            null
                          :
                            'Connect to your account'
                  }
                </h5>
                <GridContainer>
                  {erc20CapsuleShownNFTs.map(function(obj, index){
                    return (
                        <GridItem
                          xs={12}
                          md={6}
                          style={{marginBottom: "10px"}}
                        >
                          <NFTDataCard
                            nftAddress={collectionAddress}
                            nftId={obj.id}
                            height={height}
                            key={collectionAddress + obj.id}
                          />
                          <GridContainer justifyContent="center">
                            {(() => {
                              const inProgress = burningERC20Capsules.some(function(burningObj, burningIndex) {
                                if (obj.address === burningObj.address &&
                                  obj.id === burningObj.id) {
                                    return true
                                }
                              })

                              return inProgress ?
                                  (<span
                                    style={{
                                      textAlign: "center",
                                      fontSize: "18px"
                                    }}
                                  >
                                    <CircularProgress
                                      size={24}
                                      style={{
                                        marginRight: "10px"
                                      }}
                                    />
                                    <strong>Burning Capsule...</strong>
                                  </span>)
                                :
                                  (<BurnCapsule
                                    address={obj.address}
                                    id={obj.id}
                                    handleBurn={handleERC20CapsuleBurn}
                                    type={ obj?.tokens ? 'multi' : 'single' }
                                  />)
                            })()}
                            <SendCapsule
                              account={account}
                              address={collectionAddress}
                              id={obj.id}
                            />
                          </GridContainer>
                        </GridItem>
                    )
                  })}
                  <GridItem xs={12}
                    style={{
                      marginTop: "10px",
                      marginBottom: "25px"
                    }}
                  >
                    <Pagination
                      className={classes.pagination}
                      page={erc20CapsuleCurrentPage}
                      size="large"
                      count={Math.ceil(userNFTTotalHoldings.erc20NFTHoldings.length / PageSize)}
                      onChange={erc20PaginationPageChange}
                    />
                  </GridItem>
                </GridContainer>
              </div>
            )
          },
          {
            tabButton: "ERC-721 Capsule NFTs",
            tabIcon: OfflineBoltIcon,
            tabContent: (
              <div style={{
                marginTop: "-20px"
              }}>
                <h3
                  className={classes.textCenter}
                  style={{marginTop: "75px"}}
                ><strong>ERC-721 Capsule NFTs</strong></h3>
                <h5 className={classes.textCenter}>
                  {
                    !active ?
                      'Sign in to view your Capsule NFTs here'
                    :
                      (userNFTTotalHoldings.erc721NFTHoldings.length < 1) ?
                          'You don\'t have any ERC721 Capsule NFTs from this collection'
                        :
                          (active && account) ?
                            null
                          :
                            'Connect to your account'
                  }
                </h5>
                <GridContainer>
                  {erc721CapsuleShownNFTs.map(function(obj, index){
                    return (
                        <GridItem
                          xs={12}
                          md={6}
                          style={{marginBottom: "10px"}}
                        >
                          <NFTDataCard
                            nftAddress={collectionAddress}
                            nftId={obj.id}
                            height={height}
                            key={collectionAddress + obj.id}
                          />
                          <GridContainer justifyContent="center">
                            {(() => {
                              const inProgress = burningERC721Capsules.some(function(burningObj, burningIndex) {
                                if (obj.address === burningObj.address &&
                                  obj.id === burningObj.id) {
                                    return true
                                }
                              })

                              return inProgress ?
                                  (<span
                                    style={{
                                      textAlign: "center",
                                      fontSize: "18px"
                                    }}
                                  >
                                    <CircularProgress
                                      size={24}
                                      style={{
                                        marginRight: "10px"
                                      }}
                                    />
                                    <strong>Burning Capsule...</strong>
                                  </span>)
                                :
                                  (<BurnCapsule
                                    address={obj.address}
                                    id={obj.id}
                                    handleBurn={handleERC721CapsuleBurn}
                                    type={ obj?.tokens ? 'multi' : 'single' }
                                  />)
                            })()}
                            <SendCapsule
                              account={account}
                              address={collectionAddress}
                              id={obj.id}
                            />
                          </GridContainer>
                        </GridItem>
                    )
                  })}
                  <GridItem xs={12}
                    style={{
                      marginTop: "10px",
                      marginBottom: "25px"
                    }}
                  >
                    <Pagination
                      className={classes.pagination}
                      page={erc721CapsuleCurrentPage}
                      size="large"
                      count={Math.ceil(userNFTTotalHoldings.erc721NFTHoldings.length / PageSize)}
                      onChange={erc721PaginationPageChange}
                    />
                  </GridItem>
                </GridContainer>
              </div>
            ),
          },
          {
            tabButton: "ERC-1155 Capsule NFTs",
            tabIcon: GroupWorkIcon,
            tabContent: (
              <div style={{
                marginTop: "-20px"
              }}>
                <h3
                  className={classes.textCenter}
                  style={{marginTop: "75px"}}
                ><strong>ERC-1155 Capsule NFTs</strong></h3>
                <h5 className={classes.textCenter}>
                  {
                    !active ?
                      'Sign in to view your Capsule NFTs here'
                    :
                      (userNFTTotalHoldings.erc1155NFTHoldings.length < 1) ?
                          'You don\'t have any ERC1155 Capsule NFTs from this collection'
                        :
                          (active && account) ?
                            null
                          :
                            'Connect to your account'
                  }
                </h5>
                <GridContainer>
                  {erc1155CapsuleShownNFTs.map(function(obj, index){
                    return (
                        <GridItem
                          xs={12}
                          md={6}
                          style={{marginBottom: "10px"}}
                        >
                          <NFTDataCard
                            nftAddress={collectionAddress}
                            nftId={obj.id}
                            height={height}
                            key={collectionAddress + obj.id}
                          />
                          <GridContainer justifyContent="center">
                            {(() => {
                              const inProgress = burningERC1155Capsules.some(function(burningObj, burningIndex) {
                                if (obj.address === burningObj.address &&
                                  obj.id === burningObj.id) {
                                    return true
                                }
                              })

                              return inProgress ?
                                  (<span
                                    style={{
                                      textAlign: "center",
                                      fontSize: "18px"
                                    }}
                                  >
                                    <CircularProgress
                                      size={24}
                                      style={{
                                        marginRight: "10px"
                                      }}
                                    />
                                    <strong>Burning Capsule...</strong>
                                  </span>)
                                :
                                  (<BurnCapsule
                                    address={obj.address}
                                    id={obj.id}
                                    handleBurn={handleERC1155CapsuleBurn}
                                    type={ obj?.tokens ? 'multi' : 'single' }
                                  />)
                            })()}
                            <SendCapsule
                              account={account}
                              address={collectionAddress}
                              id={obj.id}
                            />
                          </GridContainer>
                        </GridItem>
                    )
                  })}
                  <GridItem xs={12}
                    style={{
                      marginTop: "10px",
                      marginBottom: "25px"
                    }}
                  >
                    <Pagination
                      className={classes.pagination}
                      page={erc1155CapsuleCurrentPage}
                      size="large"
                      count={Math.ceil(userNFTTotalHoldings.erc1155NFTHoldings.length / PageSize)}
                      onChange={erc1155PaginationPageChange}
                    />
                  </GridItem>
                </GridContainer>
              </div>
            )
          }
        ]}
      />
    </>
  )
}

export default GetUserOwnedNFTs
