import { useEffect, useState } from "react"

import {
  MaxUInt256,
  convertFormattedAmountToDecimalString
} from 'utils'
import { useActiveWeb3React } from 'hooks/useActiveWeb3React.js'

// approve tokens, if approval < 0
import ApproveXERC20Tokens from 'components/app/FunctionComponents/ApproveXERC20Tokens.js'
import { BigNumber } from "@ethersproject/bignumber"

const GetERC20TokenAllowance = function (props) {
  const {
    allowance,
    balance,
    decimalBalance,
    tokenAddress,
    getTokenDecimals,
    erc20MintAmount
  } = props
  const { account } = useActiveWeb3React()

  const [allowanceInDecimals, setAllowanceInDecimals] = useState("0")

  const getAllowanceInDecimals = async () => {
    try {
      const total = await getTokenDecimals()
      return convertFormattedAmountToDecimalString(allowance, total || 0)
    } catch (e) {
      //console.error(e)
      return 0
    }
  }

  useEffect(async () => {
    setAllowanceInDecimals(await getAllowanceInDecimals())
  }, [allowance,
    balance,
    decimalBalance,
    tokenAddress,
    getTokenDecimals,
    erc20MintAmount
  ])

  return (
    <>
      {
        (account && allowance) &&
            <>
              <p style={{
                marginTop: "5px",
                fontSize: "18px"
              }}>
                {`You own ${decimalBalance} tokens, ` +
                  `${(Number(allowanceInDecimals) > balance) ?
                    ' all '
                  :
                    ` ${allowanceInDecimals || 0} `} approved to use.`
                }
              </p>
              {
                (Number(allowanceInDecimals) < balance) &&
                  <>
                    <ApproveXERC20Tokens
                      tokenAddress={tokenAddress}
                      amount={erc20MintAmount}
                    />
                    <ApproveXERC20Tokens
                      tokenAddress={tokenAddress}
                      amount={MaxUInt256}
                    />
                  </>
              }
            </>
      }
      {
        !account && 'Sign in, and select a token'
      }
      {
        (account && BigNumber.from(allowance).eq(BigNumber.from("0")))
      }
    </>
  )
}

export default GetERC20TokenAllowance
