import React, { useEffect, useState } from "react"
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import MoneyIcon from '@material-ui/icons/Money';
// core components
import InputAdornment from "@material-ui/core/InputAdornment";
// core components
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";

import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Grid from '@material-ui/core/Grid';
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

// dropdown
import {
  MainChainId,
  NFTMinterAddress,
  ZeroAddress,
  isAddress
} from 'utils'

// dropdown currency info
import { capsuleTokenList as erc721TokenList } from 'components/app/FunctionComponents/CurrencyInputDropdown/erc721-token-list'
import CurrencyLogo from 'components/app/FunctionComponents/CurrencyInputDropdown/CurrencyLogo'
import GetTokenInfo from 'components/app/FunctionComponents/CurrencyInputDropdown/GetTokenInfo'
import DropdownTextField from 'components/app/FunctionComponents/CurrencyInputDropdown/DropdownTextField'

import styles from "assets/jss/material-kit-react/views/componentsSections/tabsStyle.js";

// approve tokens, allowance info
import GetERC721TokenApproval from 'components/app/FunctionComponents/GetERC721TokenApproval.js'

// import required nft creation info
import { useERC721Contract } from 'hooks/useContract.js'
import { useActiveWeb3React } from 'hooks/useActiveWeb3React.js'

// get state
import { useSelector } from 'react-redux'

const useStyles = makeStyles(styles);

export default function ERC721CapsuleTab(props) {
  const classes = useStyles();

  const { identifier, updateData, deleteRow, val } = props
  const { account, chainId } = useActiveWeb3React()

  // tokenList(MainChainId)[0]
  const [tokenAddressValue, setTokenAddressValue] = useState(null);
  const [inputValue, setInputValue] = useState('');

  // token amount input
  const [erc721MintId, setErc721MintId] = React.useState('')
  const handleIdChange = event => {
    setErc721MintId(event.target.value)
  }

  // account information
  const [accountAllowance, setAccountAllowance] = useState(ZeroAddress)
  const [accountCollectionAllowance, setAccountCollectionAllowance] = useState(ZeroAddress)
  const [userOwnedNFT, setUserOwnedNFT] = useState(false)

  // current test for loading transactions
  const transactionSelector = useSelector(state => state.transactions)

  const [txEx, setTxEx] = useState(transactionSelector?.[chainId]); // set when tx executed
  useEffect(() => {
    setTxEx(transactionSelector?.[chainId])
  }, [transactionSelector])

  // create the capsule through the NFT component
  const tokenERC721Contract = useERC721Contract(tokenAddressValue?.address, true, { active: false })

  // check if user owns input NFT
  const getAccountOwned = async (tokenId) => {
    return tokenERC721Contract?.ownerOf(tokenId)
  }

  const checkOwner = async (account, tokenId) => {
    try {
      if (isNaN(tokenId) || !tokenId || !isAddress(account)) {
        return ZeroAddress
      }
      const owner = await getAccountOwned(tokenId)
      setUserOwnedNFT(owner === account)
      return owner === account
    } catch (e) {
      console.error("Checking NFT Owner failed", e)
      setUserOwnedNFT(false)
      return false
    }
  }

  // NFT allowance:
  const getAccountApproved = async (tokenId) => {
    return tokenERC721Contract?.getApproved(tokenId)
  }

  const handleApproved = async (tokenId) => {
    try {
      if (isNaN(tokenId) || !tokenId) {
        return ZeroAddress
      }
      const account = await getAccountApproved(tokenId)
      return account || ZeroAddress
    } catch (e) {
      console.error("HandleApproved failed", e)
      return ZeroAddress
    }
  }

  // all approval checks the owners address to the CapsuleMinter - returns a boolean
  const getAllApproved = async (owner) => {
    return tokenERC721Contract?.isApprovedForAll(owner, NFTMinterAddress(MainChainId))
  }

  const handleAllApproved = async (owner) => {
    try {
      if (!isAddress(owner)) {
        return false
      }
      const bool = await getAllApproved(owner)
      return bool
    } catch (e) {
      console.error("HandleApproved failed", e)
      return false
    }
  }

  const callAccountApproved = async () => {
    try {
      const allApprovedResult = await handleAllApproved(account)
      if (allApprovedResult) {
        setAccountCollectionAllowance(NFTMinterAddress(MainChainId))
        return
      }
      const singleApprovedResult = await handleApproved(erc721MintId)
      setAccountAllowance(singleApprovedResult)
    } catch (e) {
      // can add an interactive error here to show when the NFT doesn't exist
      console.error('Err getting account approved', e)
      setAccountAllowance(ZeroAddress)
    }
  }

  // NFT Approved
  useEffect(() => {
    callAccountApproved()
    checkOwner(account, erc721MintId)
  }, [tokenAddressValue?.address, erc721MintId, JSON.stringify(transactionSelector), txEx, account])

  useEffect(() => {
    updateData(identifier, tokenAddressValue?.address, erc721MintId)
  }, [tokenAddressValue?.address, erc721MintId])

  const collectionApproved = (NFTMinterAddress(MainChainId) === accountCollectionAllowance)
  const nftApproved = (NFTMinterAddress(MainChainId) === accountAllowance)

  return (
    <div className={classes.purpleBorder}>
      <CardBody>
        <GridContainer>
          <GridItem xs={12} sm={12} md={8}>
            <Autocomplete
              onChange={(event, newValue) => {
                setTokenAddressValue(newValue)
              }}
              fullWidth
              inputValue={inputValue.name ? inputValue.name : inputValue}
              onInputChange={(event, newInputValue) => {
                setTokenAddressValue(newInputValue.address ?
                  {...newInputValue} :
                  {
                    address: newInputValue
                  }
                )
                setInputValue(newInputValue.address ? {...newInputValue} : newInputValue)
              }}
              options={erc721TokenList(MainChainId)}
              freeSolo
              autoHighlight
              classes={{
                root: classes.autocomplete
              }}
              getOptionLabel={(option) => option.name ? option.name : option}
              renderOption={(option) => (
                <>
                  <CurrencyLogo
                    width={24}
                    height={24}
                    address={option.address}
                    type={'erc721'}
                  />
                  &nbsp;
                  ({option.symbol}) {option.name}
                </>
              )}
              renderInput={(params) => (
                <>
                  <div className={classes.margin}>
                    <Grid container spacing={1} alignItems="flex-end">
                      <Grid item xs={12} sm={12} md={1}>
                        <CurrencyLogo
                          width={32}
                          height={32}
                          address={tokenAddressValue?.address}
                          type={'erc721'}
                          style={{
                            marginTop: "10px",
                            marginBottom: "10px",
                            marginLeft: "10px",
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={9}>
                        <DropdownTextField
                          params={params}
                          classes={classes}
                          type={'erc721'}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={2}>
                        {(tokenAddressValue?.symbol ?
                            <p>{tokenAddressValue?.symbol}</p>
                          :
                            <GetTokenInfo
                              tokenAddress={tokenAddressValue?.address}
                              useNode={{ active: false }}
                              type={'erc721'}
                            />
                          )}
                      </Grid>
                    </Grid>
                  </div>
                </>
              )}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={2}>
            <TextField
              fullWidth
              label="NFT ID"
              variant="outlined"
              classes={{
                root: classes.pureTextfield
              }}
              formcontrolprops={{
                fullWidth: true,
              }}
              inputProps={{
                type: "number",
                endadornment: (
                  <InputAdornment position="end">
                    <MoneyIcon className={classes.inputIconsColor} />
                  </InputAdornment>
                ),
                style: {
                  color: "white"
                },
                value: erc721MintId,
                onChange: handleIdChange
              }}
            />
          </GridItem>
          <Grid item xs={12} sm={12} md={1}>
            <Button
              onClick={() => deleteRow(val)}
              type="button"
              color="danger"
              size="sm"
              style={{
                marginTop: "10px",
                marginBottom: "10px",
                marginLeft: "10px",
              }}
              className="btn btn-primary text-center"
            >
              <i
                className="fa fa-minus-circle"
                aria-hidden="true"
                style={{
                  marginTop: "5px",
                  marginBottom: "5px"
                }}
              />
            </Button>
          </Grid>
        </GridContainer>
        <GridContainer
          alignItems="center"
          justifyContent="center"
          style={{
            marginTop: "25px"
          }}
        >
          <GridItem xs={12} sm={12} md={8}>
            <div
              className={classes.tokenAllowance}
              style={{
                textAlign: "center"
              }}
            >
              {
                userOwnedNFT ?
                    <p>You own this NFT</p>
                  :
                    <p style={{fontSize: "18px"}}>You don't own this NFT</p>
              }
              {
                collectionApproved ?
                  <p>NFT collection approved</p>
                :
                  nftApproved ?
                    <p>Individual NFT (ID {erc721MintId}) approved</p>
                  :
                    <>
                      <p style={{fontSize: "18px"}}>Approve your NFT:</p>
                      <GetERC721TokenApproval
                        tokenAddress={tokenAddressValue?.address}
                        erc721MintId={erc721MintId}
                      />
                    </>
              }
            </div>
          </GridItem>
        </GridContainer>
      </CardBody>
    </div>
  )
}
