import { useEffect, useState } from "react"
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import MoneyIcon from '@material-ui/icons/Money';
// core components
import InputAdornment from "@material-ui/core/InputAdornment";
// core components
import Button from "components/CustomButtons/Button.js";

import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Grid from '@material-ui/core/Grid';
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

// dropdown
import {
  MainChainId,
  NFTMinterAddress,
  convertFormattedAmountToDecimalString,
  stringAmountMultiplication
} from 'utils'

import { capsuleTokenList as erc1155TokenList } from 'components/app/FunctionComponents/CurrencyInputDropdown/erc1155-token-list'
import CurrencyLogo from 'components/app/FunctionComponents/CurrencyInputDropdown/CurrencyLogo'
import GetTokenInfo from 'components/app/FunctionComponents/CurrencyInputDropdown/GetTokenInfo'
import DropdownTextField from 'components/app/FunctionComponents/CurrencyInputDropdown/DropdownTextField'

import styles from "assets/jss/material-kit-react/views/componentsSections/tabsStyle.js";

// approve tokens, allowance info
import GetERC1155TokenAllowance from 'components/app/FunctionComponents/GetERC1155TokenAllowance.js'

// import required nft creation info
import { useERC1155Contract } from 'hooks/useContract.js'
import { useActiveWeb3React } from 'hooks/useActiveWeb3React.js'
import { BigNumber } from "@ethersproject/bignumber"

// get state
import { useSelector } from 'react-redux'

const useStyles = makeStyles(styles);

export default function ERC1155CapsuleTab(props) {
  const classes = useStyles();

  const { identifier, updateData, deleteRow, val } = props
  const { account, chainId } = useActiveWeb3React()

  // tokenList(MainChainId)[0]
  const [tokenAddressValue, setTokenAddressValue] = useState(null);
  const [inputValue, setInputValue] = useState('');

  // token amount input
  const [erc1155MintAmount, setErc1155MintAmount] = useState('')
  const handleAmountChange = event => {
    setErc1155MintAmount(event.target.value)
  }

  // token amount input
  const [erc1155MintId, setErc1155MintId] = useState('')
  const handleIdChange = event => {
    setErc1155MintId(event.target.value)
  }

  // account information
  const [accountAllowance, setAccountAllowance] = useState(false)
  const [accountBalance, setAccountBalance] = useState("0")
  // this is the 'view'able, non bignumber amount the user owns (i.e. 10 wETH, instead of 10^17*10)
  const [decimalAccountBalance, setDecimalAccountBalance] = useState("0")

  // current test for loading transactions
  const transactionSelector = useSelector(state => state.transactions)

  const [txEx, setTxEx] = useState(transactionSelector?.[chainId]); // set when tx executed
  useEffect(() => {
    setTxEx(transactionSelector?.[chainId])
  }, [transactionSelector])

  const tokenERC1155Contract = useERC1155Contract(tokenAddressValue?.address, true, { active: false })

  const getTokenAmount = async (tokenId) => {
    return tokenERC1155Contract?.balanceOf(account, tokenId)
  }

  // token/collection allowance:
  const getAccountAllowance = async () => {
    return tokenERC1155Contract?.isApprovedForAll(account, NFTMinterAddress(MainChainId))
  }

  const callAccountAllowance = async () => {
    try {
      const result = await getAccountAllowance()
      setAccountAllowance(result)
    } catch (e) {
      console.log('err getting acc allowance', e)
      setAccountAllowance(false)
    }
  }

  const getAccountBalance = async (id) => {
    try {
      const amount = await tokenERC1155Contract?.balanceOf(account, id)
      setDecimalAccountBalance(amount)
      return amount
    } catch (e) {
      return null
    }
  }

  const handleBalance = async (id) => {
    try {
      const total = await getAccountBalance(id)
      return total ? total : BigNumber.from("0")
    } catch (e) {
      console.error(e)
      return BigNumber.from("0")
    }
  }

  const callAccountBalance = async () => {
    try {
      setDecimalAccountBalance("0")
      const result = await handleBalance(erc1155MintId)
      setAccountBalance(result)
    } catch (e) {
      console.log('err getting acc balance', e)
      setAccountBalance("0")
    }
  }

  // % button values:
  const setXPercentOfHoldings = (percent) => {
    setErc1155MintAmount(
      Math.floor(parseInt(stringAmountMultiplication(decimalAccountBalance, percent)))
    )
  }

  // token allowance
  useEffect(() => {
    callAccountAllowance()
    callAccountBalance()
  }, [tokenAddressValue?.address, JSON.stringify(transactionSelector), txEx, account])

  useEffect(() => {
    updateData(identifier, tokenAddressValue?.address, erc1155MintAmount, erc1155MintId)
  }, [tokenAddressValue?.address, erc1155MintAmount, erc1155MintId])

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={7}>
          <Autocomplete
            onChange={(event, newValue) => {
              setTokenAddressValue(newValue)
            }}
            fullWidth
            autoComplete
            includeInputInList
            filterSelectedOptions
            inputValue={inputValue.name ? inputValue.name : inputValue}
            onInputChange={(event, newInputValue) => {
              setTokenAddressValue(newInputValue.address ?
                  {...newInputValue}
                :
                  {
                    address: newInputValue
                  }
              )
              setInputValue(newInputValue.address ? {...newInputValue} : newInputValue)
            }}
            options={erc1155TokenList(MainChainId)}
            freeSolo
            autoHighlight
            classes={{
              root: classes.autocomplete
            }}
            getOptionLabel={(option) => option?.name ? `${option?.name} - ${option?.symbol}` : option}
            renderOption={(option) => (
              <>
                <CurrencyLogo
                  width={24}
                  height={24}
                  address={option.address}
                  type={'erc1155'}
                />
                &nbsp;
                ({option.symbol}) {option.name}
              </>
            )}
            renderInput={(params) => (
              <>
                <div className={classes.margin}>
                  <Grid container spacing={1} alignItems="flex-end">
                    <Grid item xs={12} sm={12} md={1}>
                      <CurrencyLogo
                        width={32}
                        height={32}
                        address={tokenAddressValue?.address}
                        type={'erc1155'}
                        style={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          marginLeft: "10px",
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={10}>
                      <DropdownTextField
                        params={params}
                        classes={classes}
                        type='erc1155'
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}
                      style={{
                        marginBottom: "6px"
                      }}
                    >
                      {(tokenAddressValue?.symbol ?
                          <p>{tokenAddressValue?.symbol}</p>
                        :
                          <GetTokenInfo
                            tokenAddress={tokenAddressValue?.address}
                            useNode={{ active: false }}
                            type={'erc1155'}
                          />
                        )}
                    </Grid>
                  </Grid>
                </div>
              </>
            )}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={1}>
          <TextField
            fullWidth
            label="ID"
            variant="outlined"
            classes={{
              root: classes.pureTextfield
            }}
            formcontrolprops={{
              fullWidth: true,
            }}
            inputProps={{
              type: "number",
              endadornment: (
                <InputAdornment position="end">
                  <MoneyIcon className={classes.inputIconsColor} />
                </InputAdornment>
              ),
              style: {
                color: "white"
              },
              value: erc1155MintId,
              onChange: handleIdChange
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={3}>
          <TextField
            fullWidth
            label="Amount to Store..."
            variant="outlined"
            classes={{
              root: classes.pureTextfield
            }}
            formcontrolprops={{
              fullWidth: true,
            }}
            inputProps={{
              type: "number",
              endadornment: (
                <InputAdornment position="end">
                  <MoneyIcon className={classes.inputIconsColor} />
                </InputAdornment>
              ),
              style: {
                color: "white"
              },
              value: erc1155MintAmount,
              onChange: handleAmountChange
            }}
          />
        </GridItem>
        <Grid item xs={12} sm={12} md={1}>
          <Button
            onClick={() => deleteRow(val)}
            type="button"
            color="danger"
            size="sm"
            style={{
              marginTop: "10px",
              marginBottom: "10px",
              marginLeft: "10px",
            }}
            className="btn btn-primary text-center"
          >
            <i
              className="fa fa-minus-circle"
              aria-hidden="true"
              style={{
                marginTop: "5px",
                marginBottom: "5px"
              }}
            />
          </Button>
        </Grid>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={12} md={7}>
          <div
            className={classes.tokenAllowance}
            style={{
              textAlign: "center"
            }}
          >
            {
              accountAllowance ?
                <p>NFT collection approved</p>
              :
                <>
                  <p style={{fontSize: "18px"}}>Approve your NFTs:</p>
                  <GetERC1155TokenAllowance
                    tokenAddress={tokenAddressValue?.address}
                  />
                </>
            }
          </div>
        </GridItem>
        <GridItem xs={12} sm={12} md={4}>
          <Button
            color="danger"
            size="sm"
            onClick={() => setXPercentOfHoldings(0.25)}
          >
            25%
          </Button>
          <Button
            color="danger"
            size="sm"
            onClick={() => setXPercentOfHoldings(0.5)}
          >
            50%
          </Button>
          <Button
            color="danger"
            size="sm"
            onClick={() => setXPercentOfHoldings(0.75)}
          >
            75%
          </Button>
          <Button
            color="danger"
            size="sm"
            onClick={() => setXPercentOfHoldings(1)}
          >
            100%
          </Button>
        </GridItem>
      </GridContainer>
    </div>
  )
}
