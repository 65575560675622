import { useState, useEffect } from 'react'
// core components
import Snackbar from '@material-ui/core/Snackbar'
import MuiAlert from '@material-ui/lab/Alert'
// @material-ui/icons
import Check from "@material-ui/icons/Check"

// import chain id and chain name
import {
  getEtherscanLinkWithHash
} from 'utils'

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}

const TransactionPopup = function (props) {
  const { hash, message, severity, icon } = props

  const [open, setOpen] = useState(false)

  useEffect(() => {
    setOpen(true)
  }, [])

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }

    setOpen(false)
  }

  // severity types: success, error, info, warning
  return (
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      open={open}
      autoHideDuration={3000}
      onClose={handleClose}
      icon={ icon === 'check' ? Check : icon}
    >
      <Alert
        onClose={handleClose}
        severity={ severity ? severity : ''}
      >
        <a
          href={getEtherscanLinkWithHash(hash)}
          target="_blank" rel="noreferrer"
        > Transaction Link </a> {message}
      </Alert>
    </Snackbar>
  )
}

export default TransactionPopup
