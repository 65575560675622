import { useWeb3React as useWeb3ReactCore } from '@web3-react/core'
//import { Web3ReactContextInterface } from '@web3-react/core/dist/types'
//import { NetworkContextName } from '../constants'

export function useActiveWeb3React(){
    const context = useWeb3ReactCore()
    //const contextNetwork = useWeb3ReactCore(NetworkContextName)
    //return context.active ? context : contextNetwork
    return context
}

export default useActiveWeb3React
