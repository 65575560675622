const nftDataCardStyle = {
  removeHover: {
    background: "black",
    width: "100%",
    "&:link, &:visited": {
      color: "white",
    },
    "&:hover, &:active": {
      color: "#6e3fff"
    }
  },
  completelyRemoveHover: {
    background: "black",
    width: "100%",
    "&:link, &:visited": {
      color: "white",
    },
    "&:hover, &:active": {
      color: "white"
    }
  }
};

export default nftDataCardStyle;
