import { createAction } from "@reduxjs/toolkit";
//import { TokenList } from "@uniswap/token-lists";

export type PopupContent =
  | {
      txn: {
        hash: string;
        success: boolean;
        summary?: string;
      };
    }
  | {
      listUpdate: {
        listUrl: string;
        oldList: TokenList;
        newList: TokenList;
        auto: boolean;
      };
    };

export const updateBlockNumber = createAction("application/updateBlockNumber")
export const addPopup = createAction("application/addPopup")
export const removePopup = createAction("application/removePopup")
