import { useEffect, useState } from 'react'
import {
  useERC20Contract,
  useERC721Contract,
  useERC1155Contract
} from 'hooks/useContract.js'

import CurrencyLogo from 'components/app/FunctionComponents/CurrencyInputDropdown/CurrencyLogo'

const GetTokenInfo = function (props) {
  const { tokenAddress, tokenId, useNode, message, style, type } = props
  const tokenContractERC20 = useERC20Contract(tokenAddress, false, useNode)
  const tokenContractERC721 = useERC721Contract(tokenAddress, false, useNode)
  const tokenContractERC1155 = useERC1155Contract(tokenAddress, false, useNode)

  const [tokenName, setTokenName] = useState("?");
  const [tokenSymbol, setTokenSymbol] = useState("?");

  useEffect(() => {
    const getTokenName = async (tokenContract) => {
      try {
        return tokenContract?.name()
      } catch (e) {
        console.log(e, 'issue getting capsule collection NFT Name')
        return '?'
      }
    }

    const handleName = async (tokenContract) => {
      try {
        const name = await getTokenName(tokenContract)
        return name ? name : "?"
      } catch (e) {
        //console.error(e)
        return "?"
      }
    }

    const callTokenName = async (tokenContract) => {
      const result = await handleName(tokenContract)
      setTokenName(result)
    }

    const getTokenSymbol = async (tokenContract) => {
      return tokenContract?.symbol()
    }

    const handleSymbol = async (tokenContract) => {
      try {
        const symbol = await getTokenSymbol(tokenContract)
        return symbol ? symbol : "?"
      } catch (e) {
        //console.error(e)
        return "?"
      }
    }

    const callTokenSymbol = async (tokenContract) => {
      const result = await handleSymbol(tokenContract)
      setTokenSymbol(result)
    }

    // 1155 don't have name, symbol - just assume it's a 20 from abi
    const contractType = (type === 'erc1155' || type === 'erc20') ? tokenContractERC20 : tokenContractERC721

    callTokenName(contractType)
    callTokenSymbol(contractType)
  }, [tokenAddress, type])

  return (
    (tokenAddress ?
      (tokenSymbol ?
        (message ?
          <span style={style}>
            {message}
            <CurrencyLogo
              address={tokenAddress}
              type={type}
              style={{
                maxHeight: "32px",
                maxWidth: "32px",
                marginBottom: "5px"
              }}
            />
            {` ${tokenName} (${tokenSymbol})`}
          </span>
        :
          <p>{`${tokenName} (${tokenSymbol})`}</p>
        )
      :
        <p>'Sign in!'</p>
      )
    :
      <p></p>
    )
  )
}

export default GetTokenInfo
