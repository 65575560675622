import Button from "components/CustomButtons/Button.js";

// animation
//import FiberNewIcon from '@material-ui/icons/FiberNew';
//import { Animated } from "react-animated-css";

import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-kit-react/components/headerLinksStyle.js";

// get nfts minted hook
import { useNftsMinted } from "state/application/hooks.js"

const useStyles = makeStyles(styles)

const GetTotalNFTsMinted = function () {
  const classes = useStyles()

  const nftMintedNumber = useNftsMinted()

  /*
  const [animationVisible, setAnimationVisible] = useState(false)

  useEffect(async () => {
    setAnimationVisible(true)
    setTimeout(() => setAnimationVisible(false), 2000)
  }, [nftMintedNumber])
  */

  // other interesting animationIn types
  // headShake
  // bounceIn
  // flipInY
  // slideInDown

  /*

  {animationVisible &&
    <Animated
      animationIn="flipInY"
      animationOut="fadeOut"
      isVisible={animationVisible}
      style={{
      }}
    >
      <Button
        color="transparent"
        className={classes.navLink}
        href={`capsule/${nftMintedNumber-1}`}
      >
        <FiberNewIcon
          style={{ color: 'green' }}
        />
        <span
          style={{fontSize: '14px'}}
        >
          'Capsules Updated!'
        </span>
      </Button>
    </Animated>
  }

  */
  return (
    <div style={{
      display: "inline"
    }}>
      <Button
        color="transparent"
        className={classes.navLink}
      >
        <span
          style={{fontSize: '14px'}}
        >
          Total Capsule NFTs Minted: {
            (nftMintedNumber > 0) ?
              nftMintedNumber
                :
              '?'
          }
        </span>
      </Button>
    </div>
  )
}

export default GetTotalNFTsMinted

/*
events to watch for:
event baseNFTCreated(address account);
event SingleERC20ValueNFTCreated(address account, address token, uint256 amount);
event MultiERC20ValueNFTCreated(address account, address[] tokens, uint256[] amounts);
event SingleERC721ValueNFTCreated(address account, address token, uint256 id);
*/

/*
nftMinterContract?.on("BaseNFTCreated", async function (account, tx) {
  const totalMinted = await handleTotal()
  setNFTMintedNumber(totalMinted)
})

nftMinterContract?.on("SingleERC20ValueNFTCreated", async function (account, tx) {
  const totalMinted = await handleTotal()
  setNFTMintedNumber(totalMinted)
})
*/
