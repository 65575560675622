import React, { useState } from "react"
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import CardBody from "components/Card/CardBody.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

// dropdown
import {
  CapsuleNFTMintTax,
  parseEther,
} from 'utils'

import styles from "assets/jss/material-kit-react/views/componentsSections/tabsStyle.js";

// dropdown
import ERC721DropdownManager from './TokenDropdown/ERC721DropdownManager.js'

// nft data card
import NFTDataCard from "components/NFTDataCard"

// import required nft creation info
import { useNFTMinterContract } from 'hooks/useContract.js'
import { useActiveWeb3React } from 'hooks/useActiveWeb3React.js'
import UploadNFTInfo from "components/app/FunctionComponents/UploadNFTInfo"
import { BigNumber } from "@ethersproject/bignumber"

const useStyles = makeStyles(styles);

export default function ERC721CapsuleTab(props) {
  const classes = useStyles();

  const { collectionAddress } = props
  const { account, library } = useActiveWeb3React()

  // create the capsule through the MintNFT component
  const nftMinterContract = useNFTMinterContract(true, { active: false })

  const [capsuleDataArray, setCapsuleDataArray] = useState({
      data: [
        {
          index: Math.random(),
          id: 0,
          address: ""
        }
      ]
    }
  )

  const createCapsule = async (uri) => {
    try {
      if (capsuleDataArray.data.length < 1 || !library) {
        return
      } else {
        const data = capsuleDataArray.data

        const addressArray = []
        const idArray = []

        for (let i = 0; i < data.length; i++) {
          const tokenAddress = data[i].address
          const tokenId = BigNumber.from(
            Number(data[i].id)
          )

          // single ERC721 Capsule
          if (data.length === 1) {
            return nftMinterContract?.mintSingleERC721Capsule(
              collectionAddress,
              tokenAddress,
              tokenId,
              uri,
              account,
              {
                value: parseEther(CapsuleNFTMintTax)
              }
            )
          } else {
            addressArray.push(tokenAddress)
            idArray.push(tokenId)
          }
        }

        // mint multi Capsule with arrays built from data
        return nftMinterContract?.mintMultiERC721Capsule(
          collectionAddress,
          addressArray,
          idArray,
          uri,
          account,
          {
            value: parseEther(CapsuleNFTMintTax)
          }
        )
      }
    } catch (e) {
      console.log('problem minting the Capsule NFT', e)
      return null
    }
  }

  const updateCapsuleData = (id, address, nftId) => {
    let capsuleDataArrayTemp = [...capsuleDataArray.data]

    for (let i = 0; i < capsuleDataArrayTemp.length; i++) {
      if (capsuleDataArrayTemp[i].index === id) {
        capsuleDataArrayTemp[i].address = address
        capsuleDataArrayTemp[i].id = nftId
        setCapsuleDataArray({ data: [...capsuleDataArrayTemp] })
      }
    }
  }

  const addNewRow = function (e) {
    setCapsuleDataArray({
      data: [
        ...capsuleDataArray.data,
        {
          index: Math.random(),
          id: 0,
          address: ""
        }
      ]
    })
  }

  const clickOnDelete = function (record) {
    setCapsuleDataArray({
      data: capsuleDataArray.data.filter(r => r !== record)
    })
  }

  return (
    <div className={classes.purpleBorder}>
      <CardBody>
        <div
          className={classes.textCenter}
          style={{
            paddingBottom: "40px"
          }}
        >
          <h3><strong>Mint an ERC-721 Capsule NFT</strong></h3>
          <h5 className={classes.textCenter}>
            Need help? Consult this tutorial <strong><a href="https://www.youtube.com/watch?v=53vgzID3sWo" target="_blank" className={classes.textColor, classes.capsuleLink}>video.
            </a></strong>
          </h5>
          <p>An ERC-721 Capsule NFT stores any ERC-721 NFT alongside creation of the Capsule NFT. It could even be another Capsule NFT!</p>
        </div>
        <ERC721DropdownManager
          addRow={addNewRow}
          deleteRow={clickOnDelete.bind(this)}
          data={capsuleDataArray.data}
          updateData={updateCapsuleData}
        />
        <GridContainer alignItems="center" justifyContent="center">
          <h3>Input NFT(s):</h3>
        </GridContainer>
        <GridContainer>
          {
            capsuleDataArray?.data.map((val, idx) => {
              //const id = `erc721-preview-${val.index}`

              return (
                capsuleDataArray?.data.length === 0 ? null :
                (capsuleDataArray?.data.length === 1) ?
                  (!isNaN(val?.id) && val?.address) ?
                    <GridItem xs={12} sm={12} md={12}>
                      <NFTDataCard
                        nftAddress={val?.address}
                        nftId={val?.id}
                        height={300}
                      />
                    </GridItem> : null
                :
                  capsuleDataArray?.data.length === 2 ?
                  (!isNaN(val?.id) && val?.address) ?
                    <GridItem xs={12} sm={12} md={6}>
                      <NFTDataCard
                        nftAddress={val?.address}
                        nftId={val?.id}
                        height={300}
                      />
                    </GridItem> : null
                  :
                    (!isNaN(val?.id) && val?.address) ?
                    <GridItem xs={12} sm={6} md={4}>
                      <NFTDataCard
                        nftAddress={val?.address}
                        nftId={val?.id}
                        height={300}
                      />
                    </GridItem> : null
              )
            })
          }
        </GridContainer>
        <UploadNFTInfo
          nftMintFunction={createCapsule}
          erc721Data={capsuleDataArray.data}
          type='erc721-capsule'
          collectionAddress={collectionAddress}
        />
      </CardBody>
    </div>
  )
}
