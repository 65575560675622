import React, { useEffect } from "react";
import { useCallback, useState } from 'react'
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import Check from "@material-ui/icons/Check";
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import ImageIcon from '@material-ui/icons/Image';
import BurstModeIcon from '@material-ui/icons/BurstMode';
import { FaMask } from "react-icons/fa";
import HelpIcon from '@material-ui/icons/Help';
// core components
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import NavPills from "components/NavPills/NavPills.js";

import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Tooltip from "@material-ui/core/Tooltip";

// tx loader div
import PendingTransactions from "components/app/FunctionComponents/PendingTransactions.js"

import styles from "assets/jss/material-kit-react/views/componentsSections/tabsStyle.js";
//import styles from "assets/jss/material-kit-react/customCheckboxRadioSwitch.js";

// transactions get state
import { useSelector } from 'react-redux'

// collection getting imports
import {
  MainChainId,
  CapsuleNFTDeployTax,
  ZeroAddress,
  isAddress,
  getLibraryDirect,
  getGeneralCapsuleNFTContractDirect,
  parseEther
} from 'utils'

// for capsulenft factory methods
import { useCapsuleNFTFactoryContract } from 'hooks/useContract.js'
import Button from "components/CustomButtons/Button.js";

import SimpleCollectionCard from "components/CollectionDataCard/SimpleCollectionCard.js"

import { useTransactionAdder } from 'state/transactions/hooks'
import { useActiveWeb3React } from 'hooks/useActiveWeb3React.js'

const useStyles = makeStyles(styles);

export default function CollectionsTab() {
  const classes = useStyles()

  let { chainId, account, library } = useActiveWeb3React()

  // connect straight to node, if no library
  const useNode = {
    active: true,
    chainId: MainChainId
  }
  const capsuleNFTFactoryContract = useCapsuleNFTFactoryContract(
    account ? true : false,
    account ? { active: false } : useNode
  )

  // get collections info
  const [collectionsInfo, setCollectionsInfo] = useState([])

  // current test for loading transactions
  const transactionSelector = useSelector(state => state.transactions)
  const transactionState = chainId ? transactionSelector[chainId] ?? null : null

  const [txEx, setTxEx] = useState(transactionSelector?.[chainId]); // set when tx executed
  useEffect(() => {
    setTxEx(transactionSelector?.[chainId])
  }, [transactionSelector])

  // input user collectionName
  const [collectionName, setNftName] = useState('')
  const handleNftNameChange = event => {
    setNftName(event.target.value)
  }
  // input user nftDescription
  const [collectionSymbol, setNftSymbol] = useState('')
  const handleNftSymbolChange = event => {
    setNftSymbol(event.target.value)
  }

  // input privacy of collection
  const [privacyChecked, setPrivacyChecked] = useState(true)

  const handlePrivacyToggle = event => {
    setPrivacyChecked(!privacyChecked)
  }

  // display tokenURIOwnership validity as an address
  const [displayTokenURIOwnerValidAddress, setDisplayTokenURIOwnerValidAddress] = useState(true)

  // input account tokenURIOwner
  const [chosenCollectionTokenURIOwner, setChosenCollectionTokenURIOwner] = useState(ZeroAddress)

  const handleTokenURIOwnerChange = event => {
    const obtainedTokenURIOwnerAddress = event.target.value

    setChosenCollectionTokenURIOwner(obtainedTokenURIOwnerAddress)
    setDisplayTokenURIOwnerValidAddress(isAddress(obtainedTokenURIOwnerAddress))
  }

  const setTokenURIOwnerToZeroAddress = () => {
    setChosenCollectionTokenURIOwner(ZeroAddress)
    setDisplayTokenURIOwnerValidAddress(true)
  }

  const setTokenURIOwnerToLoggedInAccount = () => {
    setChosenCollectionTokenURIOwner(account || ZeroAddress)
    setDisplayTokenURIOwnerValidAddress(true)
  }

  // transactions area
  const [transactions, setTransactions] = useState([]) // user txs for div

  const [errorDiv, setErrorDiv] = useState(null)

  const createCapsuleCollection = async (name, symbol, uriOwner, isPrivate) => {
    return capsuleNFTFactoryContract?.createCapsuleCollection(name, symbol, uriOwner, isPrivate,
      {
        value: parseEther(CapsuleNFTDeployTax)
      }
    )
  }

  const addTransaction = useTransactionAdder()

  const createCapsuleNFTCollection = useCallback(async () => {
    try {
      const maxNameLength = 50
      const maxSymbolLength = 10
      let error = []

      const chosenCollectionName = collectionName
      const chosenCollectionSymbol = collectionSymbol
      const chosenCollectionPrivacy = privacyChecked

      if (chosenCollectionName.length > maxNameLength) {
        error.push(<p id="ntl">{`Name too long (Current ${chosenCollectionName.length} characters, max ${maxNameLength})`}</p>)
      }

      if (chosenCollectionSymbol.length > maxSymbolLength) {
        error.push(<p id="dtl">{`Symbol too long (Current ${chosenCollectionSymbol.length} characters, max ${maxSymbolLength})`}</p>)
      }

      if (error.length > 0) {
        setErrorDiv(<GridItem xs={12}>
          <h4
            style={{textAlign: 'center'}}
          >Error:</h4>
          {error}
        </GridItem>)
        return
      }

      setErrorDiv(null)

      const tx = await createCapsuleCollection(
        chosenCollectionName,
        chosenCollectionSymbol,
        chosenCollectionTokenURIOwner,
        chosenCollectionPrivacy
      )

      addTransaction(tx, {summary: `Capsule Collection Successfully Deployed`}, 'createCollection')

      return tx
    } catch (e) {
      console.log('problem creating capsule collection', e)
      return e
    }
  }, [collectionName, collectionSymbol, capsuleNFTFactoryContract])

  // transactions effect
  useEffect(() => () => {
    if (transactionState) {
      try {
        const recentTransactions = []
        for (const tx in transactionState) {
          if (transactionState[tx]?.type === 'createCollection') {
            if (transactionState[tx]?.receipt) {
              recentTransactions.push({
                tx,
                done: true
              })
            } else {
              recentTransactions.push({
                tx,
                done: false
              })
            }
          }
        }

        setTransactions(recentTransactions)
      } catch (e) {
        console.log('Failed to output state TXs', e)
      }
    }
  }, [JSON.stringify(transactionSelector), txEx])

  const getAllCapsuleNFTAddresses = async () => {
    return capsuleNFTFactoryContract?.getAllCapsuleCollections()
  }

  useEffect(async () => {
    const obtainedCollections = await getAllCapsuleNFTAddresses()

    if (obtainedCollections?.length < 1) {
      return
    }

    const collectionsInfoMenu = async () => {
      // directly access contract, w/o hooks, if no library
      if (!library) {
        library = getLibraryDirect(useNode)
      }

      return (obtainedCollections?.length > 0) ?
        Promise.all(obtainedCollections?.map(async function (nftAddress, _) {
          const capsuleNFTContract = getGeneralCapsuleNFTContractDirect(
            nftAddress,
            chainId || useNode.chainId,
            library
          )

          const getNFTName = async () => {
            try {
              return capsuleNFTContract?.name()
            } catch (e) {
              console.log(e, 'issue getting capsule collection NFT Name')
              return '?'
            }
          }

          const getNFTSymbol = async () => {
            return capsuleNFTContract?.symbol()
          }

          const getCollectionPrivacy = async () => {
            return capsuleNFTContract?.isCollectionPrivate()
          }

          const getCollectionOwner = async () => {
            try {
              return capsuleNFTContract?.owner()
            } catch (e) {
              return null
            }
          }

          const getCollectionTokenURIOwner = async () => {
            try {
              return capsuleNFTContract?.tokenURIOwner()
            } catch (e) {
              return null
            }
          }

          const getCollectionCounter = async () => {
            try {
              return capsuleNFTContract?.counter()
            } catch (e) {
              return null
            }
          }

          const getCollectionMaxId = async () => {
            try {
              return capsuleNFTContract?.maxId()
            } catch (e) {
              return null
            }
          }

          const createCollectionInfo = async () => {
            const name = await getNFTName()
            const symbol = await getNFTSymbol()
            const isPrivate = await getCollectionPrivacy()
            const owner = await getCollectionOwner()
            const tokenURIOwner = await getCollectionTokenURIOwner()
            const counter = await getCollectionCounter()
            const maxId = await getCollectionMaxId()

            const badWordRegex = new RegExp('(n|i|1){1,32}((g{2,32}|q){1,32}|[gq6]{2,32})[e3r]{1,32}')

            if (!nftAddress || !name || !symbol || (isPrivate == null)
              || badWordRegex.test(name) || badWordRegex.test(symbol)) {
              return {
                address: nftAddress,
                private: null,
                listing: nftAddress,
                owner: null,
                tokenURIOwner: null,
                counter: null,
                maxId: null
              }
            }

            return {
              address: nftAddress,
              private: isPrivate,
              listing: `${name}, (${symbol}) - ${nftAddress}`,
              owner,
              tokenURIOwner,
              counter,
              maxId
            }
          }

          const collectionString = await createCollectionInfo()

          return (
            collectionString
          )
        }))
        :
        []
    }

    setCollectionsInfo(await collectionsInfoMenu())
  }, [library, account, JSON.stringify(transactionSelector), txEx])

  let textStyle = {
    marginLeft: "40px",
    marginRight: "40px"
  }

  if (window.innerWidth < 501) {
    textStyle = {}
  }

  return (
    <>
      <NavPills
        color="danger"
        alignCenter={true}
        tabs={[
          {
            tabButton: "Create a Collection",
            tabIcon: PhotoCameraIcon,
            tabContent: (
                <CardBody>
                  <h3 className={classes.textCenter}><strong>Create a Capsule Collection</strong></h3>
                  <div style={textStyle}>
                    <p className={classes.textCenter}>
                      <strong>Capsule Collections are user deployable NFT contracts compatible with the Capsule ecosystem.</strong>
                    </p>
                    <p>
                      Capsule Collections allow users to create their own series of Capsule NFTs, meaning the collection:
                    </p>
                    <ul>
                      <li>Can be named</li>
                      <li>Exists at its own unique address on the blockchain</li>
                      <li>Has the ability to mint any type of Capsule NFT from your collection </li>
                      <li>Can be defined as public or private <Tooltip
                          title={<>
                            <p>
                              Public - A public collection means anyone can mint Capsule NFTs to the collection
                            </p>
                            <p>
                              Private - A private collection (<FaMask/>) means only the owner of the contract (in most cases, the deployer) can mint to this collection.
                            </p>
                          </>}
                          placement="top"
                          style={{
                            marginLeft: "5px"
                          }}
                        >
                          <HelpIcon style={{height:"15px", width:"15px"}} />
                        </Tooltip>
                      </li>
                      <li>Can be locked to provably stop any further Capsule NFT mints from that collection<Tooltip
                          title={<p>
                              Locking a Capsule Collection sets a maximum limit of Capsule NFTs that can be minted. Once a collection is locked, it can never be unlocked.
                            </p>}
                          placement="top"
                          style={{
                            marginLeft: "5px"
                          }}
                        >
                          <HelpIcon style={{height:"15px", width:"15px"}} />
                        </Tooltip></li>
                    </ul>
                  </div>
                  <GridContainer>
                    <GridContainer
                      style={{"marginTop": "100px"}}
                      xs={12} alignItems="center" justifyContent="center"
                    >
                      <p style={{textAlign: "center"}}>
                        <strong>Your Capsule Collection's name. (For example, CapsuleNFT)</strong> <Tooltip
                            title={<p>
                                Max 50 characters
                              </p>}
                            placement="top"
                            style={{
                              marginLeft: "5px"
                            }}
                          >
                            <HelpIcon style={{height:"15px", width:"15px"}} />
                          </Tooltip>
                      </p>
                      <CustomInput
                        labelText="Collection Name"
                        formControlProps={{
                          fullWidth: true,
                          className: classes.textArea,
                        }}
                        inputProps={{
                          type: "text",
                          value: collectionName,
                          onChange: handleNftNameChange
                        }}
                      />
                    </GridContainer>
                    <GridContainer
                      style={{"marginTop": "100px"}}
                      xs={12} alignItems="center" justifyContent="center"
                    >
                      <div style={{width: "100%"}}>
                        <p style={{textAlign: "center"}}>
                          <strong>A shorthand name for your Capsule Collection. Try for 3-5 characters, and no spaces. (For CapsuleNFT, you might enter CNFT)</strong> <Tooltip
                              title={<p>
                                  Max 10 characters
                                </p>}
                              placement="top"
                              style={{
                                marginLeft: "5px"
                              }}
                            >
                              <HelpIcon style={{height:"15px", width:"15px"}} />
                            </Tooltip>
                        </p>
                      </div>
                      <CustomInput
                        labelText="Collection Acronym"
                        id="collectionSymbolText"
                        formControlProps={{
                          className: classes.textArea,
                        }}
                        inputProps={{
                          type: "text",
                          value: collectionSymbol,
                          onChange: handleNftSymbolChange
                        }}
                      />
                    </GridContainer>
                    <GridContainer
                      style={{"marginTop": "100px"}}
                      xs={12} alignItems="center" justifyContent="center"
                    >
                      <p style={{textAlign: "center"}}>
                        <strong>
                          The Metamaster is the address of the user who controls all of the metadata set inside any Capsule NFT in their collection.
                          If this is set to the zero address (0x0000...), no one, including the user, is able to change the metadata of a Capsule NFT after mint.
                          Otherwise, the Metamaster can be changed at a later time.
                        </strong>
                      </p>
                      <CustomInput
                        labelText="Collection Metamaster"
                        formControlProps={{
                          fullWidth: true,
                          className: classes.textArea,
                        }}
                        inputProps={{
                          type: "text",
                          value: chosenCollectionTokenURIOwner,
                          onChange: handleTokenURIOwnerChange
                        }}
                      />
                    </GridContainer>
                    <GridContainer xs={12} alignItems="center" justifyContent="center">
                      {
                        !displayTokenURIOwnerValidAddress &&
                        "Metamaster has not been given a proper address"
                      }
                    </GridContainer>
                    <GridContainer xs={12} alignItems="center" justifyContent="center">
                      <Button
                        color="danger"
                        onClick={setTokenURIOwnerToZeroAddress}
                      >
                        Zero Address
                      </Button>
                      <Button
                        color="danger"
                        onClick={setTokenURIOwnerToLoggedInAccount}
                      >
                        My Account
                      </Button>
                    </GridContainer>
                    <GridContainer
                      style={{"marginTop": "100px"}}
                      xs={12} alignItems="center" justifyContent="center"
                    >
                      <div style={{width: "100%"}}>
                        <p style={{textAlign: "center"}}>
                          <strong>
                            Private collections allow only the owner to mint Capsule NFTs from this collection.
                          </strong>
                        </p>
                        <p style={{textAlign: "center"}}>
                          <strong>
                            Non-private (public) collections allow anyone to mint Capsule NFTs from this collection.
                          </strong>
                        </p>
                      </div>
                      <FormControlLabel
                        control={
                          <Checkbox
                            tabIndex={-1}
                            onClick={ handlePrivacyToggle }
                            checked={ privacyChecked }
                            checkedIcon={<Check className={classes.checkedIcon} />}
                            icon={<Check className={classes.uncheckedIcon} />}
                            classes={{ checked: classes.checked }}
                          />
                        }
                        classes={{ label: classes.label }}
                        style={{
                          marginTop: "35px",
                          transform: "scale(1.15)"
                        }}
                        label="Private Collection"
                      />
                    </GridContainer>
                  </GridContainer>
                  <GridContainer
                    style={{"marginTop": "100px"}}
                    xs={12} alignItems="center" justifyContent="center"
                  >
                    <Button
                      color="danger"
                      onClick={createCapsuleNFTCollection}
                    >
                      Create your own Collection
                    </Button>
                  </GridContainer>
                  <GridItem xs={12} sm={12} md={12}
                    style={{
                      marginTop: "50px"
                    }}
                  >
                    <GridContainer justifyContent="center">
                      { errorDiv }
                    </GridContainer>
                    <GridContainer justifyContent="center">
                      <PendingTransactions
                        transactions={transactions}
                        txText={(obj) => ('Create a Collection')}
                      />
                    </GridContainer>
                  </GridItem>
                </CardBody>
            ),
          },
          {
            tabButton: "My Collections",
            tabIcon: ImageIcon,
            tabContent: (
              <GridItem xs={12} sm={12} md={12}>
                <h3 className={classes.textCenter}><strong>Your Capsule Collections:</strong></h3>
                {collectionsInfo.length < 1 && 'You don\'t own any Collections'}
                <ul
                  style={{
                    overflow: "auto",
                    maxHeight: "600px"
                  }}
                >
                  {collectionsInfo.map(function(obj, index){
                    const owner = obj.owner

                    return (owner === account) ? (
                      <SimpleCollectionCard
                        isPrivate={obj.private}
                        info={obj.listing}
                        owner={owner}
                        tokenURIOwner={obj.tokenURIOwner}
                        nftAddress={obj.address}
                        counter={obj.counter}
                        maxId={obj.maxId}
                        key={`${index}-my-${obj?.address}`}
                      />
                    )
                      :
                    null
                  })}
                </ul>
              </GridItem>
            ),
          },
          {
            tabButton: "Other Collections",
            tabIcon: BurstModeIcon,
            tabContent: (
              <GridItem xs={12} sm={12} md={12}>
                <h3 className={classes.textCenter}><strong>Other Capsule Collections:</strong></h3>
                <ul
                  style={{
                    overflow: "auto",
                    maxHeight: "600px"
                  }}
                >
                  {collectionsInfo.map(function(obj, index){
                    const owner = obj.owner

                    return (owner === account) ? null
                      :
                    (
                      <SimpleCollectionCard
                        isPrivate={obj.private}
                        info={obj.listing}
                        owner={owner}
                        tokenURIOwner={obj.tokenURIOwner}
                        nftAddress={obj.address}
                        counter={obj.counter}
                        maxId={obj.maxId}
                        key={`${index}-other-${obj?.address}`}
                      />
                    )
                  })}
                </ul>
              </GridItem>
            ),
          }
        ]}
      />
      <CardFooter className={classes.cardFooter}>
      </CardFooter>
    </>
  );
}
