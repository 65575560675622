import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import AppHeader from "components/LandingPage/Header/Header.js";
import HeaderLinks from "components/LandingPage/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";

import GridContainer from "components/Grid/GridContainer.js";

import styles from "assets/jss/material-kit-react/views/loginPage.js";

// Capsule logo
import image from "assets/img/capsule/CAP_logo_COMINGSOON_4.gif";

const useStyles = makeStyles(styles);

export default function MainApp(props) {
  //const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
  //setTimeout(function () {
  //  setCardAnimation("");
  //}, 700);

  const classes = useStyles();
  const { ...rest } = props;

  return (
    <div>
      <AppHeader
        absolute
        color="transparent"
        brand="CapsuleNFT"
        rightLinks={<HeaderLinks />}
        {...rest}
      />
      <div
        className={classes.pageHeader}
      >
        <div className={classes.container}>
          <GridContainer
            direction="column"
            alignItems="center"
            justifyContent="center"
          >
            <img
              src={image}
              alt="Coming Soon..."
              height={"50%"}
              width={"50%"}
            />
          </GridContainer>
        </div>
        <Footer whiteFont />
      </div>
    </div>
  );
}
