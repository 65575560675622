import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";

import styles from "assets/jss/material-kit-react/views/landingPageSections/teamStyle.js";

import image56 from "assets/img/capsule/team/Artboard 1.png";
import imageWinks from "assets/img/capsule/team/Artboard 2.png";
import imageMinky from "assets/img/capsule/team/Artboard 3.png";
import imageAnon from "assets/img/capsule/team/Artboard 4.png";
import imageDan from "assets/img/capsule/team/Artboard 5.png";
import imageMaybe from "assets/img/capsule/team/Artboard 6.png";
import imageCrispy from "assets/img/capsule/team/Artboard 7.png";
import imagePeople from "assets/img/capsule/team/Artboard 8.png";

const useStyles = makeStyles(styles);

export default function TeamSection() {
  const classes = useStyles();
  const imageClasses = classNames(
    //classes.imgRaised,
    //classes.imgRoundedCircle,
    classes.imgFluid
  );
  return (
    <div className={classes.section}>
      <h2 className={classes.title}>Team</h2>
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={4}>
            <Card plain>
              <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
                <img src={image56} alt="..." className={imageClasses} />
              </GridItem>
              <h4 className={classes.cardTitle}>
                56
                <br />
                <small className={classes.smallTitle}>CEO</small>
              </h4>
              <CardBody>
                <p className={classes.description}>
                  56 has been developing in crypto for more than 5 years, building and researching solutions across chains.
                  He's a big fan of turn based RPGs, and is currently hooked on Slay the Spire.
                </p>
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <Card plain>
              <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
                <img src={imageCrispy} alt="..." className={imageClasses} />
              </GridItem>
              <h4 className={classes.cardTitle}>
                Crispy
                <br />
                <small className={classes.smallTitle}>COO</small>
              </h4>
              <CardBody>
                <p className={classes.description}>
                  Crispy is a business-forward playmaker with major aspirations and a minor in art history.
                </p>
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <Card plain>
              <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
                <img src={imageDan} alt="..." className={imageClasses} />
              </GridItem>
              <h4 className={classes.cardTitle}>
                Necron9x11
                <br />
                <small className={classes.smallTitle}>CTO</small>
              </h4>
              <CardBody>
                <p className={classes.description}>
                  Necron9x11 can usually be found illuminated by the kind of glow that only comes from a half dozen or more monitors shining in a darkened room;
                  where he feeds on a steady diet of bits, bytes and digital ICs. Networks built, servers maintained, wars fought, revolutions started,
                  battles won, elephants bred. Whatever you need. He's your go to guy.
                </p>
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <Card plain>
              <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
                <img src={imageWinks} alt="..." className={imageClasses} />
              </GridItem>
              <h4 className={classes.cardTitle}>
                Winks
                <br />
                <small className={classes.smallTitle}>Head of Strategy</small>
              </h4>
              <CardBody>
                <p className={classes.description}>
                  Winks understands how to manipulate the situation to his interest. Obstacles stand no chance; they just wait to be conquered.
                  Whether it's the intentional placement of a chess piece or plotting blockchain domination, Winks embodies strategy.
                </p>
              </CardBody>
              <CardFooter className={classes.justifyCenter}>
              </CardFooter>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <Card plain>
              <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
                <img src={imageMinky} alt="..." className={imageClasses} />
              </GridItem>
              <h4 className={classes.cardTitle}>
                Minky
                <br />
                <small className={classes.smallTitle}>Community Manager</small>
              </h4>
              <CardBody>
                <p className={classes.description}>
                  Minky is a crypto enthusiast, trading mongrel, and video game warlord.
                </p>
              </CardBody>
              <CardFooter className={classes.justifyCenter}>
              </CardFooter>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <Card plain>
              <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
                <img src={imageAnon} alt="..." className={imageClasses} />
              </GridItem>
              <h4 className={classes.cardTitle}>
                Anonymous
                <br />
                <small className={classes.smallTitle}>Lead Engineer</small>
              </h4>
              <CardBody>
                <p className={classes.description}>
                  Anonymous has decided to stay anonymous! We assure you he is a person of great knowledge and integrity.
                </p>
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={2}>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <Card plain>
              <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
                <img src={imageMaybe} alt="..." className={imageClasses} />
              </GridItem>
              <h4 className={classes.cardTitle}>
                Maybe
                <br />
                <small className={classes.smallTitle}>Head of Talent and Culture</small>
              </h4>
              <CardBody>
                <p className={classes.description}>
                  Maybe is a writer of ads, scripts, sketches, books, love poems, embarrassing texts, bathroom graffiti, and Dungeons & Dragons campaigns.
                </p>
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <Card plain>
              <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
                <img src={imagePeople} alt="..." className={imageClasses} />
              </GridItem>
              <h4 className={classes.cardTitle}>
                And You
                <br />
                <small className={classes.smallTitle}>Anything!</small>
              </h4>
              <CardBody>
                <p className={classes.description}>
                  As a member of the community, feel free to help in any way you can. Participate in discussions, build your
                  own applications, and help utilize the Capsule Protocol as the NFT backbone of the future.
                </p>
              </CardBody>
              <CardFooter className={classes.justifyCenter}>
              </CardFooter>
            </Card>
          </GridItem>
        </GridContainer>
        <GridContainer justifyContent="center"
          style={{
            marginBottom: "100px"
          }}
        >
          <GridItem cs={12} sm={12} md={12}>
            <h2 className={classes.title}>Open Positions</h2>
            <p
              className={classes.title}
              style={{
                fontSize: "1.125rem"
              }}
            >
              Full Stack Developer &nbsp; - &nbsp; <a style={{color: "#6f40ff"}} target="_blank" href="https://www.linkedin.com/jobs/view/3084378608" rel="noreferrer">View Job Posting</a>
            </p>
            <p
              className={classes.title}
              style={{
                fontSize: "1.125rem"
              }}
            >
              Designer &nbsp; - &nbsp; <a style={{color: "#6f40ff"}} target="_blank" href="https://www.linkedin.com/jobs/view/3084378682" rel="noreferrer">View Job Posting</a>
            </p>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
