import { useCallback, useState } from 'react'
import { useERC20Contract } from 'hooks/useContract.js'
import {
  MainChainId, NFTMinterAddress, MaxUInt256, isAddress
} from 'utils'
import { useActiveWeb3React } from 'hooks/useActiveWeb3React.js'
import { useTransactionAdder } from 'state/transactions/hooks'
import { BigNumber } from "@ethersproject/bignumber"

// material ui
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import Button from "components/CustomButtons/Button.js";

const ApproveXERC20Tokens = function (props) {
  const addTransaction = useTransactionAdder()
  const { tokenAddress, amount } = props
  const { account } = useActiveWeb3React()
  const tokenERC20Contract = useERC20Contract(tokenAddress, true, { active: false })

  const [errorDiv, setErrorDiv] = useState(null)

  const getTokenDecimals = async () => {
    return tokenERC20Contract?.decimals()
  }

  const approveTokens = async () => {
    try {
      const tokenDecimals = await getTokenDecimals()
      const amountToDecimals = (amount === MaxUInt256)
        ?
          amount
        :
          (amount === 0)
            ?
              null
            :
              (amount < 1)
                ?
                  BigNumber.from(Number(amount * Math.pow(10, tokenDecimals)).toString())
                :
                  BigNumber.from(amount).mul(BigNumber.from(10).pow(tokenDecimals))

      if (!amountToDecimals) return

      return tokenERC20Contract?.approve(NFTMinterAddress(MainChainId), amountToDecimals)
    } catch (e) {
      if (e.code === 'INVALID_ARGUMENT') {
        setErrorDiv(`Error: Improper 'Amount to Wrap' value`)
      } else {
        setErrorDiv(`${e}`)
      }
      return null
    }
  }

  const handleApproval = useCallback(async () => {
    try {
        const tx = await approveTokens()
        addTransaction(tx, {summary: `Approve Successful`}, 'approveTokens')
        setErrorDiv(null)
        return tx
    } catch (e) {
        return e
    }
  }, [account, tokenAddress, amount])

  return (
    <>
      {
        errorDiv ?
            <GridItem xs={12}
              style={{
                marginBottom: "50px"
              }}
            >
              <GridContainer justifyContent="center">
                <Button
                  color="google"
                  size="lg"
                >ERROR</Button>
              </GridContainer>
              <GridContainer justifyContent="center">
                <p style={{
                  fontSize: '24px',
                  textAlign: 'center'
                }}>
                  <strong>{errorDiv}</strong>
                </p>
              </GridContainer>
            </GridItem>
          :
            null
      }
      {
        isAddress(tokenAddress) ?
          <Button
            color="danger"
            size="lg"
            onClick={handleApproval}
          >
            Approve {amount === MaxUInt256 ? 'Infinite' : `${amount} Tokens`}
          </Button>
        :
          ''
      }
    </>
  )
}

export default ApproveXERC20Tokens
