import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import AppHeader from "components/app/AppHeader/Header.js";
import HeaderLinks from "components/app/AppHeader/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";

// main updater
import ApplicationUpdater from 'state/application/updater.js'
import TransactionUpdater from 'state/transactions/updater.js'

// main swap app in the middle
import AppTabs from "components/app/AppMainWidget/main.js"

import styles from "assets/jss/material-kit-react/views/loginPage.js";

const useStyles = makeStyles(styles);

function Updaters() {
  return (
    <>
      <ApplicationUpdater/>
      <TransactionUpdater/>
    </>
  )
}

export default function MainApp(props) {
  //const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
  //setTimeout(function () {
  //  setCardAnimation("");
  //}, 700);
  const classes = useStyles();
  const { ...rest } = props;
  return (
    <div>
      <Updaters/>
      <AppHeader
        absolute
        color="transparent"
        brand="CapsuleNFT"
        rightLinks={<HeaderLinks />}
        {...rest}
      />
      <div
        className={classes.pageHeader}
        style={{
          paddingTop: "10vh"
        }}
      >
        <div className={classes.container}>
          <AppTabs/>
        </div>
        <Footer whiteFont />
      </div>
    </div>
  );
}
