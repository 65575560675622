import { useState, useEffect } from 'react'
import { useWeb3React } from '@web3-react/core'
import { injected } from 'connectors/connectors.js'
import { useEagerConnect, useInactiveListener } from 'hooks/web3ConnectorHooks.js'

// random spinner added with web3-react
import { Spinner } from 'components/Spinner.js'

// web3 balances
import UserBalance from "./UserBalance.js"
// @material-ui/icons
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';
// core components
import Button from "components/CustomButtons/Button.js";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// importing of styles
import styles from "assets/jss/material-kit-react/components/headerLinksStyle.js";

const useStyles = makeStyles(styles);

const SignInButton = function (props) {
  const classes = useStyles()

  const context = useWeb3React()
  const { connector, activate, deactivate, active, error } = context

  // handle logic to recognize the connector currently being activated
  const [activatingConnector, setActivatingConnector] = useState()
  useEffect(() => {
    if (activatingConnector && activatingConnector === connector) {
      setActivatingConnector(undefined)
    }
  }, [activatingConnector, connector])

  // handle logic to eagerly connect to the injected ethereum provider, if it exists and has granted access already
  const triedEager = useEagerConnect()

  // handle logic to connect in reaction to certain events on the injected ethereum provider, if it exists
  useInactiveListener(!triedEager || !!activatingConnector)

  const currentConnector = injected
  const activating = currentConnector === activatingConnector
  const connected = currentConnector === connector
  //const disabled = !triedEager || !!activatingConnector || connected || !!error

  return (
    <div
      style={{
        display: "inline"
      }}
    >
      {!connected && (
        <Button
          color="transparent"
          target="_blank"
          className={classes.navLink}
          onClick={() => {
            setActivatingConnector(currentConnector)
            activate(injected)
          }}
        >
          <LockOpenIcon className={classes.icons} /> Connect with Metamask
          {activating && <Spinner color={'black'} style={{ height: '25%', marginLeft: '-1rem' }} />}
        </Button>
      )}
    {(active || error) && (
      <>
        <UserBalance/>
        <Button
          color="transparent"
          target="_blank"
          className={classes.navLink}
          onClick={() => {
            deactivate()
          }}
        >
          <LockIcon className={classes.icons} /> Sign out
        </Button>
      </>
    )}
    </div>
  )
}

export default SignInButton
