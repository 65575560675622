import { useState } from 'react'

import Button from "components/CustomButtons/Button.js";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

// NFT Data card
import BeforeMintDataCard from "components/NFTDataCard/BeforeMintDataCard"

import { makeStyles } from "@material-ui/core/styles";

import styles from "assets/jss/material-kit-react/views/componentsSections/tabsStyle.js";

const useStyles = makeStyles(styles);

const MintCapsule = function (props) {
  const {
    errorDiv,
    type,
    handleMint,
    collectionAddress,
    image,
    name,
    description,
    insideTokenData
  } = props

  const classes = useStyles();

  const [mintDialogOpen, setMintDialogOpen] = useState(false)

  const handleClickOpen = () => {
    setMintDialogOpen(true)
  }

  const handleClose = () => {
    setMintDialogOpen(false)
  }

  return (
    <>
      <Button
        size="lg"
        color={`${errorDiv ? "google" : "danger"}`}
        onClick={handleClickOpen}
        variant="contained"
      >
        { type === 'base' ?
            'Mint your Capsule NFT'
          :
            `Mint your ${type === 'erc20-capsule' ? 'ERC-20' : type === 'erc1155-capsule' ? 'ERC-1155' : 'ERC-721'} Capsule NFT`
        }
      </Button>
      <Dialog
        open={mintDialogOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          style={{
            textAlign: "center",
            background: 'black',
            color: 'white',
            border: '2px solid white',
            borderBottomWidth: '0px'
          }}
        >
          {`Mint Capsule NFT`}
        </DialogTitle>
        <DialogContent
          style={{
            textAlign: "center",
            background: 'black',
            border: '2px solid white',
            borderBottomWidth: '0px',
            borderTopWidth: '0px'
          }}
        >
          <BeforeMintDataCard
            nftAddress={collectionAddress}
            nftId={'Pending...'}
            image={image}
            name={name}
            description={description}
            type={type}
            insideTokenData={insideTokenData}
            height={200}
          />
          <DialogContentText
            id="alert-dialog-description"
            style={{
              color: 'white',
              marginTop: '25px'
            }}
          >
            { errorDiv }
            <p
              style={{
                fontSize: '18px'
              }}
            >
              (Example preview of your Capsule NFT)
            </p>
            <p
              style={{
                fontSize: '18px'
              }}
            >
              Upon <strong>confirmation of a transaction</strong>, {`${(type !== 'base') ? 'your tokens will be stored, ' : ''}`}this Capsule NFT will be <i>minted</i>, assigned an identification (ID) number, and sent to your address.
            </p>
            <p
              style={{
                marginTop: '50px'
              }}
            >
              To further understand the process of minting, please read <a className={classes.capsuleLink} href="help" target="_blank">Minting Capsule NFTs</a>.
            </p>
            <p>
              By minting a Capsule NFT, you agree and consent to the <a className={classes.capsuleLink} href="terms" target="_blank">Terms & Conditions</a>.
            </p>
          </DialogContentText>
        </DialogContent>
        <DialogActions
          style={{
            background: 'black',
            alignItems: 'center',
            justifyContent: 'center',
            border: '2px solid white',
            borderTopWidth: '0px'
          }}
        >
          <Button
            onClick={handleClose}
            autoFocus
            color="google"
          >Cancel</Button>
          <Button
            color="danger"
            onClick={handleMint}
          >
            Mint Capsule NFT
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default MintCapsule
