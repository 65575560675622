import React from "react";

import { Helmet } from "react-helmet";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import AppHeader from "components/LandingPage/Header/Header.js";
import HeaderLinks from "components/LandingPage/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-kit-react/views/loginPage.js";

// import main chainId, link to opensea on chain
import {
  MainChainId,
  OpenSeaCollectionUrl
} from 'utils'

// import images/animations
//import capsuleAnimationGif from "assets/img/capsule/animations/home-capsule-animation-gif.gif"; // removable
import capsuleAnimationMp4 from "assets/img/capsule/animations/capsule-main-animation.mp4"; // removable

import Alert from '@material-ui/lab/Alert'

// detect browser
const { detect } = require('detect-browser');

const useStyles = makeStyles(styles);

export default function MainApp(props) {
  //const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
  //setTimeout(function () {
  //  setCardAnimation("");
  //}, 700);

  // lottie file background to make transparent: #878787

  const classes = useStyles();
  const { ...rest } = props;

  // get window max width for lottie
  const windowMaxWidth = (window.innerWidth < 700) ? window.innerWidth : 747
  const lottieHeight = (window.innerWidth < 700) ? (405 * (window.innerWidth / 747)) : 405

  //const style = (window.innerWidth < 700) ? {} : {}

  const buttonStyle = (window.innerWidth < 700)
    ?
      {
        width: '100%',
        textAlign: 'center'
      }
    :
      {
        textAlign: 'center'
      }

  const browser = detect()

  const [open, setOpen] = React.useState(false)

  React.useEffect(async () => {
    if (browser.name !== 'chrome') {
      setOpen(true)
      setTimeout(() => {
        setOpen(false);
      }, 5000)
    }
  }, [])

  return (
    <div>
      <Helmet>
        <html lang="en" />
        <title>CapsuleNFT - Composable NFT Infrastructure</title>
        <meta name="description" content="CapsuleNFT gives you the unique ability to generate token-embedded NFTs using the Capsule protocol, which can then be held, traded, or redeemed for the underlying tokens." />
      </Helmet>
      <AppHeader
        absolute
        color="transparent"
        brand="CapsuleNFT"
        rightLinks={<HeaderLinks />}
        {...rest}
      />
      {open &&
        <Alert
          severity="warning"
          style={{
            position: "sticky",
            zIndex: "100000"
          }}
        >
          Your browser is not optimized for the Capsule NFT application. Some features may not load properly or be misplaced. We recommend that you switch to the Google Chrome or Brave browser.
        </Alert>
      }
      <div
        className={classes.pageHeader}
      >
        <div className={classes.container}>
          <GridContainer
            direction="column"
            alignItems="center"
            justifyContent="center"
          >
            <div>
              <video autoPlay loop muted
                style={{
                  height: lottieHeight,
                  width: windowMaxWidth
                }}
              >
                <source
                  src={capsuleAnimationMp4}
                  type="video/mp4"
                >
                </source>
              </video>
            </div>

            <GridItem xs={12}>
              <h1
                className={classes.frontPageText}
                style={{
                  fontSize: "2.25rem",
                  lineHeight: "1.5em"
                }}
              >
                Store, trade, and redeem tokens with Capsule NFTs
              </h1>
            </GridItem>

            <br/>

            <GridItem xs={12}>
              <h3 className={classes.frontPageText}>
                Generate token-embedded NFTs using the Capsule Protocol
              </h3>
            </GridItem>

            <br/>
            <br/>

            <GridContainer
              style={buttonStyle}
            >
              <GridItem xs={12} sm={12} md={6}>
                <Button
                  color="danger"
                  size="lg"
                  href="/app"
                  rel="noopener noreferrer"
                >
                  Mint a Capsule NFT
                </Button>
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <Button
                  color="danger"
                  size="lg"
                  href={`${OpenSeaCollectionUrl(MainChainId)}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Capsule NFTs on OpenSea
                </Button>
              </GridItem>
            </GridContainer>
          </GridContainer>
        </div>
        <Footer whiteFont />
      </div>
    </div>
  );
}
