import CircularProgress from '@material-ui/core/CircularProgress'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'

import GridContainer from "components/Grid/GridContainer.js"
import GridItem from "components/Grid/GridItem.js"

//import { makeStyles } from '@material-ui/core/styles';

/*
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& > * + *': {
      marginLeft: theme.spacing(2),
    },
  },
}));
*/

const LoadingDiv = function (props) {
  //const classes = useStyles()

  const { messageOne, animationOneType,
    messageTwo, animationTwoType } = props

  const renderedAnimation = function (type) {
    switch(type) {
      case 'loading':
        return <CircularProgress
          size={24}
        />;
      case 'done':
        return <CheckCircleIcon/>;
      default:
        return <MoreHorizIcon/>;
    }
  }

  return (
    <>
      <GridContainer>
        <GridItem xs={3}>
          <h4>Status:</h4>
        </GridItem>
        <GridItem xs={7}>
          <p>{messageOne}</p>
          <p>{messageTwo}</p>
        </GridItem>
        <GridItem xs={2}>
          {renderedAnimation(animationOneType)}
          <br/>
          {animationTwoType && renderedAnimation(animationTwoType)}
        </GridItem>
      </GridContainer>
    </>
  )
}

export default LoadingDiv
