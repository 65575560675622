/*eslint-disable*/
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
// material-ui core components
import { List, ListItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import Favorite from "@material-ui/icons/Favorite";

// for icons
import Tooltip from "@material-ui/core/Tooltip";
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-kit-react/components/footerStyle.js";

const useStyles = makeStyles(styles);

export default function Footer(props) {
  const classes = useStyles();
  const { whiteFont } = props;
  const footerClasses = classNames({
    [classes.footer]: true,
    [classes.footerWhiteFont]: whiteFont,
  });
  const aClasses = classNames({
    [classes.a]: true,
    [classes.footerWhiteFont]: whiteFont,
  });

  return (
    <footer className={footerClasses}>
      <div className={classes.container}>
        <List>
          <ListItem className={classes.inlineBlock}>
            <Tooltip
              id="instagram-twitter"
              title="Follow us on Twitter"
              placement={window.innerWidth > 959 ? "top" : "left"}
              classes={{ tooltip: classes.tooltip }}
            >
              <Button
                href="https://twitter.com/CapsuleNFT"
                target="_blank"
                rel="noopener noreferrer"
                color="transparent"
                className={classes.navLink}
                style={{padding: '12px 10px'}}
              >
                <i className={classes.socialIcons + " fab fa-twitter"} />
              </Button>
            </Tooltip>
          </ListItem>
          <ListItem className={classes.inlineBlock}>
            <Tooltip
              id="discord-tooltip"
              title="Join us on Discord"
              placement={window.innerWidth > 959 ? "top" : "left"}
              classes={{ tooltip: classes.tooltip }}
            >
              <Button
                color="transparent"
                href="https://discord.gg/CapsuleNFT"
                target="_blank"
                rel="noopener noreferrer"
                className={classes.navLink}
                style={{padding: '12px 10px'}}
              >
                <i className={classes.socialIcons + " fab fa-discord"} />
              </Button>
            </Tooltip>
          </ListItem>
          <ListItem className={classes.inlineBlock}>
            <Tooltip
              id="youtube-tooltip"
              title="See us on Youtube"
              placement={window.innerWidth > 959 ? "top" : "left"}
              classes={{ tooltip: classes.tooltip }}
            >
              <Button
                color="transparent"
                href="https://www.youtube.com/channel/UCNTwlZLvWOFO7j7lOlyesBQ/featured"
                target="_blank"
                rel="noopener noreferrer"
                className={classes.navLink}
                style={{padding: '12px 10px'}}
              >
                <i className={classes.socialIcons + " fab fa-youtube"} />
              </Button>
            </Tooltip>
          </ListItem>
          <ListItem className={classes.inlineBlock}>
            <Tooltip
              id="instagram-tooltip"
              title="Follow us on Instagram"
              placement={window.innerWidth > 959 ? "top" : "left"}
              classes={{ tooltip: classes.tooltip }}
            >
              <Button
                color="transparent"
                href="https://www.instagram.com/capsule.nft/"
                target="_blank"
                rel="noopener noreferrer"
                className={classes.navLink}
                style={{padding: '12px 10px'}}
              >
                <i className={classes.socialIcons + " fab fa-instagram"} />
              </Button>
            </Tooltip>
          </ListItem>
          <ListItem className={classes.inlineBlock}>
            <Tooltip
              id="medium-tooltip"
              title="Read our articles on Medium"
              placement={window.innerWidth > 959 ? "top" : "left"}
              classes={{ tooltip: classes.tooltip }}
            >
              <Button
                color="transparent"
                href="https://medium.com/capsulenft"
                target="_blank"
                rel="noopener noreferrer"
                className={classes.navLink}
                style={{padding: '12px 10px'}}
              >
                <i className={classes.socialIcons + " fab fa-medium"} />
              </Button>
            </Tooltip>
          </ListItem>
          <ListItem className={classes.inlineBlock}>
            <Tooltip
              id="linkedin-tooltip"
              title="Follow us on LinkedIn"
              placement={window.innerWidth > 959 ? "top" : "left"}
              classes={{ tooltip: classes.tooltip }}
            >
              <Button
                color="transparent"
                href="https://www.linkedin.com/company/capsule-nft/"
                target="_blank"
                rel="noopener noreferrer"
                className={classes.navLink}
                style={{padding: '12px 10px'}}
              >
                <i className={classes.socialIcons + " fab fa-linkedin"} />
              </Button>
            </Tooltip>
          </ListItem>
        </List>
        <div className={classes.footerText}>
          Copyright &copy; {1900 + new Date().getYear()}, CapsuleNFT™. All Rights Reserved. <a className={classes.capsuleLink} href="https://docs.capsulenft.com/terms-of-service/legal" target="_blank" rel="noopener noreferrer">Legal.</a>
        </div>
      </div>
    </footer>
  );
}

Footer.propTypes = {
  whiteFont: PropTypes.bool,
};
