import { useEffect, useState } from 'react'
import {
  useERC20Contract,
  useERC1155Contract
} from 'hooks/useContract.js'

import {
  MainChainId,
  convertFormattedAmountToDecimalString
} from 'utils'
import GetTokenInfo from 'components/app/FunctionComponents/CurrencyInputDropdown/GetTokenInfo'
import { BigNumber } from "@ethersproject/bignumber"

const CapsuleTokenInfo = function (props) {
  const { tokenAddress, tokenId, amount, hardCodedAmount } = props
  const node = {
    active: true,
    chainId: MainChainId
  }

  const tokenERC20Contract = useERC20Contract(tokenAddress, false, node)
  const tokenERC1155Contract = useERC1155Contract(tokenAddress, false, node)

  const [decimalAmount, setDecimalAmount] = useState(hardCodedAmount || amount);

  useEffect(async () => {
    const getTokenDecimals = async () => {
      return tokenERC20Contract?.decimals()
    }

    const divideByDecimals = async () => {
      try {
        const tokenDecimals = await getTokenDecimals()
        const amountToDecimals = convertFormattedAmountToDecimalString(amount, tokenDecimals)
        return amountToDecimals
      } catch (e) {
        console.log('problem transforming to decimals', e)
        return null
      }
    }

    const handleAmount = async () => {
      try {
        let total = 0
        if (tokenId) {
          total = amount
        } else {
          total = await divideByDecimals()
        }
        return total ? total : BigNumber.from("0")
      } catch (e) {
        console.error(e)
        return BigNumber.from("0")
      }
    }

    const findAmount = async () => {
      const result = hardCodedAmount || await handleAmount()
      setDecimalAmount(result)
    }

    findAmount()
  }, [])

  return (
    <GetTokenInfo
      message={`${decimalAmount ? decimalAmount.toString() : '0'} `}
      tokenAddress={tokenAddress}
      useNode={node}
      type={tokenId ? 'erc1155' : 'erc20'}
      tokenId={tokenId}
      style={{fontSize: '1.2rem'}}
    />
  )
}

export default CapsuleTokenInfo
