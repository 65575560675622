import React, { useEffect, useState } from "react"
// transactions get state
import { useSelector } from 'react-redux'

// material ui components:
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import OutlinedInput from "@material-ui/core/OutlinedInput";
import Tooltip from "@material-ui/core/Tooltip";

// icons
import { FaCrown, FaMask } from "react-icons/fa";
import HelpIcon from '@material-ui/icons/Help';

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

// collection getting imports
import {
  MainChainId,
  OurPublicCapsuleNFTAddress,
  getLibraryDirect,
  getGeneralCapsuleNFTContractDirect,
  shortenAddress
} from 'utils'

import { useActiveWeb3React } from 'hooks/useActiveWeb3React.js'

// core components
import styles from "assets/jss/material-kit-react/components/nftMenuItem.js";

const useStyles = makeStyles(styles);

const useOutlinedInputStyles = makeStyles(theme => ({
  root: {
    "& $notchedOutline": {
      // #551384,
      borderColor: "#6f40ff"
    },
    "&:hover $notchedOutline": {
      borderColor: "#6f40ff"
    },
    "&$focused $notchedOutline": {
      borderColor: "#6f40ff"
    }
  },
  focused: {},
  notchedOutline: {}
}));

export default function FormattedNFTMenuItem(props) {
  const {
    collectionArray,
    handleChange,
    selectedCollection,
    mintTab
  } = props

  const classes = useStyles();
  const outlinedInputClasses = useOutlinedInputStyles();

  let { chainId, account, library } = useActiveWeb3React()

  // current test for loading transactions
  const transactionSelector = useSelector(state => state.transactions)

  const [txEx, setTxEx] = useState(transactionSelector?.[chainId]); // set when tx executed

  useEffect(() => {
    const obtainedState = transactionSelector?.[chainId]

    if (obtainedState) {
      if (Object.keys(obtainedState).length > 0) {
        for (const tx in obtainedState) {
          if (obtainedState[tx].type ===
            'createCollection') {
              setTxEx(transactionSelector?.[chainId])
            }
        }
      }
    }
  }, [transactionSelector])

  // connect straight to node, not to injected
  const useNode = {
    active: true,
    chainId: MainChainId
  }

  const [nftMenu, setNftMenu] = useState(null)

  useEffect(async () => {
    if (collectionArray.length < 1) {
      return
    }

    const createdNFTMenu = async () => {
      try {
        // directly access contract, w/o hooks
        if (!library) {
          library = getLibraryDirect(useNode)
        }

        // create list of NFTs (collections)
        const nftList = await Promise.all(collectionArray.map(async function (nftAddress, _){
          const capsuleNFTContract = getGeneralCapsuleNFTContractDirect(
            nftAddress,
            useNode.chainId,
            library
          )

          const getNFTName = async () => {
            try {
              return capsuleNFTContract?.name()
            } catch (e) {
              console.log(e, 'issue getting capsule collection NFT Name')
              return '?'
            }
          }

          const getNFTSymbol = async () => {
            return capsuleNFTContract?.symbol()
          }

          const getCollectionPrivacy = async () => {
            return capsuleNFTContract?.isCollectionPrivate()
          }

          const isUserCollectionOwner = async (user) => {
            return await capsuleNFTContract?.owner() === user
          }

          const getUserCollectionBalanceOf = async (user) => {
            return capsuleNFTContract?.balanceOf(user)
          }

          const createNFTMenuString = async () => {
            const isPrivate = await getCollectionPrivacy()
            let isUserOwned = false

            if (account) {
              isUserOwned = await isUserCollectionOwner(account)
            }

            if (isPrivate && !isUserOwned) {
              // if in the mint tab even if you own an NFT in the collection
              // you still can't mint to it
              if (mintTab) {
                return null
              }

              // but you should see the collection in the My Capsules tab
              const userOwnsAnyInCollection = await getUserCollectionBalanceOf(account)
              if (!userOwnsAnyInCollection) {
                return null
              }
              if (userOwnsAnyInCollection < 1) {
                return null
              }
            }

            const name = await getNFTName()
            const symbol = await getNFTSymbol()

            const badWordRegex = new RegExp('(n|i|1){1,32}((g{2,32}|q){1,32}|[gq6]{2,32})[e3r]{1,32}')

            if (!nftAddress || !name || !symbol || badWordRegex.test(name) || badWordRegex.test(symbol)) {
              return {
                owned: false,
                private: false,
                content: `?, (?) - ${nftAddress}`
              }
            }

            const baseString = `${name}, (${symbol}) - ${shortenAddress(nftAddress)}`

            if (nftAddress && name && symbol && isPrivate) {
              if (isUserOwned) {
                return {
                  owned: true,
                  private: true,
                  content: baseString
                }
              }
            }

            return {
              owned: isUserOwned,
              private: isPrivate,
              content: baseString
            }
          }

          const nftString = await createNFTMenuString()

          const maxMenuWidth = window.innerWidth < 1000 ? "300px" : "500px"

          return (
            nftString ?
              <MenuItem
                value={nftAddress}
                key={`${nftString.owned ? '0-' : ''}select-${nftAddress}`}
                style={{maxWidth: maxMenuWidth}}
                name={`${nftString.owned && 'owned'}`}
              >{nftString.owned && <><FaCrown/>&nbsp;</>} {nftString.private && <><FaMask/>&nbsp;</>} {nftString.content}</MenuItem>
            :
              null
          )
        }))

        return await nftList?.sort((a, b) =>
          (a?.props.value === OurPublicCapsuleNFTAddress(MainChainId) || a?.props.name === 'owned')
            ?
              1
            :
              (a?.key > b?.key) ? 1 : -1).reverse()
       } catch (e) {
         console.log(e, 'issue obtaining Capsule Collections menu')
         return (
           <MenuItem
             value={'error'}
             key={'error'}
             style={{maxWidth: "300px"}}
           >Error: Improper Chain Id :(</MenuItem>
         )
       }
    }

    const finalMenu = await createdNFTMenu()

    if (selectedCollection === 'none') {
      if (finalMenu?.key !== 'error') {
        finalMenu.unshift(<MenuItem
          value={'none'}
          key={'none'}
          style={{maxWidth: "300px"}}
        >&nbsp;&nbsp;&nbsp;&nbsp;-- Select a Collection -- </MenuItem>)
      }
    }

    setNftMenu(finalMenu)
  }, [
      collectionArray,
      account,
      txEx ? JSON.stringify(Object.keys(txEx).map(function(obj, i) {
        const tx = txEx[obj]
        if (tx['summary'] === 'Capsule Collection Successfully Deployed') {
          if (tx.hasOwnProperty('receipt')) {
            return 'done'
          }
        }
        return null
      }).filter(element => {
        return element !== null;
      })) : ''
    ]
  )

  return (
      <GridContainer alignItems="center" justifyContent="center">
        <div className={classes.menu}>
          <GridItem
            className={classes.textCenter}
            xs={12}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            <h6>Select a Collection:</h6>
            <Tooltip
              title={<>
                <p>
                  <FaCrown/> - User Owned Collection
                </p>
                <p>
                  <FaMask/> - Private Collection
                </p>
              </>}
              placement="top"
              style={{
                marginLeft: "5px"
              }}
            >
              <HelpIcon/>
            </Tooltip>
          </GridItem>
          <GridItem xs={12}>
            <FormControl sx={{ m: 1.5, minWidth: 80 }}>
              <Select
                id="menu-select-autowidth"
                value={selectedCollection}
                onChange={handleChange}
                autoWidth
                label="Capsule Collection"
                classes={{
                  root: classes.color
                }}
                style={{
                  maxWidth: (window.innerWidth < 1000) ? "300px" : "500px",
                  color: 'white'
                }}
                input={
                  <OutlinedInput
                    name="item"
                    classes={outlinedInputClasses}
                  />
                }
              >
                {nftMenu}
              </Select>
            </FormControl>
          </GridItem>
        </div>
      </GridContainer>
  )
}
