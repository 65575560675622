import { ethers } from "ethers"
import { Contract } from "@ethersproject/contracts"
import { AddressZero } from "@ethersproject/constants";
import { getAddress } from "@ethersproject/address";

import NFTMinter from 'contracts/CapsuleMinter.json';
import CapsuleNFT from 'contracts/Capsule.json'
import CapsuleNFTFactory from 'contracts/CapsuleFactory.json'
import ERC20 from "contracts/ERC20.json"
import ERC721 from "contracts/ERC721.json"

import Big from 'big.js'
import { getNodeUrl } from 'hooks/useContract.js'

// input number amount, and decimal places, and return converted value in string
// i.e. 1, 18 returns 1000000000000000000
export function convertAmountWithDecimalsToString(amount, decimals) {
  return Big(amount).mul(Big(10).pow(decimals)).toString()
}

// convert a response from onchain and turn it into a human readable number
// i.e. 1000000000000000000 18 -> 1
export function convertFormattedAmountToDecimalString(amount, decimals) {
  return Big(amount).div(Big(10).pow(decimals)).toString()
}

// passing in a string number, do big.js multiplication, and return a string
// i.e. 10.1 10 -> 101
export function stringAmountMultiplication(amount, multipliedBy) {
  return Big(amount).mul(Big(multipliedBy)).toString()
}

// returns the checksummed address if the address is valid, otherwise returns false
export function isAddress(address) {
  try {
    return getAddress(address)
  } catch {
    return false
  }
}

// parse amount to ether amount with ethers
export function parseEther(
  input
) {
  return ethers.utils.parseEther(input)
}

// account is not optional
export function getSigner(
  library,
  account
) {
  return library.getSigner(account).connectUnchecked()
}

// account is optional
export function getProviderOrSigner(
  library,
  account
) {
  return account ? getSigner(library, account) : library
}

export function getContract(address, ABI, library, account) {
  if (!isAddress(address) || address === AddressZero) {
    throw Error(`Invalid 'address' parameter '${address}'.`)
  }

  return new Contract(address, ABI, getProviderOrSigner(library, account))
}

// return the library direct to the attached node
export function getLibraryDirect(useNode) {
  if (useNode.active) {
    const chainId = useNode.chainId
    const url = getNodeUrl(chainId)

    if (url) {
      return new ethers.providers.JsonRpcProvider(url, chainId)
    }
  }

  return null
}

export function getContractDirect(address, abi, library) {
  if (!address || !abi || !library || !isAddress(address)) {
    return null
  }
  return new Contract(
    address,
    abi,
    library
  )
}

export function getERC20ContractDirect(address, chainId, library) {
  return getContractDirect(address,
    ERC20.networks[chainId] ?
      ERC20.networks[chainId].abi :
      undefined,
    library
  )
}

export function getERC721ContractDirect(address, chainId, library) {
  return getContractDirect(address,
    ERC721.networks[chainId] ?
      ERC721.networks[chainId].abi :
      undefined,
    library
  )
}

export function getGeneralCapsuleNFTContractDirect(address, chainId, library) {
  return getContractDirect(address,
    CapsuleNFT.networks[chainId] ?
      CapsuleNFT.networks[chainId].abi :
      undefined,
    library
  )
}

export function shortenAddress(address) {
  return address ?
      `${address.substring(0, 6)}...${address.substring(address.length - 4, address.length)}`
    :
      null
}

export function getEtherscanLinkWithHash(hash) {
  return `https://${(MainChainId === 1 ? '' : `${ChainIdToName(MainChainId)}.`)}etherscan.io/tx/${hash}`
}

export const MaxUInt256 = '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff'
export const NFTMinterAddress = function (chainId) {
  return (NFTMinter.networks[chainId] ?
    NFTMinter.networks[chainId].address :
    null)
}
export const CapsuleNFTFactoryAddress = function (chainId) {
  return (CapsuleNFTFactory.networks[chainId] ?
    CapsuleNFTFactory.networks[chainId].address :
    null)
}

// returns our PUBLIC capsule nft address
// (needs to be set manually - this will be the capsuleNFTFactory value of [1])
export const OurPublicCapsuleNFTAddress = function (chainId) {
  switch(chainId) {
    case 1:
      return '0x1a0a69a267b3e72d22deb970b2cc6296ac31a80c'
    case 3:
      return null
    case 4:
      return '0xeCB140c47Bf6565C994A101C47376471dFCf5222'
    default:
      return null
  }
}

// returns our PRIVATE capsule nft address
// (needs to be set manually - this will be the capsuleNFTFactory value of [0])
export const OurPrivateCapsuleNFTAddress = function (chainId) {
  switch(chainId) {
    case 1:
      return '0xece3053e1e7c4dd365975e8ab4db7d1b238e30b6'
    case 3:
      return null
    case 4:
      return '0x27b879232f5a260a38EDdB4d344AB7463972A6A1'
    default:
      return null
  }
}
export const OpenSeaCapsuleUrl = function (chainId, address) {
  switch(chainId) {
    case 1:
      return `https://opensea.io/assets/ethereum/${address}`
    case 3:
      return null
    case 4:
      return `https://testnets.opensea.io/assets/${address}`
    default:
      return null
  }
}

export const OpenSeaCollectionUrl = function (chainId) {
  switch(chainId) {
    case 1:
      return 'https://opensea.io/collection/public-capsule'
    case 3:
      return null
    case 4:
      return 'https://testnets.opensea.io/collection/capsulenft'
    default:
      return null
  }
}

export const ChainIdToName = function (chainId) {
  switch(chainId) {
    case 1:
      return 'mainnet'
    case 3:
      return 'ropsten'
    case 4:
      return 'rinkeby'
    default:
      return ''
  }
}

// mainnet: 1
// ropsten: 3
// rinkeby: 4
export const MainChainId = parseInt(process.env.REACT_APP_MAIN_CHAIN_ID)
export const CapsuleNFTMintTax = "0.001"
export const CapsuleNFTDeployTax = "0.025"
export const ZeroAddress = "0x0000000000000000000000000000000000000000"

export const IPFSEndpoint = process.env.REACT_APP_IPFS_PROJECT_ENDPOINT
export const IPFSEndpointId = process.env.REACT_APP_IPFS_PROJECT_ID
export const IPFSEndpointSecret = process.env.REACT_APP_IPFS_PROJECT_SECRET
export const IPFSNodeURL = process.env.REACT_APP_IPFS_NODE_URL
export const IPFSGatewayURL = process.env.REACT_APP_IPFS_GATEWAY_URL
export const NFTStorageApiKey = process.env.REACT_APP_NFT_STORAGE_API_KEY

export const ExternalNodeMainnetUrl = process.env.REACT_APP_EXTERNAL_NODE_MAINNET_URL
export const ExternalNodeRopstenUrl = process.env.REACT_APP_EXTERNAL_NODE_ROPSTEN_URL
export const ExternalNodeRinkebyUrl = process.env.REACT_APP_EXTERNAL_NODE_RINKEBY_URL
