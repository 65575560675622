import { useState, useEffect } from 'react'
import { useWeb3React } from '@web3-react/core'

// web3 errors
import { UnsupportedChainIdError } from '@web3-react/core'
import {
  NoEthereumProviderError,
  UserRejectedRequestError as UserRejectedRequestErrorInjected
} from '@web3-react/injected-connector'

import { BigNumber } from "@ethersproject/bignumber"

// history dropdown
import HistoryDropdown from "./HistoryDropdown.js"

// copy button for address
import ContentCopyIcon from '@material-ui/icons/FileCopy';

// material
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "components/CustomButtons/Button.js";
// importing of styles
import styles from "assets/jss/material-kit-react/components/headerLinksStyle.js";

// redux user txs
import { useSelector } from 'react-redux'

const useStyles = makeStyles(styles);

export default function UserBalance() {
  const classes = useStyles()
  const { account, active, error, library, chainId } = useWeb3React()

  // current test for loading transactions
  const transactionSelector = useSelector(state => state.transactions)

  const [txEx, setTxEx] = useState(transactionSelector?.[chainId]); // set when tx executed
  useEffect(() => {
    setTxEx(transactionSelector?.[chainId])
  }, [transactionSelector])

  const [balance, setBalance] = useState()
  useEffect((): any => {
    if (!!account && !!library) {
      let stale = false

      library
        .getBalance(account)
        .then((balance) => {
          if (!stale) {
            // attempting to get proper decimals
            const amountToDecimals = balance.div(
              BigNumber.from(10).pow(14)).toNumber() / Math.pow(10, 4)
            setBalance(amountToDecimals)
          }
        })
        .catch(() => {
          if (!stale) {
            setBalance(null)
          }
        })

      return () => {
        stale = true
        setBalance(undefined)
      }
    }
  }, [account, library, chainId, JSON.stringify(transactionSelector), txEx]) // ensures refresh if referential identity of library doesn't change across chainIds

  return (
    <>
      {(active) ? (
        <>
          <Button
            color="transparent"
            className={classes.navLink}
            onClick={() => {navigator.clipboard.writeText(account)}}
          >
            <span
              style={{fontSize: '14px'}}
            >
              {account === null
                ? '-'
                : account
                ? `${account.substring(0, 6)}...${account.substring(account.length - 4)}`
                : ''}
              &nbsp;
              <ContentCopyIcon
                style={{
                  width: "14px",
                  height: "14px"
                }}
              />
            </span>
          </Button>
          <Button
            color="transparent"
            className={classes.navLink}
          >
            <span
              style={{fontSize: '16px'}}
            >
              {balance === null ? 'Error' : balance ? `${balance} ETH` : ''}
            </span>
          </Button>
          <HistoryDropdown/>
        </>
      ) :
      (!active && error) ? (
        <Tooltip
          id="tooltip-bottom"
          title={!!error && <h4 style={{ marginTop: '1rem', marginBottom: '0' }}>{getErrorMessage(error)}</h4>}
          placement="bottom"
          classes={{ tooltip: classes.tooltip }}
        >
          <Button
            color="google"
          >
            Error signing in
          </Button>
        </Tooltip>
      ) : '' }
    </>
  )
}

function getErrorMessage(error) {
  if (error instanceof NoEthereumProviderError) {
    return 'No Ethereum browser extension detected, install MetaMask on desktop or visit from a dApp browser on mobile.'
  } else if (error instanceof UnsupportedChainIdError) {
    return "You're connected to an unsupported network."
  } else if (
    error instanceof UserRejectedRequestErrorInjected
  ) {
    return 'Please authorize this website to access your Ethereum account.'
  } else {
    console.error(error)
    return 'An unknown error occurred. Check the console for more details.'
  }
}
