import React, { useState } from "react"
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import CardBody from "components/Card/CardBody.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

// dropdown
import {
  CapsuleNFTMintTax,
  parseEther,
} from 'utils'

import styles from "assets/jss/material-kit-react/views/componentsSections/tabsStyle.js";

// dropdown
import ERC1155DropdownManager from './TokenDropdown/ERC1155DropdownManager.js'

// nft data card
import NFTDataCard from "components/NFTDataCard"

// import required nft creation info
import { useNFTMinterContract } from 'hooks/useContract.js'
import { useActiveWeb3React } from 'hooks/useActiveWeb3React.js'
import UploadNFTInfo from "components/app/FunctionComponents/UploadNFTInfo"
import { BigNumber } from "@ethersproject/bignumber"

const useStyles = makeStyles(styles);

export default function ERC1155CapsuleTab(props) {
  const classes = useStyles();

  const { collectionAddress } = props
  const { account, library } = useActiveWeb3React()

  // create the capsule through the MintNFT component
  const nftMinterContract = useNFTMinterContract(true, { active: false })

  const [capsuleDataArray, setCapsuleDataArray] = useState({
      data: [
        {
          index: Math.random(),
          id: 0,
          amount: 0,
          address: ""
        }
      ]
    }
  )

  const createCapsule = async (uri) => {
    try {
      if (capsuleDataArray.data.length < 1 || !library) {
        return
      } else {
        const data = capsuleDataArray.data

        const addressArray = []
        const idArray = []
        const amountArray = []

        for (let i = 0; i < data.length; i++) {
          const tokenAddress = data[i].address
          const tokenId = data[i].id
          const tokenAmount = data[i].amount

          addressArray.push(tokenAddress)
          idArray.push(tokenId)
          amountArray.push(tokenAmount)
        }

        // mint multi Capsule with arrays built from data
        return nftMinterContract?.mintMultiERC1155Capsule(
          collectionAddress,
          addressArray,
          idArray,
          amountArray,
          uri,
          account,
          {
            value: parseEther(CapsuleNFTMintTax)
          }
        )
      }
    } catch (e) {
      console.log('problem minting the Capsule NFT', e)
      return null
    }
  }

  const updateCapsuleData = (id, address, amount, nftId) => {
    let capsuleDataArrayTemp = [...capsuleDataArray.data]

    for (let i = 0; i < capsuleDataArrayTemp.length; i++) {
      if (capsuleDataArrayTemp[i].index === id) {
        capsuleDataArrayTemp[i].address = address
        capsuleDataArrayTemp[i].amount = amount
        capsuleDataArrayTemp[i].id = nftId
        setCapsuleDataArray({ data: [...capsuleDataArrayTemp] })
      }
    }
  }

  const addNewRow = function (e) {
    setCapsuleDataArray({
      data: [
        ...capsuleDataArray.data,
        {
          index: Math.random(),
          id: 0,
          amount: 0,
          address: ""
        }
      ]
    })
  }

  const clickOnDelete = function (record) {
    setCapsuleDataArray({
      data: capsuleDataArray.data.filter(r => r !== record)
    })
  }

  return (
    <div className={classes.purpleBorder}>
      <CardBody>
        <div
          className={classes.textCenter}
          style={{
            paddingBottom: "40px"
          }}
        >
          <h3><strong>Mint an ERC-1155 Capsule NFT</strong></h3>
          <h5 className={classes.textCenter}>
            Need help? Consult this tutorial <strong><a href="https://www.youtube.com/watch?v=lyKG2W1XcnI" target="_blank" className={classes.textColor, classes.capsuleLink}>video.
            </a></strong>
          </h5>
          <p>An ERC-1155 Capsule NFT stores any ERC-1155 NFT alongside creation of the Capsule NFT.</p>
        </div>
        <ERC1155DropdownManager
          addRow={addNewRow}
          deleteRow={clickOnDelete.bind(this)}
          data={capsuleDataArray.data}
          updateData={updateCapsuleData}
        />
        <UploadNFTInfo
          nftMintFunction={createCapsule}
          erc1155Data={capsuleDataArray.data}
          type='erc1155-capsule'
          collectionAddress={collectionAddress}
        />
      </CardBody>
    </div>
  )
}
