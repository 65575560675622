import { useEffect, useState } from "react"

// mat ui
import GridContainer from "components/Grid/GridContainer.js";

import {
  MaxUInt256,
  convertFormattedAmountToDecimalString
} from 'utils'
import { useActiveWeb3React } from 'hooks/useActiveWeb3React.js'

// approve tokens, if approval < 0
import ApproveXERC1155Tokens from 'components/app/FunctionComponents/ApproveXERC1155Tokens.js'
import { BigNumber } from "@ethersproject/bignumber"

const GetERC1155TokenAllowance = function (props) {
  const {
    tokenAddress
  } = props
  const { account } = useActiveWeb3React()

  return (
    <>
      {
        !account && 'Sign in, and select a token'
      }
      {
        account &&
          <>
            <GridContainer alignItems="center" justifyContent="center">
              <ApproveXERC1155Tokens
                tokenAddress={tokenAddress}
              />
            </GridContainer>
          </>
      }
    </>
  )
}

export default GetERC1155TokenAllowance
