import React, { useEffect, useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import GridItem from "components/Grid/GridItem.js";

import styles from "assets/jss/material-kit-react/views/componentsSections/tabsStyle.js";

// import contract methods
import GetUserOwnedNFTs from 'components/app/FunctionComponents/GetUserOwnedNFTs.js'

// get select menu item
import FormattedNFTMenuItem from 'components/app/FunctionComponents/FormattedNFTMenuItem.js'

// redux user txs
import { useSelector } from 'react-redux'

import { useActiveWeb3React } from 'hooks/useActiveWeb3React.js'
import { useCapsuleNFTFactoryContract } from 'hooks/useContract.js'

const useStyles = makeStyles(styles);

export default function MyCapsulesTab() {
  const classes = useStyles();

  const { chainId, library } = useActiveWeb3React()

  const [selectedCapsuleCollection, setSelectedCapsuleCollection] = useState('none')
  const [capsuleCollectionArray, setCapsuleCollectionArray] = useState([])

  // current test for loading transactions
  const transactionSelector = useSelector(state => state.transactions)

  const [txEx, setTxEx] = useState(transactionSelector?.[chainId]); // set when tx executed
  useEffect(() => {
    setTxEx(transactionSelector?.[chainId])
  }, [transactionSelector])

  const handleChange = (event) => {
    setSelectedCapsuleCollection(event.target.value)
  }

  const capsuleNFTFactoryContract = useCapsuleNFTFactoryContract(false, { active: false })

  useEffect(() => {
    const getAllCapsuleNFTAddresses = async () => {
      return capsuleNFTFactoryContract?.getAllCapsuleCollections()
    }

    const getFactoryCapsuleNFTs = async () => {
      try {
        const capsuleArray = await getAllCapsuleNFTAddresses()
        return capsuleArray
      } catch (e) {
        console.log('error getting cap collection addresses', e)
        return e
      }
    }

    const createCollectionSelect = async () => {
      const collectionArray = await getFactoryCapsuleNFTs()
      setCapsuleCollectionArray(collectionArray || [])
      // may want to auto-select to just the capsuleNFT public token, if unreachable
    }

    createCollectionSelect()
  }, [
    chainId,
    txEx ? JSON.stringify(Object.keys(txEx).map(function(obj, i) {
      const tx = txEx[obj]
      if (tx['summary'] === 'Capsule Collection Successfully Deployed') {
        if (tx.hasOwnProperty('receipt')) {
          return 'done'
        }
      }
      return null
    }).filter(element => {
      return element !== null;
    })) : ''
  ])

  return (
    <div>
      <CardBody>
      <GridItem xs={12} sm={12}>
        {
          (chainId && library)
            ?
              <div
                style={{
                  marginTop: "-15px"
                }}
              >
                <FormattedNFTMenuItem
                  collectionArray={capsuleCollectionArray}
                  selectedCollection={selectedCapsuleCollection}
                  handleChange={handleChange}
                />
              </div>
            :
              ''
        }
      </GridItem>
      <GetUserOwnedNFTs
        collectionAddress={selectedCapsuleCollection}
      />
      </CardBody>
      <CardFooter className={classes.cardFooter}>
      </CardFooter>
    </div>
  );
}
