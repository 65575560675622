import { createReducer, nanoid } from "@reduxjs/toolkit"
import {
  addPopup,
  removePopup,
  updateBlockNumber
} from "./actions"

const initialState = {
  blockNumber: {},
  popupList: [],
  nftsMinted: 0,
  openModal: null
}

export default createReducer(initialState, (builder) =>
  builder
    .addCase(updateBlockNumber, (state, action) => {
      const { chainId, blockNumber, nftsMinted } = action.payload
      state.nftsMinted = nftsMinted
      if (typeof state.blockNumber[chainId] !== "number") {
        state.blockNumber[chainId] = blockNumber
      } else {
        state.blockNumber[chainId] = Math.max(
          blockNumber,
          state.blockNumber[chainId]
        )
      }
    })
    /*
    .addCase(setOpenModal, (state, action) => {
      state.openModal = action.payload
    })*/
    .addCase(
      addPopup,
      (state, { payload: { content, key, removeAfterMs = 15000 } }) => {
        state.popupList = (
          key
            ? state.popupList.filter((popup) => popup.key !== key)
            : state.popupList
        ).concat([
          {
            key: key || nanoid(),
            show: true,
            content,
            removeAfterMs,
          },
        ])
      }
    )
    .addCase(removePopup, (state, { payload: { key } }) => {
      state.popupList.forEach((p) => {
        if (p.key === key) {
          p.show = false
        }
      })
    })
)
