import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";

import styles from "assets/jss/material-kit-react/views/componentsSections/tabsStyle.js";

// for nft base mint function
import { useNFTMinterContract } from 'hooks/useContract.js'

// import web3 react to get user account
import { useActiveWeb3React } from 'hooks/useActiveWeb3React.js'

// get tax from util.js

import {
  CapsuleNFTMintTax,
  parseEther
} from 'utils'

// import nft creation info
import UploadNFTInfo from "components/app/FunctionComponents/UploadNFTInfo"

const useStyles = makeStyles(styles);

export default function BaseNFTTab(props) {
  const classes = useStyles()

  const { collectionAddress } = props

  const { account } = useActiveWeb3React()

  const nftMinterContract = useNFTMinterContract(true, { active: false })

  const createNFT = async (ipfsLink) => {
    return nftMinterContract?.mintSimpleCapsule(
      collectionAddress,
      ipfsLink,
      account,
      {
        value: parseEther(CapsuleNFTMintTax)
      }
    )
  }

  return (
    <div className={classes.purpleBorder}>
      <CardBody>
        <h3 className={classes.textCenter}><strong>Mint a Simple Capsule NFT</strong></h3>
        <h5 className={classes.textCenter}>
          Need help? Consult this tutorial <strong><a href="https://www.youtube.com/watch?v=Mmf9Nklcg6A" target="_blank" className={classes.textColor, classes.capsuleLink}>video.
          </a></strong>
        </h5>
        <p className={classes.textCenter}>
          A Simple Capsule NFT does not store any tokens alongside it. It functions
          exactly like any basic NFT.
        </p>
        <UploadNFTInfo
          nftMintFunction={createNFT}
          type='base'
          collectionAddress={collectionAddress}
        />
      </CardBody>
      <CardFooter className={classes.cardFooter}>
      </CardFooter>
    </div>
  );
}
