//import { MainChainId } from 'utils'

export const capsuleTokenList = function (chainId) {
  // glance at: https://github.com/sushiswap/default-token-list/blob/master/tokens/mainnet.json

  return [
    {
      "chainId": 1,
      "address": "0x111111111117dC0aa78b770fA6A738034120C302",
      "name": "1inch",
      "symbol": "1INCH",
      "decimals": 18,
      "logoURI": "https://assets.coingecko.com/coins/images/13469/thumb/1inch-token.png?1608803028"
    },
    {
      "chainId": 1,
      "address": "0x7Fc66500c84A76Ad7e9c93437bFc5Ac33E2DDaE9",
      "name": "Aave",
      "symbol": "AAVE",
      "decimals": 18,
      "logoURI": "https://assets.coingecko.com/coins/images/12645/thumb/AAVE.png?1601374110"
    },
    {
      "chainId": 1,
      "address": "0xEd04915c23f00A313a544955524EB7DBD823143d",
      "name": "Alchemy Pay",
      "symbol": "ACH",
      "decimals": 8,
      "logoURI": "https://assets.coingecko.com/coins/images/12390/thumb/ACH_%281%29.png?1599691266"
    },
    {
      "chainId": 1,
      "address": "0x626E8036dEB333b408Be468F951bdB42433cBF18",
      "name": "AIOZ Network",
      "symbol": "AIOZ",
      "decimals": 18,
      "logoURI": "https://assets.coingecko.com/coins/images/14631/thumb/aioz_logo.png?1617413126"
    },
    {
      "chainId": 1,
      "address": "0xAC51066d7bEC65Dc4589368da368b212745d63E8",
      "name": "My Neighbor Alice",
      "symbol": "ALICE",
      "decimals": 6,
      "logoURI": "https://assets.coingecko.com/coins/images/14375/thumb/alice_logo.jpg?1615782968"
    },
    {
      "chainId": 1,
      "address": "0xfF20817765cB7f73d4bde2e66e067E58D11095C2",
      "name": "Amp",
      "symbol": "AMP",
      "decimals": 18,
      "logoURI": "https://assets.coingecko.com/coins/images/12409/thumb/amp-200x200.png?1599625397"
    },
    {
      "chainId": 1,
      "address": "0x8290333ceF9e6D528dD5618Fb97a76f268f3EDD4",
      "name": "Ankr",
      "symbol": "ANKR",
      "decimals": 18,
      "logoURI": "https://assets.coingecko.com/coins/images/4324/thumb/U85xTl2.png?1608111978"
    },
    {
      "name": "Aragon Network Token",
      "address": "0x960b236A07cf122663c4303350609A66A7B288C0",
      "symbol": "ANT",
      "decimals": 18,
      "chainId": 1,
      "logoURI": "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x960b236A07cf122663c4303350609A66A7B288C0/logo.png"
    },
    {
      "chainId": 1,
      "address": "0x4d224452801ACEd8B2F0aebE155379bb5D594381",
      "name": "ApeCoin",
      "symbol": "APE",
      "decimals": 18,
      "logoURI": "https://assets.coingecko.com/coins/images/24383/small/apecoin.jpg?1647476455"
    },
    {
      "chainId": 1,
      "address": "0xBA50933C268F567BDC86E1aC131BE072C6B0b71a",
      "name": "ARPA Chain",
      "symbol": "ARPA",
      "decimals": 18,
      "logoURI": "https://assets.coingecko.com/coins/images/8506/thumb/9u0a23XY_400x400.jpg?1559027357"
    },
    {
      "chainId": 1,
      "address": "0x18aAA7115705e8be94bfFEBDE57Af9BFc265B998",
      "name": "Audius",
      "symbol": "AUDIO",
      "decimals": 18,
      "logoURI": "https://assets.coingecko.com/coins/images/12913/thumb/AudiusCoinLogo_2x.png?1603425727"
    },
    {
      "chainId": 1,
      "address": "0xBB0E17EF65F82Ab018d8EDd776e8DD940327B28b",
      "name": "Axie Infinity",
      "symbol": "AXS",
      "decimals": 18,
      "logoURI": "https://assets.coingecko.com/coins/images/13029/thumb/axie_infinity_logo.png?1604471082"
    },
    {
      "chainId": 1,
      "address": "0x3472A5A71965499acd81997a54BBA8D852C6E53d",
      "name": "Badger DAO",
      "symbol": "BADGER",
      "decimals": 18,
      "logoURI": "https://assets.coingecko.com/coins/images/13287/thumb/badger_dao_logo.jpg?1607054976"
    },
    {
      "name": "Balancer",
      "address": "0xba100000625a3754423978a60c9317c58a424e3D",
      "symbol": "BAL",
      "decimals": 18,
      "chainId": 1,
      "logoURI": "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xba100000625a3754423978a60c9317c58a424e3D/logo.png"
    },
    {
      "chainId": 1,
      "address": "0xBA11D00c5f74255f56a5E366F4F77f5A186d7f55",
      "name": "Band Protocol",
      "symbol": "BAND",
      "decimals": 18,
      "logoURI": "https://assets.coingecko.com/coins/images/9545/thumb/band-protocol.png?1568730326"
    },
    {
      "chainId": 1,
      "address": "0x0D8775F648430679A709E98d2b0Cb6250d2887EF",
      "name": "Basic Attention Token",
      "symbol": "BAT",
      "decimals": 18,
      "logoURI": "https://assets.coingecko.com/coins/images/677/thumb/basic-attention-token.png?1547034427"
    },
    {
      "chainId": 1,
      "address": "0x5732046A883704404F284Ce41FfADd5b007FD668",
      "name": "Bluzelle",
      "symbol": "BLZ",
      "decimals": 18,
      "logoURI": "https://assets.coingecko.com/coins/images/2848/thumb/ColorIcon_3x.png?1622516510"
    },
    {
      "name": "Bancor Network Token",
      "address": "0x1F573D6Fb3F13d689FF844B4cE37794d79a7FF1C",
      "symbol": "BNT",
      "decimals": 18,
      "chainId": 1,
      "logoURI": "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x1F573D6Fb3F13d689FF844B4cE37794d79a7FF1C/logo.png"
    },
    {
      "chainId": 1,
      "address": "0x0391D2021f89DC339F60Fff84546EA23E337750f",
      "name": "BarnBridge",
      "symbol": "BOND",
      "decimals": 18,
      "logoURI": "https://assets.coingecko.com/coins/images/12811/thumb/barnbridge.jpg?1602728853"
    },
    {
      "chainId": 1,
      "address": "0x3506424F91fD33084466F402d5D97f05F8e3b4AF",
      "name": "Chiliz",
      "symbol": "CHZ",
      "decimals": 18,
      "logoURI": "https://assets.coingecko.com/coins/images/8834/thumb/Chiliz.png?1561970540"
    },
    {
      "chainId": 1,
      "address": "0x80C62FE4487E1351b47Ba49809EBD60ED085bf52",
      "name": "Clover Finance",
      "symbol": "CLV",
      "decimals": 18,
      "logoURI": "https://assets.coingecko.com/coins/images/15278/thumb/clover.png?1645084454"
    },
    {
      "name": "Compound",
      "address": "0xc00e94Cb662C3520282E6f5717214004A7f26888",
      "symbol": "COMP",
      "decimals": 18,
      "chainId": 1,
      "logoURI": "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xc00e94Cb662C3520282E6f5717214004A7f26888/logo.png"
    },
    {
      "chainId": 1,
      "address": "0xDDB3422497E61e13543BeA06989C0789117555c5",
      "name": "COTI",
      "symbol": "COTI",
      "decimals": 18,
      "logoURI": "https://assets.coingecko.com/coins/images/2962/thumb/Coti.png?1559653863"
    },
    {
      "chainId": 1,
      "address": "0x3D658390460295FB963f54dC0899cfb1c30776Df",
      "name": "Circuits of Value",
      "symbol": "COVAL",
      "decimals": 8,
      "logoURI": "https://assets.coingecko.com/coins/images/588/thumb/coval-logo.png?1599493950"
    },
    {
      "chainId": 1,
      "address": "0xA0b73E1Ff0B80914AB6fe0444E65848C4C34450b",
      "name": "Cronos",
      "symbol": "CRO",
      "decimals": 8,
      "logoURI": "https://assets.coingecko.com/coins/images/7310/thumb/oCw2s3GI_400x400.jpeg?1645172042"
    },
    {
      "chainId": 1,
      "address": "0x08389495D7456E1951ddF7c3a1314A4bfb646d8B",
      "name": "Crypterium",
      "symbol": "CRPT",
      "decimals": 18,
      "logoURI": "https://assets.coingecko.com/coins/images/1901/thumb/crypt.png?1547036205"
    },
    {
      "name": "Curve DAO Token",
      "address": "0xD533a949740bb3306d119CC777fa900bA034cd52",
      "symbol": "CRV",
      "decimals": 18,
      "chainId": 1,
      "logoURI": "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xD533a949740bb3306d119CC777fa900bA034cd52/logo.png"
    },
    {
      "chainId": 1,
      "address": "0x491604c0FDF08347Dd1fa4Ee062a822A5DD06B5D",
      "name": "Cartesi",
      "symbol": "CTSI",
      "decimals": 18,
      "logoURI": "https://assets.coingecko.com/coins/images/11038/thumb/cartesi.png?1592288021"
    },
    {
      "chainId": 1,
      "address": "0x41e5560054824eA6B0732E656E3Ad64E20e94E45",
      "name": "Civic",
      "symbol": "CVC",
      "decimals": 8,
      "logoURI": "https://assets.coingecko.com/coins/images/788/thumb/civic.png?1547034556"
    },
    {
      "chainId": 1,
      "address": "0x4e3FBD56CD56c3e72c1403e103b45Db9da5B9D2B",
      "name": "Convex Finance",
      "symbol": "CVX",
      "decimals": 18,
      "logoURI": "https://assets.coingecko.com/coins/images/15585/thumb/convex.png?1621256328"
    },
    {
      "name": "Dai Stablecoin",
      "address": "0x6B175474E89094C44Da98b954EedeAC495271d0F",
      "symbol": "DAI",
      "decimals": 18,
      "chainId": 1,
      "logoURI": "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x6B175474E89094C44Da98b954EedeAC495271d0F/logo.png"
    },
    {
      "chainId": 1,
      "address": "0x3A880652F47bFaa771908C07Dd8673A787dAEd3A",
      "name": "DerivaDAO",
      "symbol": "DDX",
      "decimals": 18,
      "logoURI": "https://assets.coingecko.com/coins/images/13453/thumb/ddx_logo.png?1608741641"
    },
    {
      "chainId": 1,
      "address": "0x84cA8bc7997272c7CfB4D0Cd3D55cd942B3c9419",
      "name": "DIA",
      "symbol": "DIA",
      "decimals": 18,
      "logoURI": "https://assets.coingecko.com/coins/images/11955/thumb/image.png?1646041751"
    },
    {
      "chainId": 1,
      "address": "0x0AbdAce70D3790235af448C88547603b945604ea",
      "name": "district0x",
      "symbol": "DNT",
      "decimals": 18,
      "logoURI": "https://assets.coingecko.com/coins/images/849/thumb/district0x.png?1547223762"
    },
    {
      "chainId": 1,
      "address": "0x92D6C1e31e14520e676a687F0a93788B716BEff5",
      "name": "dYdX",
      "symbol": "DYDX",
      "decimals": 18,
      "logoURI": "https://assets.coingecko.com/coins/images/17500/thumb/hjnIm9bV.jpg?1628009360"
    },
    {
      "chainId": 1,
      "address": "0x761D38e5ddf6ccf6Cf7c55759d5210750B5D60F3",
      "name": "Dogelon Mars",
      "symbol": "ELON",
      "decimals": 18,
      "logoURI": "https://assets.coingecko.com/coins/images/14962/thumb/6GxcPRo3_400x400.jpg?1619157413"
    },
    {
      "chainId": 1,
      "address": "0xF629cBd94d3791C9250152BD8dfBDF380E2a3B9c",
      "name": "Enjin Coin",
      "symbol": "ENJ",
      "decimals": 18,
      "logoURI": "https://assets.coingecko.com/coins/images/1102/thumb/enjin-coin-logo.png?1547035078"
    },
    {
      "chainId": 1,
      "address": "0xC18360217D8F7Ab5e7c516566761Ea12Ce7F9D72",
      "name": "Ethereum Name Service",
      "symbol": "ENS",
      "decimals": 18,
      "logoURI": "https://assets.coingecko.com/coins/images/19785/thumb/acatxTm8_400x400.jpg?1635850140"
    },
    {
      "chainId": 1,
      "address": "0xBBc2AE13b23d715c30720F079fcd9B4a74093505",
      "name": "Ethernity Chain",
      "symbol": "ERN",
      "decimals": 18,
      "logoURI": "https://assets.coingecko.com/coins/images/14238/thumb/LOGO_HIGH_QUALITY.png?1647831402"
    },
    {
      "chainId": 1,
      "address": "0xa0246c9032bC3A600820415aE600c6388619A14D",
      "name": "Harvest Finance",
      "symbol": "FARM",
      "decimals": 18,
      "logoURI": "https://assets.coingecko.com/coins/images/12304/thumb/Harvest.png?1613016180"
    },
    {
      "chainId": 1,
      "address": "0xaea46A60368A7bD060eec7DF8CBa43b7EF41Ad85",
      "name": "Fetch ai",
      "symbol": "FET",
      "decimals": 18,
      "logoURI": "https://assets.coingecko.com/coins/images/5681/thumb/Fetch.jpg?1572098136"
    },
    {
      "chainId": 1,
      "address": "0x77FbA179C79De5B7653F68b5039Af940AdA60ce0",
      "name": "Ampleforth Governance Token",
      "symbol": "FORTH",
      "decimals": 18,
      "logoURI": "https://assets.coingecko.com/coins/images/14917/thumb/photo_2021-04-22_00.00.03.jpeg?1619020835"
    },
    {
      "chainId": 1,
      "address": "0xc770EEfAd204B5180dF6a14Ee197D99d808ee52d",
      "name": "ShapeShift FOX Token",
      "symbol": "FOX",
      "decimals": 18,
      "logoURI": "https://assets.coingecko.com/coins/images/9988/thumb/FOX.png?1574330622"
    },
    {
      "chainId": 1,
      "address": "0x4E15361FD6b4BB609Fa63C81A2be19d873717870",
      "name": "Fantom",
      "symbol": "FTM",
      "decimals": 18,
      "logoURI": "https://assets.coingecko.com/coins/images/4001/thumb/Fantom.png?1558015016"
    },
    {
      "chainId": 1,
      "address": "0x8c15Ef5b4B21951d50E53E4fbdA8298FFAD25057",
      "name": "Function X",
      "symbol": "FX",
      "decimals": 18,
      "logoURI": "https://assets.coingecko.com/coins/images/8186/thumb/47271330_590071468072434_707260356350705664_n.jpg?1556096683"
    },
    {
      "chainId": 1,
      "address": "0x3432B6A60D23Ca0dFCa7761B7ab56459D9C964D0",
      "name": "Frax Share",
      "symbol": "FXS",
      "decimals": 18,
      "logoURI": "https://assets.coingecko.com/coins/images/13423/thumb/frax_share.png?1608478989"
    },
    {
      "chainId": 1,
      "address": "0x15D4c048F83bd7e37d49eA4C83a07267Ec4203dA",
      "name": "Gala",
      "symbol": "GALA",
      "decimals": 8,
      "logoURI": "https://assets.coingecko.com/coins/images/12493/thumb/GALA-COINGECKO.png?1600233435"
    },
    {
      "name": "Gnosis Token",
      "address": "0x6810e776880C02933D47DB1b9fc05908e5386b96",
      "symbol": "GNO",
      "decimals": 18,
      "chainId": 1,
      "logoURI": "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x6810e776880C02933D47DB1b9fc05908e5386b96/logo.png"
    },
    {
      "chainId": 1,
      "address": "0xc944E90C64B2c07662A292be6244BDf05Cda44a7",
      "name": "The Graph",
      "symbol": "GRT",
      "decimals": 18,
      "logoURI": "https://assets.coingecko.com/coins/images/13397/thumb/Graph_Token.png?1608145566"
    },
    {
      "chainId": 1,
      "address": "0xDe30da39c46104798bB5aA3fe8B9e0e1F348163F",
      "name": "Gitcoin",
      "symbol": "GTC",
      "decimals": 18,
      "logoURI": "https://assets.coingecko.com/coins/images/15810/thumb/gitcoin.png?1621992929"
    },
    {
      "chainId": 1,
      "address": "0x056Fd409E1d7A124BD7017459dFEa2F387b6d5Cd",
      "name": "Gemini Dollar",
      "symbol": "GUSD",
      "decimals": 2,
      "logoURI": "https://assets.coingecko.com/coins/images/5992/thumb/gemini-dollar-gusd.png?1536745278"
    },
    {
      "chainId": 1,
      "address": "0xC08512927D12348F6620a698105e1BAac6EcD911",
      "name": "GYEN",
      "symbol": "GYEN",
      "decimals": 6,
      "logoURI": "https://assets.coingecko.com/coins/images/14191/thumb/icon_gyen_200_200.png?1614843343"
    },
    {
      "chainId": 1,
      "address": "0x71Ab77b7dbB4fa7e017BC15090b2163221420282",
      "name": "Highstreet",
      "symbol": "HIGH",
      "decimals": 18,
      "logoURI": "https://assets.coingecko.com/coins/images/18973/thumb/logosq200200Coingecko.png?1634090470"
    },
    {
      "chainId": 1,
      "address": "0xB705268213D593B8FD88d3FDEFF93AFF5CbDcfAE",
      "name": "IDEX",
      "symbol": "IDEX",
      "decimals": 18,
      "logoURI": "https://assets.coingecko.com/coins/images/2565/thumb/logomark-purple-286x286.png?1638362736"
    },
    {
      "chainId": 1,
      "address": "0xe28b3B32B6c345A34Ff64674606124Dd5Aceca30",
      "name": "Injective",
      "symbol": "INJ",
      "decimals": 18,
      "logoURI": "https://assets.coingecko.com/coins/images/12882/thumb/Secondary_Symbol.png?1628233237"
    },
    {
      "chainId": 1,
      "address": "0x6fB3e0A217407EFFf7Ca062D46c26E5d60a14d69",
      "name": "IoTeX",
      "symbol": "IOTX",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/2777.png"
    },
    {
      "chainId": 1,
      "address": "0x7420B4b9a0110cdC71fB720908340C03F9Bc03EC",
      "name": "JasmyCoin",
      "symbol": "JASMY",
      "decimals": 18,
      "logoURI": "https://assets.coingecko.com/coins/images/13876/thumb/JASMY200x200.jpg?1612473259"
    },
    {
      "chainId": 1,
      "address": "0x85Eee30c52B0b379b046Fb0F85F4f3Dc3009aFEC",
      "name": "Keep Network",
      "symbol": "KEEP",
      "decimals": 18,
      "logoURI": "https://assets.coingecko.com/coins/images/3373/thumb/IuNzUb5b_400x400.jpg?1589526336"
    },
    {
      "name": "Kyber Network Crystal",
      "address": "0xdd974D5C2e2928deA5F71b9825b8b646686BD200",
      "symbol": "KNC",
      "decimals": 18,
      "chainId": 1,
      "logoURI": "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xdd974D5C2e2928deA5F71b9825b8b646686BD200/logo.png"
    },
    {
      "chainId": 1,
      "address": "0x1cEB5cB57C4D4E2b2433641b95Dd330A33185A44",
      "name": "Keep3rV1",
      "symbol": "KP3R",
      "decimals": 18,
      "logoURI": "https://assets.coingecko.com/coins/images/12966/thumb/kp3r_logo.jpg?1607057458"
    },
    {
      "chainId": 1,
      "address": "0x464eBE77c293E473B48cFe96dDCf88fcF7bFDAC0",
      "name": "KRYLL",
      "symbol": "KRL",
      "decimals": 18,
      "logoURI": "https://assets.coingecko.com/coins/images/2807/thumb/krl.png?1547036979"
    },
    {
      "chainId": 1,
      "address": "0x037A54AaB062628C9Bbae1FDB1583c195585fe41",
      "name": "LCX",
      "symbol": "LCX",
      "decimals": 18,
      "logoURI": "https://assets.coingecko.com/coins/images/9985/thumb/zRPSu_0o_400x400.jpg?1574327008"
    },
    {
      "chainId": 1,
      "address": "0x5A98FcBEA516Cf06857215779Fd812CA3beF1B32",
      "name": "Lido DAO",
      "symbol": "LDO",
      "decimals": 18,
      "logoURI": "https://assets.coingecko.com/coins/images/13573/thumb/Lido_DAO.png?1609873644"
    },
    {
      "name": "ChainLink Token",
      "address": "0x514910771AF9Ca656af840dff83E8264EcF986CA",
      "symbol": "LINK",
      "decimals": 18,
      "chainId": 1,
      "logoURI": "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x514910771AF9Ca656af840dff83E8264EcF986CA/logo.png"
    },
    {
      "name": "Loom Network",
      "address": "0xA4e8C3Ec456107eA67d3075bF9e3DF3A75823DB0",
      "symbol": "LOOM",
      "decimals": 18,
      "chainId": 1,
      "logoURI": "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xA4e8C3Ec456107eA67d3075bF9e3DF3A75823DB0/logo.png"
    },
    {
      "chainId": 1,
      "address": "0x58b6A8A3302369DAEc383334672404Ee733aB239",
      "name": "Livepeer",
      "symbol": "LPT",
      "decimals": 18,
      "logoURI": "https://assets.coingecko.com/coins/images/7137/thumb/logo-circle-green.png?1619593365"
    },
    {
      "chainId": 1,
      "address": "0x6DEA81C8171D0bA574754EF6F8b412F2Ed88c54D",
      "name": "Liquity",
      "symbol": "LQTY",
      "decimals": 18,
      "logoURI": "https://assets.coingecko.com/coins/images/14665/thumb/200-lqty-icon.png?1617631180"
    },
    {
      "name": "LoopringCoin V2",
      "address": "0xBBbbCA6A901c926F240b89EacB641d8Aec7AEafD",
      "symbol": "LRC",
      "decimals": 18,
      "chainId": 1,
      "logoURI": "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xBBbbCA6A901c926F240b89EacB641d8Aec7AEafD/logo.png"
    },
    {
      "chainId": 1,
      "address": "0x0F5D2fB29fb7d3CFeE444a200298f468908cC942",
      "name": "Decentraland",
      "symbol": "MANA",
      "decimals": 18,
      "logoURI": "https://assets.coingecko.com/coins/images/878/thumb/decentraland-mana.png?1550108745"
    },
    {
      "chainId": 1,
      "address": "0x69af81e73A73B40adF4f3d4223Cd9b1ECE623074",
      "name": "Mask Network",
      "symbol": "MASK",
      "decimals": 18,
      "logoURI": "https://assets.coingecko.com/coins/images/14051/thumb/Mask_Network.jpg?1614050316"
    },
    {
      "chainId": 1,
      "address": "0x7D1AfA7B718fb893dB30A3aBc0Cfc608AaCfeBB0",
      "name": "Polygon",
      "symbol": "MATIC",
      "decimals": 18,
      "logoURI": "https://assets.coingecko.com/coins/images/4713/thumb/matic-token-icon.png?1624446912"
    },
    {
      "chainId": 1,
      "address": "0x949D48EcA67b17269629c7194F4b727d4Ef9E5d6",
      "name": "Merit Circle",
      "symbol": "MC",
      "decimals": 18,
      "logoURI": "https://assets.coingecko.com/coins/images/19304/thumb/Db4XqML.png?1634972154"
    },
    {
      "chainId": 1,
      "address": "0xfC98e825A2264D890F9a1e68ed50E1526abCcacD",
      "name": "Moss Carbon Credit",
      "symbol": "MCO2",
      "decimals": 18,
      "logoURI": "https://assets.coingecko.com/coins/images/14414/thumb/ENtxnThA_400x400.jpg?1615948522"
    },
    {
      "chainId": 1,
      "address": "0x814e0908b12A99FeCf5BC101bB5d0b8B5cDf7d26",
      "name": "Measurable Data Token",
      "symbol": "MDT",
      "decimals": 18,
      "logoURI": "https://assets.coingecko.com/coins/images/2441/thumb/mdt_logo.png?1569813574"
    },
    {
      "chainId": 1,
      "address": "0x99D8a9C45b2ecA8864373A26D1459e3Dff1e17F3",
      "name": "Magic Internet Money",
      "symbol": "MIM",
      "decimals": 18,
      "logoURI": "https://assets.coingecko.com/coins/images/16786/thumb/mimlogopng.png?1624979612"
    },
    {
      "chainId": 1,
      "address": "0x09a3EcAFa817268f77BE1283176B946C4ff2E608",
      "name": "Mirror Protocol",
      "symbol": "MIR",
      "decimals": 18,
      "logoURI": "https://assets.coingecko.com/coins/images/13295/thumb/mirror_logo_transparent.png?1611554658"
    },
    {
      "name": "Maker",
      "address": "0x9f8F72aA9304c8B593d555F12eF6589cC3A579A2",
      "symbol": "MKR",
      "decimals": 18,
      "chainId": 1,
      "logoURI": "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x9f8F72aA9304c8B593d555F12eF6589cC3A579A2/logo.png"
    },
    {
      "chainId": 1,
      "address": "0xec67005c4E498Ec7f55E092bd1d35cbC47C91892",
      "name": "Melon",
      "symbol": "MLN",
      "decimals": 18,
      "logoURI": "https://assets.coingecko.com/coins/images/605/thumb/melon.png?1547034295"
    },
    {
      "chainId": 1,
      "address": "0xe2f2a5C287993345a840Db3B0845fbC70f5935a5",
      "name": "mStable USD",
      "symbol": "MUSD",
      "decimals": 18,
      "logoURI": "https://assets.coingecko.com/coins/images/11576/thumb/mStable_USD.png?1595591803"
    },
    {
      "chainId": 1,
      "address": "0x9E46A38F5DaaBe8683E10793b06749EEF7D733d1",
      "name": "PolySwarm",
      "symbol": "NCT",
      "decimals": 18,
      "logoURI": "https://assets.coingecko.com/coins/images/2843/thumb/ImcYCVfX_400x400.jpg?1628519767"
    },
    {
      "chainId": 1,
      "address": "0x5Cf04716BA20127F1E2297AdDCf4B5035000c9eb",
      "name": "NKN",
      "symbol": "NKN",
      "decimals": 18,
      "logoURI": "https://assets.coingecko.com/coins/images/3375/thumb/nkn.png?1548329212"
    },
    {
      "name": "Numeraire",
      "address": "0x1776e1F26f98b1A5dF9cD347953a26dd3Cb46671",
      "symbol": "NMR",
      "decimals": 18,
      "chainId": 1,
      "logoURI": "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x1776e1F26f98b1A5dF9cD347953a26dd3Cb46671/logo.png"
    },
    {
      "chainId": 1,
      "address": "0x4fE83213D56308330EC302a8BD641f1d0113A4Cc",
      "name": "NuCypher",
      "symbol": "NU",
      "decimals": 18,
      "logoURI": "https://assets.coingecko.com/coins/images/3318/thumb/photo1198982838879365035.jpg?1547037916"
    },
    {
      "chainId": 1,
      "address": "0x967da4048cD07aB37855c090aAF366e4ce1b9F48",
      "name": "Ocean Protocol",
      "symbol": "OCEAN",
      "decimals": 18,
      "logoURI": "https://assets.coingecko.com/coins/images/3687/thumb/ocean-protocol-logo.jpg?1547038686"
    },
    {
      "chainId": 1,
      "address": "0x8207c1FfC5B6804F6024322CcF34F29c3541Ae26",
      "name": "Origin Protocol",
      "symbol": "OGN",
      "decimals": 18,
      "logoURI": "https://assets.coingecko.com/coins/images/3296/thumb/op.jpg?1547037878"
    },
    {
      "chainId": 1,
      "address": "0xd26114cd6EE289AccF82350c8d8487fedB8A0C07",
      "name": "OMG Network",
      "symbol": "OMG",
      "decimals": 18,
      "logoURI": "https://assets.coingecko.com/coins/images/776/thumb/OMG_Network.jpg?1591167168"
    },
    {
      "chainId": 1,
      "address": "0x0258F474786DdFd37ABCE6df6BBb1Dd5dfC4434a",
      "name": "Orion Protocol",
      "symbol": "ORN",
      "decimals": 8,
      "logoURI": "https://assets.coingecko.com/coins/images/11841/thumb/orion_logo.png?1594943318"
    },
    {
      "name": "Orchid",
      "address": "0x4575f41308EC1483f3d399aa9a2826d74Da13Deb",
      "symbol": "OXT",
      "decimals": 18,
      "chainId": 1,
      "logoURI": "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x4575f41308EC1483f3d399aa9a2826d74Da13Deb/logo.png"
    },
    {
      "chainId": 1,
      "address": "0x45804880De22913dAFE09f4980848ECE6EcbAf78",
      "name": "PAX Gold",
      "symbol": "PAXG",
      "decimals": 18,
      "logoURI": "https://assets.coingecko.com/coins/images/9519/thumb/paxg.PNG?1568542565"
    },
    {
      "chainId": 1,
      "address": "0xbC396689893D065F41bc2C6EcbeE5e0085233447",
      "name": "Perpetual Protocol",
      "symbol": "PERP",
      "decimals": 18,
      "logoURI": "https://assets.coingecko.com/coins/images/12381/thumb/60d18e06844a844ad75901a9_mark_only_03.png?1628674771"
    },
    {
      "chainId": 1,
      "address": "0xD8912C10681D8B21Fd3742244f44658dBA12264E",
      "name": "Pluton",
      "symbol": "PLU",
      "decimals": 18,
      "logoURI": "https://assets.coingecko.com/coins/images/1241/thumb/pluton.png?1548331624"
    },
    {
      "chainId": 1,
      "address": "0x83e6f1E41cdd28eAcEB20Cb649155049Fac3D5Aa",
      "name": "Polkastarter",
      "symbol": "POLS",
      "decimals": 18,
      "logoURI": "https://assets.coingecko.com/coins/images/12648/thumb/polkastarter.png?1609813702"
    },
    {
      "chainId": 1,
      "address": "0x9992eC3cF6A55b00978cdDF2b27BC6882d88D1eC",
      "name": "Polymath",
      "symbol": "POLY",
      "decimals": 18,
      "logoURI": "https://assets.coingecko.com/coins/images/2784/thumb/inKkF01.png?1605007034"
    },
    {
      "chainId": 1,
      "address": "0x595832F8FC6BF59c85C527fEC3740A1b7a361269",
      "name": "Power Ledger",
      "symbol": "POWR",
      "decimals": 6,
      "logoURI": "https://assets.coingecko.com/coins/images/1104/thumb/power-ledger.png?1547035082"
    },
    {
      "chainId": 1,
      "address": "0x226bb599a12C826476e3A771454697EA52E9E220",
      "name": "Propy",
      "symbol": "PRO",
      "decimals": 8,
      "logoURI": "https://assets.coingecko.com/coins/images/869/thumb/propy.png?1548332100"
    },
    {
      "chainId": 1,
      "address": "0x4a220E6096B25EADb88358cb44068A3248254675",
      "name": "Quant",
      "symbol": "QNT",
      "decimals": 18,
      "logoURI": "https://assets.coingecko.com/coins/images/3370/thumb/5ZOu7brX_400x400.jpg?1612437252"
    },
    {
      "chainId": 1,
      "address": "0x99ea4dB9EE77ACD40B119BD1dC4E33e1C070b80d",
      "name": "Quantstamp",
      "symbol": "QSP",
      "decimals": 18,
      "logoURI": "https://assets.coingecko.com/coins/images/1219/thumb/0_E0kZjb4dG4hUnoDD_.png?1604815917"
    },
    {
      "chainId": 1,
      "address": "0x6c28AeF8977c9B773996d0e8376d2EE379446F2f",
      "name": "Quickswap",
      "symbol": "QUICK",
      "decimals": 18,
      "logoURI": "https://assets.coingecko.com/coins/images/13970/thumb/1_pOU6pBMEmiL-ZJVb0CYRjQ.png?1613386659"
    },
    {
      "chainId": 1,
      "address": "0x31c8EAcBFFdD875c74b94b077895Bd78CF1E64A3",
      "name": "Radicle",
      "symbol": "RAD",
      "decimals": 18,
      "logoURI": "https://assets.coingecko.com/coins/images/14013/thumb/radicle.png?1614402918"
    },
    {
      "chainId": 1,
      "address": "0x03ab458634910AaD20eF5f1C8ee96F1D6ac54919",
      "name": "Rai Reflex Index",
      "symbol": "RAI",
      "decimals": 18,
      "logoURI": "https://assets.coingecko.com/coins/images/14004/thumb/RAI-logo-coin.png?1613592334"
    },
    {
      "chainId": 1,
      "address": "0xFca59Cd816aB1eaD66534D82bc21E7515cE441CF",
      "name": "Rarible",
      "symbol": "RARI",
      "decimals": 18,
      "logoURI": "https://assets.coingecko.com/coins/images/11845/thumb/Rari.png?1594946953"
    },
    {
      "chainId": 1,
      "address": "0xA4EED63db85311E22dF4473f87CcfC3DaDCFA3E3",
      "name": "Rubic",
      "symbol": "RBC",
      "decimals": 18,
      "logoURI": "https://assets.coingecko.com/coins/images/12629/thumb/200x200.png?1607952509"
    },
    {
      "name": "Republic Token",
      "address": "0x408e41876cCCDC0F92210600ef50372656052a38",
      "symbol": "REN",
      "decimals": 18,
      "chainId": 1,
      "logoURI": "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x408e41876cCCDC0F92210600ef50372656052a38/logo.png"
    },
    {
      "name": "Reputation Augur v1",
      "address": "0x1985365e9f78359a9B6AD760e32412f4a445E862",
      "symbol": "REP",
      "decimals": 18,
      "chainId": 1,
      "logoURI": "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x1985365e9f78359a9B6AD760e32412f4a445E862/logo.png"
    },
    {
      "name": "Reputation Augur v2",
      "address": "0x221657776846890989a759BA2973e427DfF5C9bB",
      "symbol": "REPv2",
      "decimals": 18,
      "chainId": 1,
      "logoURI": "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x221657776846890989a759BA2973e427DfF5C9bB/logo.png"
    },
    {
      "chainId": 1,
      "address": "0x8f8221aFbB33998d8584A2B05749bA73c37a938a",
      "name": "Request",
      "symbol": "REQ",
      "decimals": 18,
      "logoURI": "https://assets.coingecko.com/coins/images/1031/thumb/Request_icon_green.png?1643250951"
    },
    {
      "chainId": 1,
      "address": "0xD291E7a03283640FDc51b121aC401383A46cC623",
      "name": "Rari Governance Token",
      "symbol": "RGT",
      "decimals": 18,
      "logoURI": "https://assets.coingecko.com/coins/images/12900/thumb/Rari_Logo_Transparent.png?1613978014"
    },
    {
      "chainId": 1,
      "address": "0x607F4C5BB672230e8672085532f7e901544a7375",
      "name": "iExec RLC",
      "symbol": "RLC",
      "decimals": 9,
      "logoURI": "https://assets.coingecko.com/coins/images/646/thumb/pL1VuXm.png?1604543202"
    },
    {
      "chainId": 1,
      "address": "0xf1f955016EcbCd7321c7266BccFB96c68ea5E49b",
      "name": "Rally",
      "symbol": "RLY",
      "decimals": 18,
      "logoURI": "https://assets.coingecko.com/coins/images/12843/thumb/image.png?1611212077"
    },
    {
      "chainId": 1,
      "address": "0x6De037ef9aD2725EB40118Bb1702EBb27e4Aeb24",
      "name": "Render Token",
      "symbol": "RNDR",
      "decimals": 18,
      "logoURI": "https://assets.coingecko.com/coins/images/11636/thumb/rndr.png?1638840934"
    },
    {
      "chainId": 1,
      "address": "0x3845badAde8e6dFF049820680d1F14bD3903a5d0",
      "name": "The Sandbox",
      "symbol": "SAND",
      "decimals": 18,
      "logoURI": "https://assets.coingecko.com/coins/images/12129/thumb/sandbox_logo.jpg?1597397942"
    },
    {
      "chainId": 1,
      "address": "0x95aD61b0a150d79219dCF64E1E6Cc01f0B64C4cE",
      "name": "Shiba Inu",
      "symbol": "SHIB",
      "decimals": 18,
      "logoURI": "https://assets.coingecko.com/coins/images/11939/thumb/shiba.png?1622619446"
    },
    {
      "chainId": 1,
      "address": "0x7C84e62859D0715eb77d1b1C4154Ecd6aBB21BEC",
      "name": "Shping",
      "symbol": "SHPING",
      "decimals": 18,
      "logoURI": "https://assets.coingecko.com/coins/images/2588/thumb/r_yabKKi_400x400.jpg?1639470164"
    },
    {
      "chainId": 1,
      "address": "0x00c83aeCC790e8a4453e5dD3B0B4b3680501a7A7",
      "name": "SKALE",
      "symbol": "SKL",
      "decimals": 18,
      "logoURI": "https://assets.coingecko.com/coins/images/13245/thumb/SKALE_token_300x300.png?1606789574"
    },
    {
      "chainId": 1,
      "address": "0xCC8Fa225D80b9c7D42F96e9570156c65D6cAAa25",
      "name": "Smooth Love Potion",
      "symbol": "SLP",
      "decimals": 0,
      "logoURI": "https://assets.coingecko.com/coins/images/10366/thumb/SLP.png?1578640057"
    },
    {
      "chainId": 1,
      "address": "0x744d70FDBE2Ba4CF95131626614a1763DF805B9E",
      "name": "Status",
      "symbol": "SNT",
      "decimals": 18,
      "logoURI": "https://assets.coingecko.com/coins/images/779/thumb/status.png?1548610778"
    },
    {
      "name": "Synthetix Network Token",
      "address": "0xC011a73ee8576Fb46F5E1c5751cA3B9Fe0af2a6F",
      "symbol": "SNX",
      "decimals": 18,
      "chainId": 1,
      "logoURI": "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xC011a73ee8576Fb46F5E1c5751cA3B9Fe0af2a6F/logo.png"
    },
    {
      "chainId": 1,
      "address": "0xD31a59c85aE9D8edEFeC411D448f90841571b89c",
      "name": "SOL Wormhole ",
      "symbol": "SOL",
      "decimals": 9,
      "logoURI": "https://assets.coingecko.com/coins/images/22876/thumb/SOL_wh_small.png?1644224316"
    },
    {
      "chainId": 1,
      "address": "0x090185f2135308BaD17527004364eBcC2D37e5F6",
      "name": "Spell Token",
      "symbol": "SPELL",
      "decimals": 18,
      "logoURI": "https://assets.coingecko.com/coins/images/15861/thumb/abracadabra-3.png?1622544862"
    },
    {
      "name": "Storj Token",
      "address": "0xB64ef51C888972c908CFacf59B47C1AfBC0Ab8aC",
      "symbol": "STORJ",
      "decimals": 8,
      "chainId": 1,
      "logoURI": "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xB64ef51C888972c908CFacf59B47C1AfBC0Ab8aC/logo.png"
    },
    {
      "chainId": 1,
      "address": "0x006BeA43Baa3f7A6f765F14f10A1a1b08334EF45",
      "name": "Stox",
      "symbol": "STX",
      "decimals": 18,
      "logoURI": "https://assets.coingecko.com/coins/images/1230/thumb/stox-token.png?1547035256"
    },
    {
      "chainId": 1,
      "address": "0x0763fdCCF1aE541A5961815C0872A8c5Bc6DE4d7",
      "name": "SUKU",
      "symbol": "SUKU",
      "decimals": 18,
      "logoURI": "https://assets.coingecko.com/coins/images/11969/thumb/UmfW5S6f_400x400.jpg?1596602238"
    },
    {
      "chainId": 1,
      "address": "0xe53EC727dbDEB9E2d5456c3be40cFF031AB40A55",
      "name": "SuperFarm",
      "symbol": "SUPER",
      "decimals": 18,
      "logoURI": "https://assets.coingecko.com/coins/images/14040/thumb/6YPdWn6.png?1613975899"
    },
    {
      "name": "Synth sUSD",
      "address": "0x57Ab1ec28D129707052df4dF418D58a2D46d5f51",
      "symbol": "sUSD",
      "decimals": 18,
      "chainId": 1,
      "logoURI": "https://assets.coingecko.com/coins/images/5013/thumb/sUSD.png?1616150765"
    },
    {
      "chainId": 1,
      "address": "0x6B3595068778DD592e39A122f4f5a5cF09C90fE2",
      "name": "Sushi",
      "symbol": "SUSHI",
      "decimals": 18,
      "logoURI": "https://assets.coingecko.com/coins/images/12271/thumb/512x512_Logo_no_chop.png?1606986688"
    },
    {
      "chainId": 1,
      "address": "0x8dAEBADE922dF735c38C80C7eBD708Af50815fAa",
      "name": "tBTC",
      "symbol": "TBTC",
      "decimals": 18,
      "logoURI": "https://assets.coingecko.com/coins/images/11224/thumb/tBTC.png?1589620754"
    },
    {
      "chainId": 1,
      "address": "0xaA7a9CA87d3694B5755f213B5D04094b8d0F0A6F",
      "name": "OriginTrail",
      "symbol": "TRAC",
      "decimals": 18,
      "logoURI": "https://assets.coingecko.com/coins/images/1877/thumb/TRAC.jpg?1635134367"
    },
    {
      "chainId": 1,
      "address": "0x88dF592F8eb5D7Bd38bFeF7dEb0fBc02cf3778a0",
      "name": "Tellor",
      "symbol": "TRB",
      "decimals": 18,
      "logoURI": "https://assets.coingecko.com/coins/images/9644/thumb/Blk_icon_current.png?1584980686"
    },
    {
      "chainId": 1,
      "address": "0xc7283b66Eb1EB5FB86327f08e1B5816b0720212B",
      "name": "Tribe",
      "symbol": "TRIBE",
      "decimals": 18,
      "logoURI": "https://assets.coingecko.com/coins/images/14575/thumb/tribe.PNG?1617487954"
    },
    {
      "chainId": 1,
      "address": "0x4C19596f5aAfF459fA38B0f7eD92F11AE6543784",
      "name": "TrueFi",
      "symbol": "TRU",
      "decimals": 8,
      "logoURI": "https://assets.coingecko.com/coins/images/13180/thumb/truefi_glyph_color.png?1617610941"
    },
    {
      "name": "UMA Voting Token v1",
      "address": "0x04Fa0d235C4abf4BcF4787aF4CF447DE572eF828",
      "symbol": "UMA",
      "decimals": 18,
      "chainId": 1,
      "logoURI": "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x04Fa0d235C4abf4BcF4787aF4CF447DE572eF828/logo.png"
    },
    {
      "name": "Uniswap",
      "address": "0x1f9840a85d5aF5bf1D1762F925BDADdC4201F984",
      "symbol": "UNI",
      "decimals": 18,
      "chainId": 1,
      "logoURI": "ipfs://QmXttGpZrECX5qCyXbBQiqgQNytVGeZW5Anewvh2jc4psg"
    },
    {
      "name": "USDCoin",
      "address": "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48",
      "symbol": "USDC",
      "decimals": 6,
      "chainId": 1,
      "logoURI": "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48/logo.png"
    },
    {
      "name": "Tether USD",
      "address": "0xdAC17F958D2ee523a2206206994597C13D831ec7",
      "symbol": "USDT",
      "decimals": 6,
      "chainId": 1,
      "logoURI": "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xdAC17F958D2ee523a2206206994597C13D831ec7/logo.png"
    },
    {
      "chainId": 1,
      "address": "0xa47c8bf37f92aBed4A126BDA807A7b7498661acD",
      "name": "Wrapped UST",
      "symbol": "UST",
      "decimals": 18,
      "logoURI": "https://assets.coingecko.com/coins/images/15462/thumb/ust.png?1620910058"
    },
    {
      "chainId": 1,
      "address": "0xa693B19d2931d498c5B318dF961919BB4aee87a5",
      "name": "TerraUSD Wormhole ",
      "symbol": "UST",
      "decimals": 6,
      "logoURI": "https://assets.coingecko.com/coins/images/21150/thumb/UST_wh_small.png?1644223065"
    },
    {
      "chainId": 1,
      "address": "0x1b40183EFB4Dd766f11bDa7A7c3AD8982e998421",
      "symbol": "VSP",
      "name": "Vesper Finance",
      "decimals": 18,
      "logoURI": "ipfs://QmRFHWRxPnZMYGfeviHSBmxVzAbN7E6dQLZpEKht41GeBE"
    },
    {
      "chainId": 1,
      "address": "0x3C4B6E6e1eA3D4863700D7F76b36B7f3D3f13E3d",
      "name": "Voyager Token",
      "symbol": "VGX",
      "decimals": 8,
      "logoURI": "https://assets.coingecko.com/coins/images/794/thumb/Voyager-vgx.png?1575693595"
    },
    {
      "name": "Wrapped BTC",
      "address": "0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599",
      "symbol": "WBTC",
      "decimals": 8,
      "chainId": 1,
      "logoURI": "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599/logo.png"
    },
    {
      "name": "Wrapped Ether",
      "address": chainId === 4 ? "0xc778417E063141139Fce010982780140Aa0cD5Ab" : "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2",
      "symbol": "WETH",
      "decimals": 18,
      "chainId": 1,
      "logoURI": "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2/logo.png"
    },
    {
      "chainId": 1,
      "address": "0xd2877702675e6cEb975b4A1dFf9fb7BAF4C91ea9",
      "name": "Wrapped LUNA Token",
      "symbol": "WLUNA",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/11178.png"
    },
    {
      "chainId": 1,
      "address": "0x55296f69f40Ea6d20E478533C15A6B08B654E758",
      "name": "XYO Network",
      "symbol": "XYO",
      "decimals": 18,
      "logoURI": "https://assets.coingecko.com/coins/images/4519/thumb/XYO_Network-logo.png?1547039819"
    },
    {
      "chainId": 1,
      "address": "0x0bc529c00C6401aEF6D220BE8C6Ea1667F6Ad93e",
      "name": "yearn finance",
      "symbol": "YFI",
      "decimals": 18,
      "logoURI": "https://assets.coingecko.com/coins/images/11849/thumb/yfi-192x192.png?1598325330"
    },
    {
      "chainId": 1,
      "address": "0xa1d0E215a23d7030842FC67cE582a6aFa3CCaB83",
      "name": "DFI money",
      "symbol": "YFII",
      "decimals": 18,
      "logoURI": "https://assets.coingecko.com/coins/images/11902/thumb/YFII-logo.78631676.png?1598677348"
    },
    {
      "name": "0x Protocol Token",
      "address": "0xE41d2489571d322189246DaFA5ebDe1F4699F498",
      "symbol": "ZRX",
      "decimals": 18,
      "chainId": 1,
      "logoURI": "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xE41d2489571d322189246DaFA5ebDe1F4699F498/logo.png"
    },
  ]
}
