import ContentCopyIcon from '@material-ui/icons/FileCopy';

import Button from "components/CustomButtons/Button.js";

const CopyButton = function (props) {
  const { width, height, copyText } = props

  const style = (width && height) ? {
    width,
    height
  }
    :
  null

  return (
    <Button
      color="transparent"
      style={{
        padding: '0px 10px',
        margin: '0px'
      }}
      onClick={() => {navigator.clipboard.writeText(copyText)}}
    >
      <ContentCopyIcon
        style={style}
      />
    </Button>
  )
}

export default CopyButton
