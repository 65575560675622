import React, { useEffect, useState, useCallback } from 'react';
import {useDropzone} from 'react-dropzone';
import { NFTStorage, Blob } from "nft.storage"
import {
  NFTStorageApiKey,
  MainChainId,
  isAddress
} from 'utils'

// active web3, track tx, erc20 contract
import { useActiveWeb3React } from 'hooks/useActiveWeb3React.js'
import { useTransactionAdder } from 'state/transactions/hooks'

import { makeStyles } from "@material-ui/core/styles";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";

// blank image
import blankImage from "assets/img/blank-image.png";
//import PreviewImage from "assets/img/Capsule-Preview.png";

// loading div
import LoadingDiv from "components/app/FunctionComponents/UploadNFTInfo/LoadingDiv.js"

// mint Capsule
import MintCapsule from "./MintCapsule"

//import styles from "assets/jss/material-kit-react/views/componentsSections/tabsStyle.js";
import styles from "assets/jss/material-kit-react/views/componentsSections/tabsStyle.js";

// redux user txs
import { useSelector } from 'react-redux'

// tx loader div
import PendingTransactions from "components/app/FunctionComponents/PendingTransactions.js"

const useStyles = makeStyles(styles)

const thumbsContainer = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  marginTop: 16,
  marginLeft: 9
};

const thumb = {
  display: 'inline-flex',
  borderRadius: 2,
  border: '2px solid #6f40ff',
  marginBottom: 8,
  marginRight: 8,
  maxWidth: (window.innerWidth - 30),
  height: 500,
  padding: 4,
  boxSizing: 'border-box'
};

const thumbInner = {
  display: 'flex',
  minWidth: 0,
  overflow: 'hidden'
};

const img = {
  display: 'block',
  width: (window.innerWidth < 700 ? '100%' : 'auto'),
  height: '100%',
  objectFit: 'scale-down'
};

// file validation requirements
const maxLength = 100
const maxSize = 104857600 // 100 MB
const maxSizeInMB = 100 // update alongside maxSize

// should link to user account, or a specific NFT #
const capsuleUrl = 'capsulenft.com'

function nameLengthValidator(file) {
  if (file.name.length > maxLength) {
    return {
      code: "name-too-long",
      message: `Name is longer than ${maxLength} characters`
    };
  }

  if (file.size > maxSize) {
    return {
      code: "file-too-large",
      message: `File is larger than ${maxSizeInMB} MB`
    };
  }

  return null
}


//** issue exists where a user can upload to ipfs without being logged in
const UploadNFTInfo = function (props) {
  const classes = useStyles()

  const {
    nftMintFunction,
    collectionAddress,
    erc20Data,
    erc721Data,
    erc1155Data,
    type,
    open
  } = props
  const { chainId, account } = useActiveWeb3React()

  const [files, setFiles] = useState([]) // file upload
  // input values
  const [ipfsLoadingDiv, setIpfsLoadingDiv] = useState(null) // ipfs loading div
  // error div
  const [errorDiv, setErrorDiv] = useState(null)

  // current test for loading transactions
  const transactionSelector = useSelector(state => state.transactions)
  const transactionState = chainId ? transactionSelector[chainId] ?? null : null

  const [txEx, setTxEx] = useState(transactionSelector?.[chainId]); // set when tx executed
  useEffect(() => {
    setTxEx(transactionSelector?.[chainId])
  }, [transactionSelector])

  const [transactions, setTransactions] = useState([]) // user txs for div

  // input user nftName
  const [nftName, setNftName] = React.useState('')
  const handleNftNameChange = event => {
    setNftName(event.target.value)
  }
  // input user nftDescription
  const [nftDescription, setNftDescription] = React.useState('')
  const handleNftDescriptionChange = event => {
    setNftDescription(event.target.value)
  }

  // ^ note that any changes/additions to variables need to also be made to the callback, to update it
  //const tokenERC20Contract = useERC20Contract(erc20TokenAddress, false, { active: false })
  //const tokenERC721Contract = useERC721Contract(erc721TokenAddress, false, { active: false })

  const addTransaction = useTransactionAdder()

  const {
    acceptedFiles,
    fileRejections,
    getRootProps,
    getInputProps
  } = useDropzone({
    accept: 'image/*,audio/*,video/*',
    validator: nameLengthValidator,
    maxFiles: 1,
    onDrop: acceptedFiles => {
      setFiles(acceptedFiles.map(file => Object.assign(file, {
        preview: URL.createObjectURL(file)
      })));
    }
  });

  const client = new NFTStorage({ token: NFTStorageApiKey })

  const thumbs = files.map(file => (
    <div style={thumb} key={file.name}>
      <div style={thumbInner}>
        <img
          src={file.preview}
          alt="Image Preview"
          style={img}
        />
      </div>
    </div>
  ));

  useEffect(() => () => {
    // Make sure to revoke the data uris to avoid memory leaks
    files.forEach(file => URL.revokeObjectURL(file.preview))}, [files]);

    const acceptedFileItems = acceptedFiles.map(file => (
      <li key={file.path}>
        {file.path} - {file.size} bytes
      </li>
    ));

    const fileRejectionItems = fileRejections.map(({ file, errors }) => (
      <li key={file.path}>
        {file.path} - {file.size} bytes
        <ul>
          {errors.map(e => (
            <li key={e.code}>{e.message}</li>
          ))}
        </ul>
      </li>
    ));

    const removeFiles = function () {
      setFiles([])
    }

    const createErrorModule = function (customError, errorObject) {
      setErrorDiv(
        <GridItem
          xs={12}
          style={{
            color: "#dd4b39"
          }}
        >
          <h3
            style={{
              textAlign: 'center'
            }}
          >Previous Error:</h3>
          <p style={{
            fontSize: '18px',
            textAlign: 'center'
          }}>
            <strong>{customError || errorObject?.message}</strong>
          </p>
        </GridItem>)
    }

    const createAttributes = function (type, data) {
      let attributes = null
      if (type === 'erc20-capsule') {
        attributes = [
          {
            trait_type: 'Capsule Type',
            value: 'ERC-20 Capsule NFT'
          },
          {
            trait_type: 'Creator',
            value: account
          },
          {
            trait_type: 'Chain Id',
            value: `${MainChainId}`
          }
        ]

        for (let a = 0; a < data.length; a++) {
          attributes.push({
            trait_type: `Token ${a+1} Address`,
            value: data[a].address
          })
          attributes.push({
            trait_type: `Token ${a+1} Amount`,
            value: data[a].amount
          })
        }
      } else if (type === 'erc721-capsule') {
        attributes = [
          {
            trait_type: 'Capsule Type',
            value: 'ERC-721 Capsule NFT'
          },
          {
            trait_type: 'Creator',
            value: account
          },
          {
            trait_type: 'Chain Id',
            value: `${MainChainId}`
          }
        ]

        for (let a = 0; a < data.length; a++) {
          attributes.push({
            trait_type: `Token ${a+1} Address`,
            value: data[a].address
          })
          attributes.push({
            trait_type: `Token ${a+1} Id`,
            value: data[a].id
          })
        }
      } else if (type === 'erc1155-capsule') {
        attributes = [
          {
            trait_type: 'Capsule Type',
            value: 'ERC-1155 Capsule NFT'
          },
          {
            trait_type: 'Creator',
            value: account
          },
          {
            trait_type: 'Chain Id',
            value: `${MainChainId}`
          }
        ]

        for (let a = 0; a < data.length; a++) {
          attributes.push({
            trait_type: `Token ${a+1} Address`,
            value: data[a].address
          })
          attributes.push({
            trait_type: `Token ${a+1} Amount`,
            value: data[a].amount
          })
        }
      }
      return attributes
    }

    console.log(erc1155Data)

    // if exist, for attribute section
    let nftValueAttribute = createAttributes(type, erc20Data || erc721Data || erc1155Data || [])

    const handleCreation = useCallback(async () => {
      try {
        if (!account) {
          createErrorModule('You need to sign in to create an NFT!', null)
          return
        }

        const maxNameLength = 5000
        const maxDescriptionLength = 25000
        let error = []

        const chosenNftName = nftName
        const chosenNftDescription = nftDescription

        // *- to add -*
        // *issue with ipfs image upload failing
        // if account has 0 eth, do not allow for capsule creation (or at least IPFS upload)
        if (chosenNftName.length > maxNameLength) {
          error.push(<p id="ntl">{`Name too long (Current ${chosenNftName.length} characters, max ${maxNameLength})`}</p>)
        }

        if (chosenNftDescription.length > maxDescriptionLength) {
          error.push(<p id="dtl">{`Description too long (Current ${chosenNftDescription.length} characters, max ${maxDescriptionLength})`}</p>)
        }

        // specific ERC-20 errors
        if (type === 'erc20-capsule') {
          if (!erc20Data) {
            error.push(<p id="no20token">{'You haven\'t placed any tokens in this ERC-20 Capsule NFT. If you do not wish to store any ERC-20 tokens, please create a Simple Capsule NFT?'}</p>)
          } else if (erc20Data?.length < 1) {
            error.push(<p id="no20tokendata">{'You haven\'t placed any tokens in this ERC-20 Capsule NFT. If you do not wish to store any ERC-20 tokens, please create a Simple Capsule NFT?'}</p>)
          }

          for (var e = 0; e < erc20Data.length; e++) {
            const erc20TokenAddress = erc20Data[e].address
            const erc20TokenAmount = erc20Data[e].amount

            if (!isAddress(erc20TokenAddress)) {
              error.push(<p id="ietaf">{`Token ${e+1}: Invalid ERC-20 token address format`}</p>)
            }

            if (isNaN(erc20TokenAmount) || parseFloat(erc20TokenAmount) <= 0 || erc20TokenAmount.length < 1) {
              error.push(<p id="ieta">{`Token ${e+1}: Invalid ERC-20 token amount`}</p>)
            }
          }
        }

        // specific ERC-721 errors
        if (type === 'erc721-capsule') {
          if (!erc721Data) {
            error.push(<p id="no721token">{'You haven\'t placed any NFTs in this ERC-721 Capsule NFT. If you do not wish to store any ERC-721 tokens, please create a Simple Capsule NFT.'}</p>)
          } else if (erc721Data?.length < 1) {
            error.push(<p id="no721tokendata">{'You haven\'t placed any NFTs in this ERC-721 Capsule NFT. If you do not wish to store any ERC-721 tokens, please create a Simple Capsule NFT.'}</p>)
          }

          for (var err = 0; err < erc721Data.length; err++) {
            const erc721TokenAddress = erc721Data[err].address
            const erc721TokenId = erc721Data[err].id

            if (!isAddress(erc721TokenAddress)) {
              error.push(<p id="ietaf">{`Token ${err}: Invalid ERC-721 token address format`}</p>)
            }

            if (isNaN(erc721TokenId) || parseFloat(erc721TokenId) < 0 || erc721TokenId.length < 1) {
              error.push(<p id="ieta">{`Token ${err}: Invalid ERC-721 Id`}</p>)
            }
          }
        }

        // specific ERC-1155 errors
        if (type === 'erc1155-capsule') {
          if (!erc1155Data) {
            error.push(<p id="no1155token">{'You haven\'t placed any tokens in this ERC-1155 Capsule NFT. If you do not wish to store any ERC-1155 tokens, please create a Simple Capsule NFT?'}</p>)
          } else if (erc1155Data?.length < 1) {
            error.push(<p id="no1155tokendata">{'You haven\'t placed any tokens in this ERC-1155 Capsule NFT. If you do not wish to store any ERC-1155 tokens, please create a Simple Capsule NFT?'}</p>)
          }

          for (var e = 0; e < erc1155Data.length; e++) {
            const erc1155TokenAddress = erc1155Data[e].address
            const erc1155TokenId = erc1155Data[e].id
            const erc1155TokenAmount = erc1155Data[e].amount

            console.log('p', erc1155Data)

            if (!isAddress(erc1155TokenAddress)) {
              error.push(<p id="ietaf">{`Token ${e+1}: Invalid ERC-1155 token address format`}</p>)
            }

            if (isNaN(erc1155TokenId) || parseFloat(erc1155TokenId) < 0 || erc1155TokenId.length < 1) {
              error.push(<p id="ieta">{`Token ${err}: Invalid ERC-1155 Id`}</p>)
            }

            if (isNaN(erc1155TokenAmount) || parseFloat(erc1155TokenAmount) <= 0 || erc1155TokenAmount.length < 1) {
              error.push(<p id="ieta">{`Token ${e+1}: Invalid ERC-1155 token amount`}</p>)
            }
          }
        }

        /*
        ///// needs to be added and reformated
        // if these exist, add the name to the attribute section for the Capsule
        // TODO: Check if we should add this for ERC721 Capsules
        if (erc20TokenAmount && erc20TokenAddress && nftValueAttribute) {
          const getTokenName = async () => {
            try {
              return tokenERC20Contract?.name()
            } catch (e) {
              console.log(e, 'issue getting capsule collection NFT Name')
              return '?'
            }
          }

          const handleName = async () => {
            try {
              const name = await getTokenName()
              return name ? name : null
            } catch (e) {
              return null
            }
          }

          const erc20TokenName = await handleName()

          if (erc20TokenName) {
            nftValueAttribute.push({
              trait_type: 'Token 1 Name',
              value: erc20TokenName
            })
          } else {
            error.push(<p id="eati">{'Error acquiring token info (is the address correct?)'}</p>)
          }
        }
        */

        if (error.length > 0) {
          createErrorModule(error, null)
          return
        }

        setErrorDiv(null)
        setIpfsLoadingDiv(<LoadingDiv
          messageOne='Uploading your NFT data to IPFS, please wait...'
          animationOneType='loading'
        />)

        const attributes = nftValueAttribute || [
            {
              trait_type: 'Capsule Type',
              value: 'Simple Capsule NFT'
            },
            {
              trait_type: 'Creator',
              value: account
            },
            {
              trait_type: 'Chain Id',
              value: `${MainChainId}`
            }
          ]

        const metadataObj = {
          description: chosenNftDescription,
          external_url: capsuleUrl,
          name: chosenNftName,
          attributes
        }

        if (files.length < 1) {
          metadataObj.image = new Blob([''], { type: 'image/*' })
        } else if (files[0].type?.includes('image')) {
          metadataObj.image = files[0]
        } else if (files[0].type?.includes('video')) {
          //metadataObj['animation_url'] = files[0]
          metadataObj.image = files[0]

          /*
          let blobURL2 = new File([files[0].preview], "name");
          console.log('bloe2', blobURL2)

          let blobURL = URL.createObjectURL(files[0])
          console.log(blobURL)

          let blobURL3 = new File([blobURL], "name");
          console.log('bloe3', blobURL3)

          //console.log(file)
          // files[0].preview


          const previewImg = await fetch(PreviewImage).then(response => {
            return response.blob();
          })

          // hardcoded preview image: Bloq in a Box
          metadataObj.image = previewImg
          */
        }

        const metadata = await client.store(metadataObj)

        //console.log('this is the metadata: need to check on any error what happens here', metadata)
        // check for error somehow?** (maybe the metadata.ok?)

        setIpfsLoadingDiv(<LoadingDiv
          messageOne='IPFS data stored!'
          animationOneType='done'
          messageTwo='Confirm the metamask transaction to create your NFT!'
          animationTwoType='loading'
        />)

        // if possible, on tx accept, say/add 'congratulations! enjoy your new nft (at #?)'
        //console.log('nftstorage client', client)
        //console.log('IPFS URL for the metadata:', metadata.url)
        //console.log('metadata.json contents:\n', metadata.data)
        //console.log('metadata.json with IPFS gateway URLs:\n', metadata.embed())

        // send tx
        const tx = await nftMintFunction(metadata.url)

        setIpfsLoadingDiv(<LoadingDiv
          messageOne='IPFS data stored!'
          animationOneType='done'
          messageTwo='Waiting until transaction is confirmed...'
          animationTwoType='loading'
        />)

        addTransaction(tx, {summary: `Capsule Successfully Minted`}, 'mintCapsule')

        setIpfsLoadingDiv(null)

        return tx
      } catch (e) {
        let translatedError
        console.log(e.message)
        if (e.message?.includes("transfer amount exceeds allowance")) {
          translatedError = "Token(s) were not properly approved. Scroll back up and make sure to properly approve your token(s) by clicking on the 'Approve' button."
        } else if (e.message?.includes("low-level call failed")) {
          translatedError = "Issue transacting token amount. Are you sure you have enough tokens?" +
          " Properly check and approve your token(s) amount by clicking on the 'Approve' button above."
        } else if (e.message?.includes("'from' is not authorized to send transactions")) {
          translatedError = "Issue creating the transaction. Are you signed in?"
        } else if (e.message?.includes("ERC721: transfer from incorrect owner")) {
          translatedError = "Issue transacting NFTs. Are you sure you own those NFTs?"
        } else if (e.message?.includes("resolver or addr is not configured for ENS name")) {
          translatedError = "Issue obtaining NFT Collection. Please select a collection above and try again."
        } else if (e.message?.includes("ERC1155: insufficient balance for transfer")) {
          translatedError = "You do not have enough tokens to make this transaction."
        }

        createErrorModule(translatedError, e)
        setIpfsLoadingDiv(null)
        return e
      }
  }, [account, files, nftName, nftDescription, nftMintFunction,
      JSON.stringify(erc20Data), JSON.stringify(erc721Data), JSON.stringify(erc1155Data)])

  useEffect(() => () => {
    if (transactionState) {
      try {
        const recentTransactions = []
        for (const tx in transactionState) {
          const type = transactionState[tx]?.type
          if (type === 'mintCapsule' ||
              type === 'approveTokens' ||
              type === 'approveSingleNFT' ||
              type === 'approveAllNFTs') {
            if (transactionState[tx]?.receipt) {
              recentTransactions.push({
                tx,
                approval: (type === 'approveTokens' ||
                  type === 'approveSingleNFT' ||
                  type === 'approveAllNFTs'),
                done: true
              })
            } else {
              recentTransactions.push({
                tx,
                approval: (type === 'approveTokens' ||
                  type === 'approveSingleNFT' ||
                  type === 'approveAllNFTs'),
                done: false
              })
            }
          }
        }

        setTransactions(recentTransactions)
      } catch (e) {
        console.log('Failed to output state TXs', e)
      }
    }
  }, [JSON.stringify(transactionSelector), txEx])

  return (
    <>
      <GridContainer>
        <GridItem
          style={{
            marginTop: "150px",
            textAlign: "center"
          }}
        >
          <strong>Give your Capsule NFT a name and short description:</strong>
        </GridItem>
        <GridItem xs={12}>
          <CustomInput
            labelText="Name"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              type: "text",
              value: nftName,
              onChange: handleNftNameChange
            }}
          />
        </GridItem>
        <GridItem xs={12}
          style={{
            marginBottom: "170px"
          }}
        >
          <CustomInput
            labelText="Description"
            formControlProps={{
              fullWidth: true,
              className: classes.textArea,
            }}
            inputProps={{
              type: "text",
              value: nftDescription,
              onChange: handleNftDescriptionChange,
              multiline: true,
              rows: 2
            }}
          />
        </GridItem>
        <GridItem
          style={{
            textAlign: "center"
          }}
        >
          <strong>Then, upload a file for your Capsule NFT:</strong>
        </GridItem>
        { files.length > 0 && acceptedFileItems.length > 0 ?
            <>
              <GridItem xs={12}>
                <GridContainer alignItems="center" justifyContent="center">
                  <aside style={thumbsContainer}>
                    {thumbs}
                  </aside>
                </GridContainer>
              </GridItem>
              <GridItem xs={12}
                style={{
                  marginBottom: "150px"
                }}
              >
                <GridContainer alignItems="center" justifyContent="center">
                  <Button
                    color="danger"
                    onClick={removeFiles}
                  >
                    Remove file
                  </Button>
                </GridContainer>
                {window.innerWidth < 800 &&
                  <p>
                    *Image viewing may be affected on mobile screens
                  </p>
                }
              </GridItem>
            </>
          :
            <>
              <GridItem xs={12}>
                <div {...getRootProps()}>
                  <input {...getInputProps()} />
                    <GridContainer alignItems="center" justifyContent="center">
                      <aside style={thumbsContainer}>
                        <div style={thumb} key='blank-file'>
                          <div style={thumbInner}>
                            <img
                              src={blankImage}
                              alt="No Attachment"
                              style={img}
                            />
                          </div>
                        </div>
                      </aside>
                    </GridContainer>
                    <GridItem xs={12}
                      style={{
                        marginBottom: "150px"
                      }}
                    >
                      <GridContainer alignItems="center" justifyContent="center">
                        <Button
                          color="danger"
                          onClick={open}
                        >
                          Upload File
                        </Button>
                      </GridContainer>
                      {window.innerWidth < 800 &&
                        <p>
                          *Image viewing may be affected on mobile screens
                        </p>
                      }
                    </GridItem>
                </div>
              </GridItem>
            </>
        }
        { fileRejectionItems.length > 0 &&
          <p>Error, file was rejected: {fileRejectionItems}</p>
        }
        <GridItem
          style={{
            textAlign: "center"
          }}
        >
          <strong>Finally, mint your Capsule NFT:</strong>
        </GridItem>
        <div
          className={classes.container}
        >
          {
            ipfsLoadingDiv ||
            <GridContainer alignItems="center" justifyContent="center">
              <MintCapsule
                errorDiv={errorDiv}
                type={type}
                handleMint={handleCreation}
                collectionAddress={collectionAddress}
                image={files.map(file => (
                  <img
                    src={file.preview}
                    alt="Attachment Preview"
                    style={img}
                  />
                ))}
                name={nftName}
                description={nftDescription}
                insideTokenData={erc20Data || erc721Data || erc1155Data || []}
              />
            </GridContainer>
          }
        </div>
        <GridItem xs={12} sm={12} md={12}
          style={{
            marginTop: `${errorDiv ? "25px" : "150px"}`
          }}
        >
          <GridContainer justifyContent="center">
            { errorDiv }
          </GridContainer>
          <GridContainer justifyContent="center">
            <PendingTransactions
              transactions={transactions}
              txText={(obj) => (`${obj?.approval ? 'Approve Tokens'
                 : 'Mint a Capsule NFT'}`)}
            />
          </GridContainer>
        </GridItem>
      </GridContainer>
    </>
  );
}

export default UploadNFTInfo


  /*
  // 'clear info checkmark' of collection
  const [dontClearInfoChecked, setDontClearInfoChecked] = useState(true)

  console.log("now", dontClearInfoChecked)
  const handleClearInfoToggle = event => {
    console.log(dontClearInfoChecked)
    setDontClearInfoChecked(!dontClearInfoChecked)
  }

  const clearUserInputInfoOnTab = function () {
    setNftDescription('')
    setNftName('')
    removeFiles()
  }

  console.log("I got here", dontClearInfoChecked)
  if (!dontClearInfoChecked) {
    clearUserInputInfoOnTab()
  }


  <GridItem
    style={{
      textAlign: "center"
    }}
  >
    <FormControlLabel
      control={
        <Checkbox
          tabIndex={-1}
          onClick={ handleClearInfoToggle }
          checked={ dontClearInfoChecked }
          checkedIcon={<Check className={classes.checkedIcon} />}
          icon={<Check className={classes.uncheckedIcon} />}
          classes={{ checked: classes.checked }}
        />
      }
      classes={{ label: classes.label }}
      style={{
        marginTop: "35px"
      }}
      label="Don't clear info after mint (useful for minting many similar Capsule NFTs)"
    />
  </GridItem>

  */
