import React, { useCallback, useEffect } from "react"

import TransactionPopup from "./TransactionPopup"
import { useRemovePopup } from "state/application/hooks"

export default function PopupItem({
  removeAfterMs,
  content,
  popKey,
}) {
  const removePopup = useRemovePopup()
  const removeThisPopup = useCallback(
    () => removePopup(popKey),
    [popKey, removePopup]
  )
  useEffect(() => {
    if (removeAfterMs === null) return undefined

    const timeout = setTimeout(() => {
      removeThisPopup()
    }, removeAfterMs)

    return () => {
      clearTimeout(timeout)
    }
  }, [removeAfterMs, removeThisPopup])

  let popupContent
  if ("txn" in content) {
    const {
      txn: { hash, success, summary },
    } = content
    popupContent = (
      <TransactionPopup
        hash={`${hash}`}
        message={`${summary}`}
        severity={success ? 'success' : 'danger'}
        icon={'check'}
      />
    )
  }

  return (
    <div>
      {popupContent}
    </div>
  )
}
