/*eslint-disable*/
import React from "react";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
// react components for routing our app without refresh
import { Link } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

// @material-ui/icons
import { Apps, CloudDownload } from "@material-ui/icons";

// core components
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-kit-react/components/headerLinksStyle.js";

// metamask widget
import MetamaskWidget from "components/app/MetamaskCornerWidget/MetamaskWidget.js"

const useStyles = makeStyles(styles);

export default function HeaderLinks(props) {
  const classes = useStyles();

  let color = undefined

  if (window.innerWidth < 700) {
    color = "black"
  }

  return (
    <>
      <List className={classes.list}>
        <Button
          href="/"
          color="transparent"
          rel="noopener noreferrer"
          className={classes.navLink}
        >
          Home
        </Button>
        <Button
          href={"https://docs.capsulenft.com/"}
          color="transparent"
          target="_blank"
          rel="noopener noreferrer"
          className={classes.navLink}
        >
          Documentation
        </Button>
        <Button
          href="/about"
          color="transparent"
          rel="noopener noreferrer"
          className={classes.navLink}
        >
          About
        </Button>
        <Button
          simple
          style={{
            color
          }}
          color="danger"
          href="/app"
          rel="noopener noreferrer"
        >
          Go to App
        </Button>
      </List>
    </>
  );
}

/*

<Button
  href="/metaverse"
  color="transparent"
  className={classes.navLink}
>
  Metaverse
</Button>

<ListItem className={classes.listItem}>
  <Button
    href="https://www.creative-tim.com/product/material-kit-react?ref=mkr-navbar"
    color="transparent"
    target="_blank"
    className={classes.navLink}
  >
    <CloudDownload className={classes.icons} /> Download
  </Button>
</ListItem>
<ListItem className={classes.listItem}>
  <Button
    href="https://www.creative-tim.com/product/material-kit-react?ref=mkr-navbar"
    color="transparent"
    target="_blank"
    className={classes.navLink}
  >
    <CloudDownload className={classes.icons} /> Download
  </Button>
</ListItem>
<ListItem className={classes.listItem}>
  <CustomDropdown
    noLiPadding
    buttonText="Components"
    buttonProps={{
      className: classes.navLink,
      color: "transparent",
    }}
    buttonIcon={Apps}
    dropdownList={[
      <Link to="/" className={classes.dropdownLink}>
        All components
      </Link>,
      <Link to="/" className={classes.dropdownLink}>
        All components2
      </Link>,
    ]}
  />
</ListItem>

*/
